import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import styles from './Dropzone.module.scss';
import { Icon } from '../Icon/Icon';

// https://react-dropzone.js.org
export const Dropzone: FC<
  DropzoneOptions & { additionalInfoText?: string; tooltip?: ReactNode }
> = (props) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
    isDragActive,
    open,
  } = useDropzone(props);

  return (
    <div
      {...getRootProps()}
      className={clsx(styles.container, {
        [styles.focused]: isFocused,
        [styles.accepted]: isDragAccept || isFileDialogActive || isDragActive,
        [styles.rejected]: isDragReject,
      })}
    >
      <input {...getInputProps()} />
      <div className={styles.icon}>
        <Icon name="uploadCloud" width={20} height={20} onClick={open} />
      </div>
      <span className={styles.text}>
        <span className={styles.click} onClick={open}>
          Click to upload
        </span>{' '}
        or drag and drop
      </span>

      {props?.additionalInfoText && (
        <span className={styles.text}>
          {props.additionalInfoText}
          {props.tooltip}
        </span>
      )}
    </div>
  );
};
