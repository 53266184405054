import { FC, useEffect } from 'react';
import { usePageTitleNameByLocation } from '@module/shared/hooks/usePageTitleNameByLocation';
import { IframeMessageProvider } from '@providers/IframeMessageProvider';
import posthog from 'posthog-js';
import { Outlet, useLocation } from 'react-router-dom';

import { useSetupAxios } from '../modules/auth';

const Page: FC = () => {
  useSetupAxios();
  const pageTitle = usePageTitleNameByLocation();

  const location = useLocation();
  useEffect(() => {
    posthog.capture('$pageview');
    document.title = pageTitle;
  }, [location, pageTitle]);

  return <Outlet />;
};

const RootPage = () => {
  return (
    <IframeMessageProvider>
      <Page />
    </IframeMessageProvider>
  );
};

export default RootPage;
