import React, { forwardRef } from 'react';
import clsx from 'clsx';

import styles from './Input.module.scss';

export type HTMLInputElementPropsSubset = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size' | 'height' | 'className' | 'required' | 'disabled' | 'ref' | 'type' | 'readOnly'
>;

type InputSize = 'sm' | 'md';

export type InputType =
  | 'text'
  | 'email'
  | 'number'
  | 'search'
  | 'url'
  | 'date'
  | 'textarea'
  | 'password';

export interface InputProps extends HTMLInputElementPropsSubset {
  disabled?: boolean;
  size?: InputSize;
  className?: string;
  wrapperClassName?: string;
  required?: boolean;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  type?: InputType;
  error?: boolean;
  readOnly?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = 'md',
      disabled = false,
      className,
      wrapperClassName,
      required = false,
      leftSection,
      rightSection,
      error,
      type = 'text',
      readOnly = false,
      ...nativeInputProps
    },
    ref,
  ) => {
    const inputWrapperClassName = clsx(
      'ongage-input-wrapper',
      styles.wrapper,
      styles[size],
      wrapperClassName,
    );

    const inputClassName = clsx(
      'ongage-input',
      'fw-normal fs-4',
      styles.input,
      styles[size],
      {
        [styles.error]: error,
        [styles.withLeftSection]: leftSection,
        [styles.withRightSection]: rightSection,
      },
      className,
    );

    return (
      <div className={inputWrapperClassName}>
        {leftSection && <div className={clsx(styles.leftSection)}>{leftSection}</div>}
        <input
          {...nativeInputProps}
          ref={ref}
          disabled={disabled}
          required={required}
          type={type}
          className={inputClassName}
          readOnly={readOnly}
        />
        {rightSection && <div className={clsx(styles.rightSection)}>{rightSection}</div>}
      </div>
    );
  },
);
