import { createRoot } from 'react-dom/client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { App } from './app/App';
import config from './app-config';
import { setupSentry } from './setupSentry';

import './app/styles/style.scss';

setupSentry();

if (config.postHog.enabled) {
  posthog.init(config.postHog.key, {
    api_host: config.postHog.host, // host
    person_profiles: 'identified_only', // we want to record only registered users
    capture_pageview: false, // This flag must be turned of for SPA projects, so we can track page views manually
    mask_all_text: false,
    mask_all_element_attributes: false,
    session_recording: {
      maskAllInputs: false,
      recordCrossOriginIframes: true,
    },
  });
}

// Should resolve issue: "Failed to fetch dynamically imported module..."
// Currently, we do not store old static files when new deployed is published
// https://github.com/vitejs/vite/issues/11804#issuecomment-2364075274
window.addEventListener('vite:preloadError', (_) => {
  window.location.reload();
});

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>,
  );
}
