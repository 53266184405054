import { AuthModel, MFAModel } from './models';

export const isMFAModel = (model: AuthModel | MFAModel): model is MFAModel => {
  return 'mfa_token' in model && Boolean(model.mfa_token);
};

export const isAuthModel = (model: AuthModel | MFAModel): model is AuthModel => {
  return 'access_token' in model && Boolean(model.access_token);
};

export const decodeStringsInObject = <T>(obj: T) => {
  if (typeof obj === 'string') {
    return decodeHTMLEntities(obj);
  }

  if (Array.isArray(obj)) {
    return obj.map(decodeStringsInObject);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, decodeStringsInObject(value)]),
    );
  }

  return obj;
};

const decodeHTMLEntities = (text: string): string => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};
