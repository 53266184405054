import { FC } from 'react';
import { TextField, TextFieldProps } from '@ui/TextField';
import { useField } from 'formik';

export const FormikTextField: FC<TextFieldProps> = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <TextField
      {...field}
      error={meta.touched && meta.error}
      id={props.id || props.name}
      {...props}
    />
  );
};
