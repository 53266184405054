import { useMemo } from 'react';
import { EditListFormFields } from '@module/settings/components/list-management/components/EditListForm';
import { TableSortParams } from '@module/settings/components/list-management/components/ExportImportSupListModal';
import { NewListFormFields } from '@module/settings/components/list-management/components/NewListForm';
import {
  archiveList,
  copyList,
  downloadImportTemplateCsv,
  editList,
  exportListsToCsv,
  exportSupExportsToCsv,
  exportSupImportsToCsv,
  getListsV2,
  getListsWithFilters,
  getListsWithFiltersExport,
  getSidebarLists,
  ListFilters,
  newList,
  removeList,
  setNewFrequencyField,
  setNewSmsField,
  updateList,
} from '@module/shared/api/list';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { ArchiveListParams, ExportToCSVParams, SearchParams } from '@module/shared/models/models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { List } from '../store/models';
import { useSelectedListId } from '../store/useListStore';

export const listsKeys = {
  list: () =>
    [
      {
        entity: 'lists',
      },
    ] as const,
  listsWithFilters: (
    listId: number | 'all' | undefined,
    filters?: ListFilters,
    searchParams?: SearchParams,
  ) =>
    [
      {
        listId,
        filters,
        searchParams,
        entity: 'lists',
      },
    ] as const,
  listsV2: (listId: number | 'all' | undefined, searchParams?: SearchParams) =>
    [
      {
        listId,
        searchParams,
        entity: 'listsV2',
      },
    ] as const,
  importTemplateSample: () => [
    {
      entity: 'importTemplate',
    },
  ],
};

export const useSidebarLists = () => {
  return useQuery({
    queryKey: listsKeys.list(),
    queryFn: getSidebarLists,
    select: (data) => data?.payload,
  });
};

export const useListsV2 = (searchParams: SearchParams) => {
  const listId = useSelectedListId();

  return useQuery({
    queryKey: listsKeys.listsV2(listId, searchParams),
    queryFn: ({ queryKey: [{ listId, searchParams }] }) => getListsV2(listId!, searchParams),
    enabled: !!listId,
  });
};

export const useListsStats = () => {
  const { data: lists, isLoading } = useSidebarLists();

  const stats = useMemo(() => {
    const initialState = {
      count: 0,
      activeCount: 0,
      complaintsCount: 0,
      bouncesCount: 0,
      unsubscribesCount: 0,
    };

    return (
      lists?.reduce((acc, list) => {
        return {
          count: acc.count + list.last_count,
          activeCount: acc.activeCount + list.last_active_count,
          complaintsCount: acc.complaintsCount + list.complaints,
          bouncesCount: acc.bouncesCount + list.bounces,
          unsubscribesCount: acc.unsubscribesCount + list.unsubscribes,
        };
      }, initialState) ?? initialState
    );
  }, [lists]);

  return { stats, isLoading };
};

export const useNewSmsField = () => {
  const queryClient = useQueryClient();
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (field_id: number) => setNewSmsField(listId!, field_id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useNewFrequencyField = () => {
  const queryClient = useQueryClient();
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (field_id: number) => setNewFrequencyField(listId!, field_id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useListsWithFilters = (filters: ListFilters, forceAllLists = false) => {
  const listId = useSelectedListId();

  const { filters: searchParams } = useTableFilters();

  return useQuery({
    queryKey: listsKeys.listsWithFilters(forceAllLists ? 'all' : listId, filters, searchParams),
    queryFn: ({ queryKey: [{ listId, filters, searchParams }] }) =>
      getListsWithFilters(listId!, { ...filters, ...searchParams }),
    enabled: !!listId,
  });
};

export const useListsWithFiltersExport = (filters: ListFilters) => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (
      params: Pick<ExportToCSVParams, 'csv_title' | 'columns' | 'format' | 'time_zone'>,
    ) => getListsWithFiltersExport(listId!, params, filters),
  });
};

export const useListUpdate = () => {
  const queryClient = useQueryClient();
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (updatedList: Partial<List> & { id: List['id'] }) =>
      updateList(listId!, updatedList),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useNewList = () => {
  const listId = useSelectedListId();
  const { filters: searchParams } = useTableFilters();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (list: NewListFormFields) => newList({ listId: listId!, ...list }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: listsKeys.listsV2(listId, searchParams) }),
  });
};

export const useRemoveList = () => {
  const listId = useSelectedListId();
  const { filters: searchParams } = useTableFilters();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (listIdToRemove: number) => removeList({ listId: listId!, listIdToRemove }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: listsKeys.listsV2(listId, searchParams) }),
  });
};

export const useEditList = () => {
  const listId = useSelectedListId();
  const { filters: searchParams } = useTableFilters();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (list: Omit<EditListFormFields, 'suppress_in_lists'>) =>
      editList({ listId: listId!, ...list }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: listsKeys.listsV2(listId, searchParams) }),
  });
};

export const useCopyList = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (list: Partial<List>) => copyList({ listId: listId!, ...list }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useArchiveList = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();
  const { filters: searchParams } = useTableFilters();

  return useMutation({
    mutationFn: ({ listIdToArchive, archive }: ArchiveListParams) =>
      archiveList({ listId: listId!, listIdToArchive, archive }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: listsKeys.listsV2(listId, searchParams) }),
  });
};

export const useExportListsToCsv = () => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (
      params: Pick<ExportToCSVParams, 'csv_title' | 'columns' | 'format' | 'time_zone'>,
    ) => exportListsToCsv(listId!, params),
  });
};

export const useDownloadImportTemplate = () => {
  return useMutation({
    mutationFn: () => downloadImportTemplateCsv(),
  });
};

export const useExportSupExportsToCsv = (
  listId: number | 'all' | undefined,
  sortParams: TableSortParams,
) => {
  return useMutation({
    mutationFn: (params: Pick<ExportToCSVParams, 'csv_title' | 'columns' | 'format'>) =>
      exportSupExportsToCsv(listId!, { ...params, ...sortParams }),
  });
};

export const useExportSupImportsToCsv = (
  listId: number | 'all' | undefined,
  sortParams: TableSortParams,
) => {
  return useMutation({
    mutationFn: (params: Pick<ExportToCSVParams, 'csv_title' | 'columns' | 'format'>) =>
      exportSupImportsToCsv(listId!, { ...params, ...sortParams }),
  });
};
