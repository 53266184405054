export enum DateFieldFormats {
  MM_DD_YYYY = 'mm/dd/yyyy',
  DD_MM_YYYY = 'dd/mm/yyyy',
  YYYY_MM_DD = 'yyyy/mm/dd',
  DD_MM_YYYY_DASH = 'dd-mm-yyyy',
  MM_DD_YYYY_DASH = 'mm-dd-yyyy',
  YYYY_MM_DD_DASH = 'yyyy-mm-dd',
  DD_MM_YYYY_TIME = 'dd/mm/yyyy hh24:mi',
  MM_DD_YYYY_TIME = 'mm/dd/yyyy hh24:mi',
  YYYY_MM_DD_TIME = 'yyyy/mm/dd hh24:mi',
  DD_MM_YYYY_DASH_TIME = 'dd-mm-yyyy hh24:mi',
  MM_DD_YYYY_DASH_TIME = 'mm-dd-yyyy hh24:mi',
  YYYY_MM_DD_DASH_TIME = 'yyyy-mm-dd hh24:mi',
  DD_MM_YYYY_FULL_TIME = 'dd/mm/yyyy hh24:mi:ss',
  MM_DD_YYYY_FULL_TIME = 'mm/dd/yyyy hh24:mi:ss',
  YYYY_MM_DD_FULL_TIME = 'yyyy/mm/dd hh24:mi:ss',
  DD_MM_YYYY_DASH_FULL_TIME = 'dd-mm-yyyy hh24:mi:ss',
  MM_DD_YYYY_DASH_FULL_TIME = 'mm-dd-yyyy hh24:mi:ss',
  YYYY_MM_DD_DASH_FULL_TIME = 'yyyy-mm-dd hh24:mi:ss',
}

export enum DateUIFormats {
  DD_MMM_YYYY = 'dd MMM yyyy',
  MMM_DD_COMMA_YYYY = 'MMM dd, yyyy',
  DD_MMM_YY_HH_MM = 'dd MMM yy HH:mm',
  HH_MM_DD_MMMM_YYYY = 'HH:mm, dd MMM yyyy',
}

export const TO_DATEPICKER_FORMAT: { [key in DateFieldFormats]: string } & {
  [key in DateUIFormats]: string;
} = {
  [DateFieldFormats.MM_DD_YYYY]: 'MM/DD/YYYY',
  [DateFieldFormats.DD_MM_YYYY]: 'DD/MM/YYYY',
  [DateFieldFormats.YYYY_MM_DD]: 'YYYY/MM/DD',
  [DateFieldFormats.DD_MM_YYYY_DASH]: 'DD-MM-YYYY',
  [DateFieldFormats.MM_DD_YYYY_DASH]: 'MM-DD-YYYY',
  [DateFieldFormats.YYYY_MM_DD_DASH]: 'YYYY-MM-DD',
  [DateFieldFormats.DD_MM_YYYY_TIME]: 'DD/MM/YYYY HH:mm',
  [DateFieldFormats.MM_DD_YYYY_TIME]: 'MM/DD/YYYY HH:mm',
  [DateFieldFormats.YYYY_MM_DD_TIME]: 'YYYY/MM/DD HH:mm',
  [DateFieldFormats.DD_MM_YYYY_DASH_TIME]: 'DD-MM-YYYY HH:mm',
  [DateFieldFormats.MM_DD_YYYY_DASH_TIME]: 'MM-DD-YYYY HH:mm',
  [DateFieldFormats.YYYY_MM_DD_DASH_TIME]: 'YYYY-MM-DD HH:mm',
  [DateFieldFormats.DD_MM_YYYY_FULL_TIME]: 'DD/MM/YYYY HH:mm:ss',
  [DateFieldFormats.MM_DD_YYYY_FULL_TIME]: 'MM/DD/YYYY HH:mm:ss',
  [DateFieldFormats.YYYY_MM_DD_FULL_TIME]: 'YYYY/MM/DD HH:mm:ss',
  [DateFieldFormats.DD_MM_YYYY_DASH_FULL_TIME]: 'DD-MM-YYYY HH:mm:ss',
  [DateFieldFormats.MM_DD_YYYY_DASH_FULL_TIME]: 'MM-DD-YYYY HH:mm:ss',
  [DateFieldFormats.YYYY_MM_DD_DASH_FULL_TIME]: 'YYYY-MM-DD HH:mm:ss',
  [DateUIFormats.DD_MMM_YYYY]: 'DD MMM YYYY',
  [DateUIFormats.MMM_DD_COMMA_YYYY]: 'MMM DD, yyyy',
  [DateUIFormats.HH_MM_DD_MMMM_YYYY]: 'HH:mm, dd MMM yyyy',
  [DateUIFormats.DD_MMM_YY_HH_MM]: 'DD MMM YY HH:mm',
};

export const TO_DATE_FNS_FORMAT: { [key in DateFieldFormats]: string } = {
  [DateFieldFormats.DD_MM_YYYY]: 'dd/MM/yyyy',
  [DateFieldFormats.MM_DD_YYYY]: 'MM/dd/yyyy',
  [DateFieldFormats.YYYY_MM_DD]: 'yyyy/MM/dd',
  [DateFieldFormats.DD_MM_YYYY_DASH]: 'dd-MM-yyyy',
  [DateFieldFormats.MM_DD_YYYY_DASH]: 'MM-dd-yyyy',
  [DateFieldFormats.YYYY_MM_DD_DASH]: 'yyyy-MM-dd',
  [DateFieldFormats.DD_MM_YYYY_TIME]: 'dd/MM/yyyy HH:mm',
  [DateFieldFormats.MM_DD_YYYY_TIME]: 'MM/dd/yyyy HH:mm',
  [DateFieldFormats.YYYY_MM_DD_TIME]: 'yyyy/MM/dd HH:mm',
  [DateFieldFormats.DD_MM_YYYY_DASH_TIME]: 'dd-MM-yyyy HH:mm',
  [DateFieldFormats.MM_DD_YYYY_DASH_TIME]: 'MM-dd-yyyy HH:mm',
  [DateFieldFormats.YYYY_MM_DD_DASH_TIME]: 'yyyy-MM-dd HH:mm',
  [DateFieldFormats.DD_MM_YYYY_FULL_TIME]: 'dd/MM/yyyy HH:mm:ss',
  [DateFieldFormats.MM_DD_YYYY_FULL_TIME]: 'MM/dd/yyyy HH:mm:ss',
  [DateFieldFormats.YYYY_MM_DD_FULL_TIME]: 'yyyy/MM/dd HH:mm:ss',
  [DateFieldFormats.DD_MM_YYYY_DASH_FULL_TIME]: 'dd-MM-yyyy HH:mm:ss',
  [DateFieldFormats.MM_DD_YYYY_DASH_FULL_TIME]: 'MM-dd-yyyy HH:mm:ss',
  [DateFieldFormats.YYYY_MM_DD_DASH_FULL_TIME]: 'yyyy-MM-dd HH:mm:ss',
};
