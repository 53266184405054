import { useMemo } from 'react';
import { useDecodedToken } from '@module/auth/store/authTokensStore';
import { useUserACL, useUserInfo } from '@module/list/hooks/user-queries';
import { useAllListsSelected } from '@module/list/store/useListStore';
import { AclPrivileges, isActionAllowed, UserPermissions } from '@utils/permissionsCheck';

export const useUserPermissions = (): { isLoading: boolean; permissions: UserPermissions } => {
  const { data: user, isLoading: isUserLoading } = useUserInfo();
  const { data: userAcl, isLoading: isUserAclLoading } = useUserACL();
  const isAllListsSelected = useAllListsSelected();
  const decodedToken = useDecodedToken();
  const isAdmin =
    user?.payload.is_admin || user?.payload.is_admin_manager || user?.payload.is_admin_manager;
  const isGeneralUser = decodedToken?.role === 'general_user';
  const isSuperAdmin = decodedToken?.role === 'super_admin';

  const isLoading = isUserLoading || isUserAclLoading;

  const permissions = useMemo<UserPermissions>(() => {
    const isAdminOrGeneralUser = isAdmin || isGeneralUser;
    return {
      // Dashboard
      hasDashboardViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.ANALYTICS_VIEW], userAcl),

      // List
      hasListImportViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.LIST_IMPORT], userAcl)),
      hasListExportViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser ||
          isActionAllowed([AclPrivileges.LIST_EXPORT, AclPrivileges.LIST_FIELDS_VIEW], userAcl)),
      // Segments
      hasSegmentsViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.SEGMENT_VIEW], userAcl)),
      hasNewSegmentsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.SEGMENT_VIEW, AclPrivileges.SEGMENT_SAVE], userAcl),
      hasEditSegmentViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.SEGMENT_VIEW, AclPrivileges.SEGMENT_SAVE], userAcl),
      // Contacts
      hasContactsManagerViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CONTACT_VIEW], userAcl)),
      hasNewContactsSearchViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.CONTACT_VIEW, AclPrivileges.CONTACT_SAVE], userAcl),
      hasContactSearchReportViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.CONTACT_VIEW, AclPrivileges.CONTACT_SAVE], userAcl),
      // Form builder
      hasFormBuilderViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.LIST_FORM_BUILDER], userAcl)),
      hasNewFormBuilderViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_FORM_BUILDER, AclPrivileges.FORM_SAVE], userAcl),
      hasEditFormBuilderViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_FORM_BUILDER, AclPrivileges.FORM_SAVE], userAcl),
      hasCopyFormBuilderViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_FORM_BUILDER, AclPrivileges.FORM_SAVE], userAcl),
      // Conversion points
      hasConversionPointsViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CONVERSION_POINT_VIEW], userAcl)),
      hasNewConversionPointsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.CONVERSION_POINT_VIEW, AclPrivileges.CONVERSION_POINT_SAVE],
          userAcl,
        ),
      hasEditConversionPointViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.CONVERSION_POINT_VIEW, AclPrivileges.CONVERSION_POINT_SAVE],
          userAcl,
        ),
      // Automation rules
      hasAutomationRulesViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.AUTOMATION_RULE_VIEW], userAcl)),
      // List settings
      hasListSettingsViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser ||
          isActionAllowed(
            [AclPrivileges.LIST_FIELDS_VIEW, AclPrivileges.LIST_FIELDS_SAVE],
            userAcl,
          )),
      // Automations
      hasAnalyticsByWorkflowViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.AUTOMATIONS_VIEW, AclPrivileges.AUTOMATIONS_SAVE], userAcl),
      hasAutomationsAnalyticsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.MARKETING_AUTOMATION_VIEW, AclPrivileges.MARKETING_AUTOMATION_SAVE],
          userAcl,
        ),
      // AI
      hasAIViewPermission: isAdminOrGeneralUser || isActionAllowed([AclPrivileges.AI], userAcl),
      // Campaigns
      hasCampaignOverviewViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_VIEW], userAcl),
      hasCampaignsABViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_TEST], userAcl)),
      hasCampaignsCalendarViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_CALENDAR], userAcl),
      hasCampaignsJourneysViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EVENT_VIEW], userAcl)),
      hasCampaignsTransactionalViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_VIEW], userAcl)),
      hasEventsAndTriggersViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EVENT_VIEW], userAcl)),
      hasConnectionsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      hasSMSVendorsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      hasESPAndSMTPVendorsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      hasEditConnectionViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      // Content
      hasContentEmailsViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VIEW], userAcl)),
      hasContentEmailFormViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VIEW], userAcl),
      hasContentSMSViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.SMS_VIEW], userAcl)),
      hasContentImagesViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.IMAGE_VIEW], userAcl)),
      hasContentTemplatesViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.TEMPLATE_VIEW], userAcl)),
      // Analytics
      hasAnalyticsTransactionalViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_TRANSACTIONAL], userAcl),
      hasAnalyticsOverviewViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_VIEW], userAcl),
      hasAnalyticsAggregateViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_AGGREGATE], userAcl),
      hasAnalyticsEventsViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_EVENT], userAcl),
      hasAnalyticsMatrixViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_MATRIX], userAcl),
      hasAnalyticsContactsActivityViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser ||
          isActionAllowed([AclPrivileges.CONTACT_VIEW, AclPrivileges.LIST_VIEW], userAcl)),
      hasAnalyticsInsightsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.CONTACT_VIEW], userAcl),
      hasAnalyticsSplitCampaignsViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_SPLIT], userAcl),
      hasAnalyticsAggregateReportViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser ||
          isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.CONTACT_VIEW], userAcl)),
      // Validation
      hasValidationIndexViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VALIDATION_VIEW], userAcl),
      hasValidationFormViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VALIDATION_VIEW], userAcl),
      // Account
      hasProfilePermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.ACCOUNT_SETTINGS, AclPrivileges.ACCOUNT_SAVE], userAcl),
      hasAccountSpecsViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ACCOUNT_SAVE], userAcl),
      hasAccountSettingsViewPermission: isAdminOrGeneralUser,
      hasImageTrackingViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.DOMAIN_VIEW], userAcl),
      hasDomainRoutingViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.SEGMENT_VIEW],
          userAcl,
        ),
      hasUserManagementViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.USERS_VIEW, AclPrivileges.USERS_SAVE], userAcl),
      hasListManagementViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.LIST_SAVE], userAcl),
      // NOP
      hasNopIndexViewPermission: isSuperAdmin,
      // Welcome page
      hasSetupWelcomeViewPermission: isAdminOrGeneralUser,
      // Added from the sidebar menu
      hasMarketingAutomationViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.MARKETING_AUTOMATION_VIEW, AclPrivileges.MARKETING_AUTOMATION_SAVE],
          userAcl,
        ),
    };
  }, [isSuperAdmin, isAdmin, isGeneralUser, userAcl, isAllListsSelected]);

  return { isLoading, permissions };
};
