import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Dropdown } from 'react-bootstrap';

type ExternalLinkProps = {
  href: string;
};

export const ExternalLink: FC<PropsWithChildren<ExternalLinkProps>> = ({ href, children }) => {
  return (
    <Dropdown.Item
      as={'div'}
      className={clsx('d-flex align-items-center p-0 rounded', { 'p-3': !href })}
    >
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="rouded text-gray-700 d-flex flex-grow-1 align-items-center p-3"
      >
        {children}
      </a>
    </Dropdown.Item>
  );
};
