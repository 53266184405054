import { FC, ReactElement, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { useLocation } from 'react-router-dom';

import { MenuItem } from './entities/MenuItem';
import { getActiveItemIdx } from './utils';
import { useSidebarActions, useSidebarAnimating, useSidebarMinimized } from '../sidebar-store';

export type NavItem = {
  title: string;
  icon?: ReactElement;
  badge?: ReactElement;
  disabled?: boolean;
  blocked?: boolean;
} & ({ to: string } | { sub: NavItem[] } | { render: (menuLink: ReactElement) => ReactElement });

export interface NavigationMenuProps {
  items: NavItem[];
  isLoading: boolean;
}

export const NavigationMenu: FC<NavigationMenuProps> = ({ items, isLoading }) => {
  const { pathname } = useLocation();

  const sidebarMinimized = useSidebarMinimized();
  const sidebarAnimating = useSidebarAnimating();
  const { toggleSidebar } = useSidebarActions();

  const [activeItemIdx, setActiveItemIdx] = useState<number | null>(() =>
    getActiveItemIdx(items, pathname),
  );
  useEffect(() => {
    setActiveItemIdx(getActiveItemIdx(items, pathname));
  }, [items, pathname]);

  const [openedSubmenuIdx, setOpenedSubmenuIdx] = useState<number | null>(null);

  const handleItemSelect = (e: AccordionEventKey) => {
    if (e === null) {
      setOpenedSubmenuIdx(null);
      return;
    }

    if (typeof e !== 'string') {
      return;
    }

    if (sidebarMinimized) {
      toggleSidebar();
    }

    setOpenedSubmenuIdx(Number(e));
  };

  return (
    <Accordion
      as="div"
      className="d-flex flex-column pt-3 px-5 gap-1"
      activeKey={sidebarMinimized ? null : openedSubmenuIdx?.toString() || null}
      onSelect={handleItemSelect}
    >
      {items.map((item, index) => (
        <MenuItem
          item={item}
          id={index}
          key={index}
          isActive={activeItemIdx === index}
          isMinimized={sidebarMinimized}
          isAnimating={sidebarAnimating}
          isLoading={isLoading}
          isVisible={!item.blocked}
        />
      ))}
    </Accordion>
  );
};
