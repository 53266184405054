import { forwardRef, InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Checkbox.module.scss';

export type HTMLInputElementPropsSubset = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'disabled' | 'ref' | 'type' | 'checked'
>;

export interface CheckboxProps extends HTMLInputElementPropsSubset {
  checked: boolean;
  disabled?: boolean;
  className?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked, disabled, className, ...inputProps }, forwardedRef) => {
    const checkboxClassName = clsx(
      'form-check-input h-20px w-20px align-self-start',
      styles.checkbox,
      {
        checked: checked,
      },
      className,
    );

    return (
      <input
        {...inputProps}
        ref={forwardedRef}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className={checkboxClassName}
      />
    );
  },
);
