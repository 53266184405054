import { Fragment, FC, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { CustomToggle } from './CustomToggle';
import styles from './FaqAccordion.module.scss';

type FaqProps = {
  faqs: Array<{
    q: string;
    a: string;
  }>;
};

export const FaqAccordion: FC<FaqProps> = ({ faqs }) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  return (
    <Accordion>
      {faqs.map((faq, index) => (
        <Fragment key={index}>
          <Accordion.Item
            eventKey={index.toString()}
            className={styles.item}
            onClick={() => handleSelect(index)}
          >
            <Accordion.Header className={styles.header}>
              <CustomToggle eventKey={index.toString()} isActive={activeKey === index}>
                <h3 className={styles.question}>{faq.q}</h3>
              </CustomToggle>
            </Accordion.Header>
            <Accordion.Body className={styles.body}>
              <p className={styles.answer}>{faq.a}</p>
            </Accordion.Body>
          </Accordion.Item>
          <div className={styles.divider}></div>
        </Fragment>
      ))}
    </Accordion>
  );
};
