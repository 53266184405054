import { FC } from 'react';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import { Icon } from '@ui/Icon/Icon';
import clsx from 'clsx';
import { differenceInDays, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';

import styles from './FreeTrialBanner.module.scss';

type BannerProps = {
  isMinimised: boolean;
};

export const FreeTrialBanner: FC<BannerProps> = ({ isMinimised }) => {
  const { data, isLoading } = useAccountInfo();
  const trialExpirationDate = fromUnixTime(Number(data?.trial_expiration_date));
  const today = new Date();
  const daysLeft = differenceInDays(trialExpirationDate, today);
  const days = !isNaN(daysLeft) && daysLeft > 0 ? daysLeft : 0;

  return !isLoading && !data?.is_setup_wizard_completed ? (
    <div
      className={clsx(styles.container, {
        [styles.minimised]: isMinimised,
      })}
    >
      {isMinimised ? (
        <p
          className={clsx(styles.timer, {
            [styles.miniTimer]: isMinimised,
          })}
        >
          <Icon name="infoIcon" stroke="danger" className="mb-1" />
          {days}d Trial
        </p>
      ) : (
        <>
          <p className={styles.timer}>
            <Icon name="infoIcon" stroke="danger" className="me-2" />
            {days} Days of trial left
          </p>
          <p className={styles.info}>Activate now to unlock all features.</p>
          <p className={styles.activationLink}>
            <Link to="/setup/welcome">
              Activate Account <Icon name="arrowRight" stroke="danger" className="ms-1" />
            </Link>
          </p>
          <p className={styles.footnote}>Until activation, only self-emails allowed.</p>
        </>
      )}
    </div>
  ) : null;
};
