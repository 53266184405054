import { FC, PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { useWrappedTo } from '@routing/to';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon/Icon';
import { ROUTES } from '@utils/routes';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { CurrentUserMenu } from './current-user-menu/CurrentUserMenu';
import { FreeTrialBanner } from './free-trial-banner/FreeTrialBanner';
import { ListOfListsTrigger } from './list-of-lists/ListOfListsTrigger';
import { FooterMenu } from './menu/FooterMenu';
import { MainNavigationMenu } from './menu/MainNavigationMenu';
import {
  useSidebarActions,
  useSidebarAnimating,
  useSidebarMinimized,
  useSidebarMode,
} from './sidebar-store';
import styles from './Sidebar.module.scss';
import { OngageLogo } from '../../ui/ongage-logo';

export const Sidebar: FC<PropsWithChildren<unknown>> = () => {
  const to = useWrappedTo();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isMinimized = useSidebarMinimized();
  const isAnimating = useSidebarAnimating();
  const mode = useSidebarMode();
  const { toggleSidebar, setIsAnimating } = useSidebarActions();

  useLayoutEffect(() => {
    if (!sidebarRef.current) {
      return;
    }

    const sidebar = sidebarRef.current;

    function onTransitionEnd(e: TransitionEvent) {
      if (e.target === sidebar) {
        setIsAnimating(false);
      }
    }

    sidebar.addEventListener('transitionend', onTransitionEnd);
    return () => {
      sidebar.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [setIsAnimating]);

  function handleSidebarToggle() {
    // when mode is click, we need to toggle isMinimized and isAnimating
    toggleSidebar({
      mode: mode === 'hover' ? 'click' : 'hover',
      isMinimized: mode !== 'hover',
      isAnimating: mode !== 'hover',
    });
  }

  return (
    <div
      ref={sidebarRef}
      className={clsx('z-index-2', styles.sidebar, {
        [styles.minimized]: isMinimized,
      })}
      onMouseEnter={() => mode === 'hover' && isMinimized && !isAnimating && toggleSidebar()}
      onMouseLeave={() => mode === 'hover' && !isMinimized && !isAnimating && toggleSidebar()}
    >
      <div>
        <div
          className={clsx('px-5 pt-5 d-flex align-items-center justify-content-center', {
            'justify-content-between': !isMinimized,
          })}
        >
          <Link className="flex-shrink-0" to={to(ROUTES.LIST.DASHBOARD)}>
            <OngageLogo height="28px" variant={isMinimized ? 'small' : 'default'} />
          </Link>
          <SidebarTrigger onClick={handleSidebarToggle} />
        </div>
        <div className={clsx('px-5 mt-4')}>
          <CurrentUserMenu />
        </div>
        <div className="mt-3">
          <ListOfListsTrigger />
        </div>
        <div className={clsx('my-3', { 'px-4': isMinimized })}>
          <div className={clsx(styles.separator)} />
        </div>
      </div>
      <div className="flex-grow-1 overflow-y-auto overflow-x-hidden d-flex flex-column justify-content-between">
        <MainNavigationMenu />
        <FreeTrialBanner isMinimised={isMinimized} />
      </div>
      <div className={clsx('flex-shrink-0 pb-3', styles.sidebarFooter)}>
        <FooterMenu />
      </div>
    </div>
  );
};

const SidebarTrigger: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      variant="secondary"
      size="sm"
      onClick={onClick}
      className={clsx(styles.trigger)}
      style={{
        padding: 4,
        borderRadius: 4,
        borderColor: 'var(--bs-gray-200)',
      }}
    >
      <Icon name="layoutLeft" />
    </Button>
  );
};
