import React, { forwardRef } from 'react';
import clsx from 'clsx';

import styles from './Textarea.module.scss';
import { TextFieldError, TextFieldLabel } from '../TextField';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  required?: boolean;
  rightSection?: React.ReactNode;
  leftSection?: React.ReactNode;
  wrapperClassName?: string;
}
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      disabled = false,
      required = false,
      className,
      error = false,
      rightSection,
      wrapperClassName,
      leftSection,
      ...textareaProps
    },
    ref,
  ) => {
    const textAreaWrapperClassName = clsx(
      'ongage-textarea-wrapper',
      styles.wrapper,
      wrapperClassName,
    );

    const textareaClassName = clsx(
      'ongage-textarea',
      'fw-normal fs-4',
      styles.textarea,
      className,
      {
        [styles.error]: error,
        [styles.withRightSection]: rightSection,
        [styles.withLeftSection]: leftSection,
      },
    );

    return (
      <div className={textAreaWrapperClassName}>
        {leftSection && <div className={clsx(styles.leftSection)}>{leftSection}</div>}
        <textarea
          ref={ref}
          disabled={disabled}
          required={required}
          {...textareaProps}
          value={textareaProps.value || ''}
          className={textareaClassName}
        />
        {rightSection && <div className={clsx(styles.rightSection)}>{rightSection}</div>}
      </div>
    );
  },
);

export interface TextAreaFieldProps extends Omit<TextAreaProps, 'error'> {
  className?: string;
  required?: boolean;
  label?: string;
  error?: string | boolean;
  hint?: string;
}
export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    { disabled = false, required = false, error = false, className, label, ...textareaProps },
    ref,
  ) => {
    const textareaClassName = clsx(
      'ongage-textarea',
      'fw-normal fs-4',
      styles.textarea,
      className,
      {
        [styles.error]: error,
      },
    );

    return (
      <div className="d-flex flex-column">
        {label && (
          <TextFieldLabel htmlFor={textareaProps.id || textareaProps.name}>{label}</TextFieldLabel>
        )}
        <TextArea
          ref={ref}
          disabled={disabled}
          required={required}
          {...textareaProps}
          className={textareaClassName}
        />
        {!disabled && error && typeof error === 'string' && (
          <TextFieldError>{error}</TextFieldError>
        )}
      </div>
    );
  },
);
