import { useCallback } from 'react';
import { useSelectedListId } from '@module/list/store/useListStore';
import { useLocation } from 'react-router-dom';

export const ROUTES = {
  LIST: {
    DASHBOARD: '/:listId?/list/dashboard',
    IMPORT: '/:listId?/list/import',
    EXPORT: '/:listId?/list/export',
    SEGMENTS: '/:listId?/list/segments',
    NEW_SEGMENTS: '/:listId?/list/segments/new',
    EDIT_SEGMENT: '/:listId?/list/segments/:id',
    CONTACTS_MANAGER: '/:listId?/list/contacts-manager',
    NEW_CONTACT_SEARCH: '/:listId?/list/contacts-manager/new',
    CONTACT_SEARCH_REPORT: '/:listId?/list/contacts-manager/report/:id',
    FORM_BUILDER: '/:listId?/list/form-builder',
    NEW_FORM_BUILDER: '/:listId?/list/form-builder/new',
    EDIT_FORM_BUILDER: '/:listId?/list/form-builder/:id',
    COPY_FORM_BUILDER: '/:listId?/list/form-builder/:id/copy',
    CONVERSION_POINTS: '/:listId?/list/conversion-points',
    EDIT_CONVERSION_POINT: '/:listId?/list/conversion-points/:id',
    NEW_CONVERSION_POINTS: '/:listId?/list/conversion-points/new',
    AUTOMATION_RULES: '/:listId?/list/automation-rules',
    SETTINGS: '/:listId?/list/settings',
    NEW_FIELD: '/:listId?/list/settings/new',
  },
  CAMPAIGNS: {
    OVERVIEW: '/:listId?/campaigns/overview',
    A_B: '/:listId?/campaigns/a-b',
    EVENTS_AND_TRIGGERS: '/:listId?/campaigns/events-and-triggers',
    TRANSACTIONAL: '/:listId?/campaigns/transactional',
    JOURNEYS: '/:listId?/campaigns/journeys',
    CALENDAR: '/:listId?/campaigns/calendar',
  },
  AUTOMATION: {
    LIST: '/:listId?/automations/list',
    ANALYTICS: '/:listId?/automations/analytics',
    ANALYTICS_BY_WORKFLOW: '/:listId?/automations/analytics/:workflowId',
  },
  CONTENT: {
    TEMPLATES: '/:listId?/content/templates',
    IMAGES: '/:listId?/content/images',
    EMAILS: '/:listId?/content/emails',
    EMAIL_FORM: '/:listId?/content/emails/:id',
    SMS: '/:listId?/content/sms',
  },
  ANALYTICS: {
    INSIGHTS: '/:listId?/analytics/insights',
    OVERVIEW: '/:listId?/analytics',
    AGGREGATE: '/:listId?/analytics/aggregate',
    MATRIX: '/:listId?/analytics/matrix',
    SPLIT_CAMPAIGNS: '/:listId?/analytics/split-campaigns',
    EVENTS: '/:listId?/analytics/events',
    TRANSACTIONAL: '/:listId?/analytics/transactional',
    CONTACTS_ACTIVITY: '/:listId?/analytics/contacts-activity',
    AGGREGATE_REPORT: '/:listId?/analytics/ag-report',
  },
  VENDORS: {
    CONNECTIONS: '/:listId?/vendors/connections',
    ESP_AND_SMTP: '/:listId?/vendors/esp-and-smtp',
    SMS: '/:listId?/vendors/sms',
    EDIT_CONNECTION: '/:listId?/vendors/edit-connection/:id',
  },
  VALIDATION: {
    INDEX: '/:listId?/validation',
    VALIDATION_FORM: '/:listId?/validation/form/:id?',
  },
  AI: '/:listId?/ai',
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password',
    FORGOT_PASSWORD_SENT_LINK: '/auth/forgot-password/sent-link/:email',
    FORGOT_PASSWORD_RESET: '/auth/forgot-password/reset',
    FORGOT_PASSWORD_RESET_COMPLETE: '/auth/forgot-password/pwd-reset-complete',
    FIRST_LOGIN: '/auth/first-login',
  },
  SETTINGS: {
    PROFILE: '/:listId?/settings/profile',
    ACCOUNT_SPECS: '/:listId?/settings/account-specs',
    DOMAIN_ROUTING: '/:listId?/settings/domain-routing',
    ACCOUNT_SETTINGS: '/:listId?/settings/account-settings',
    TRACKING_IMAGE_DOMAINS: '/:listId?/settings/tracking-image-domains',
    USER_MANAGEMENT: '/:listId?/settings/user-management',
    LIST_MANAGEMENT: '/:listId?/settings/list-management',
  },
  NOP: {
    INDEX: '/:listId?/nop',
  },
  SETUP: {
    WELCOME: '/:listId?/setup/welcome',
  },
  PRICING: {
    INDEX: '/:listId?/pricing',
  },
} as const;

export const useTransformCurrentPathUsingList = () => {
  const location = useLocation();
  const selectedListId = useSelectedListId();

  return useCallback(
    (listId: number | 'all') => {
      if (selectedListId && location.pathname.includes(selectedListId.toString())) {
        return `${location.pathname.replace(selectedListId.toString(), listId.toString())}${location.search}`;
      }

      return `/${listId.toString()}${location.pathname}${location.search}`;
    },
    [location, selectedListId],
  );
};

// Utility type to extract all possible paths
type ExtractRoutes<T> = T extends string
  ? T
  : T extends Record<string, unknown>
    ? { [K in keyof T]: ExtractRoutes<T[K]> }[keyof T]
    : never;

// Infer all routes from the `Routes` object
type AllRoutes = ExtractRoutes<typeof ROUTES>;

// Define the type for `PAGE_TITLE`
type PageTitleType = Record<AllRoutes, string>;
export const PAGE_TITLE: PageTitleType = {
  [ROUTES.LIST.DASHBOARD]: 'Dashboard',
  [ROUTES.LIST.IMPORT]: 'Import',
  [ROUTES.LIST.EXPORT]: 'Export',
  [ROUTES.LIST.SEGMENTS]: 'Segments',
  [ROUTES.LIST.NEW_SEGMENTS]: 'New Segment',
  [ROUTES.LIST.EDIT_SEGMENT]: 'Edit Segment',
  [ROUTES.LIST.CONTACTS_MANAGER]: 'Contacts Manager',
  [ROUTES.LIST.NEW_CONTACT_SEARCH]: 'New Contact Search',
  [ROUTES.LIST.CONTACT_SEARCH_REPORT]: 'Contact Search Report',
  [ROUTES.LIST.FORM_BUILDER]: 'Form Builder',
  [ROUTES.LIST.NEW_FORM_BUILDER]: 'New Form Builder',
  [ROUTES.LIST.EDIT_FORM_BUILDER]: 'Edit Form Builder',
  [ROUTES.LIST.COPY_FORM_BUILDER]: 'Copy Form Builder',
  [ROUTES.LIST.CONVERSION_POINTS]: 'Conversion Points',
  [ROUTES.LIST.EDIT_CONVERSION_POINT]: 'Edit Conversion Point',
  [ROUTES.LIST.NEW_CONVERSION_POINTS]: 'New Conversion Points',
  [ROUTES.LIST.AUTOMATION_RULES]: 'Automation Rules',
  [ROUTES.LIST.SETTINGS]: 'Settings',
  [ROUTES.LIST.NEW_FIELD]: 'New Field',
  [ROUTES.CAMPAIGNS.OVERVIEW]: 'Campaigns Overview',
  [ROUTES.CAMPAIGNS.A_B]: 'A/B Campaigns',
  [ROUTES.CAMPAIGNS.EVENTS_AND_TRIGGERS]: 'Events & Triggers',
  [ROUTES.CAMPAIGNS.TRANSACTIONAL]: 'Transactional Campaigns',
  [ROUTES.CAMPAIGNS.JOURNEYS]: 'Journeys',
  [ROUTES.CAMPAIGNS.CALENDAR]: 'Calendar',
  [ROUTES.AUTOMATION.LIST]: 'Automation List',
  [ROUTES.AUTOMATION.ANALYTICS]: 'Automation Analytics',
  [ROUTES.AUTOMATION.ANALYTICS_BY_WORKFLOW]: 'Automation Analytics by Workflow',
  [ROUTES.CONTENT.TEMPLATES]: 'Templates',
  [ROUTES.CONTENT.IMAGES]: 'Images',
  [ROUTES.CONTENT.EMAILS]: 'Emails',
  [ROUTES.CONTENT.EMAIL_FORM]: 'Email Form',
  [ROUTES.CONTENT.SMS]: 'SMS',
  [ROUTES.ANALYTICS.INSIGHTS]: 'Insights',
  [ROUTES.ANALYTICS.OVERVIEW]: 'Overview',
  [ROUTES.ANALYTICS.AGGREGATE]: 'Aggregate',
  [ROUTES.ANALYTICS.MATRIX]: 'Matrix',
  [ROUTES.ANALYTICS.SPLIT_CAMPAIGNS]: 'Split Campaigns',
  [ROUTES.ANALYTICS.EVENTS]: 'Events',
  [ROUTES.ANALYTICS.TRANSACTIONAL]: 'Transactional',
  [ROUTES.ANALYTICS.CONTACTS_ACTIVITY]: 'Contacts Activity',
  [ROUTES.ANALYTICS.AGGREGATE_REPORT]: 'Aggregate Report',
  [ROUTES.VENDORS.CONNECTIONS]: 'Connections',
  [ROUTES.VENDORS.ESP_AND_SMTP]: 'ESP & SMTP',
  [ROUTES.VENDORS.SMS]: 'SMS',
  [ROUTES.VENDORS.EDIT_CONNECTION]: 'Edit Connection',
  [ROUTES.VALIDATION.INDEX]: 'Validation',
  [ROUTES.VALIDATION.VALIDATION_FORM]: 'Validation Form',
  [ROUTES.AI]: 'AI',
  [ROUTES.AUTH.LOGIN]: 'Login',
  [ROUTES.AUTH.REGISTER]: 'Register',
  [ROUTES.AUTH.FORGOT_PASSWORD]: 'Forgot Password',
  [ROUTES.AUTH.FORGOT_PASSWORD_SENT_LINK]: 'Forgot Password Sent Link',
  [ROUTES.AUTH.FORGOT_PASSWORD_RESET]: 'Forgot Password Reset',
  [ROUTES.AUTH.FORGOT_PASSWORD_RESET_COMPLETE]: 'Forgot Password Reset Complete',
  [ROUTES.AUTH.FIRST_LOGIN]: 'First Login',
  [ROUTES.SETTINGS.PROFILE]: 'Profile',
  [ROUTES.SETTINGS.ACCOUNT_SPECS]: 'Account Specs',
  [ROUTES.SETTINGS.DOMAIN_ROUTING]: 'Domain Routing',
  [ROUTES.SETTINGS.ACCOUNT_SETTINGS]: 'Account Settings',
  [ROUTES.SETTINGS.TRACKING_IMAGE_DOMAINS]: 'Tracking Image Domains',
  [ROUTES.SETTINGS.USER_MANAGEMENT]: 'User Management',
  [ROUTES.SETTINGS.LIST_MANAGEMENT]: 'List Management',
  [ROUTES.NOP.INDEX]: 'Nop',
  [ROUTES.SETUP.WELCOME]: 'Welcome',
  [ROUTES.PRICING.INDEX]: 'Pricing',
};
