import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import config from './app-config';

export const setupSentry = () =>
  Sentry.init({
    dsn: config.sentry.dsn,
    normalizeDepth: 7,
    enabled: !window.location.origin.includes('localhost'),
    environment: config.env,
    integrations: [
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          window.location.origin,
          /^https:\/\/api\.ongage\.io\/ma-workflow\/api\/.*/,
        ],
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.extraErrorDataIntegration({
        depth: 5,
        captureErrorCause: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
