import { TableColumn } from '../ui';

type CsvColumn = {
  fld: string;
  name: string;
  type: string;
};
export const csvFileColumns = (columns: TableColumn<unknown>[]): CsvColumn[] => {
  return columns
    .filter((item) => item.accessorField && !item.omit)
    .map((item) => ({
      fld: item.accessorField || '',
      name: item.name?.toString() || '',
      type: item.dataType || '',
    }));
};
