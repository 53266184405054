import { FC } from 'react';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon/Icon';
import { useParams } from 'react-router-dom';

import { BackToLogin } from './BackToLogin';
import styles from './ForgotPassword.module.scss';

export const SentLinkMessage: FC = () => {
  const { email } = useParams();
  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div className={styles.iconContainer}>
          <Icon name="mail" width={25} height={25} />
        </div>
        <h2>Check your email</h2>
        <p className="fs-4 text-center">We sent a password reset link to reset link to {email}</p>
      </div>
      <div className="d-flex flex-column justify-content-center pt-8 px-3">
        <Button
          type="submit"
          variant="info"
          className="w-100"
          size="lg"
          onClick={() => {
            location.href = 'mailto:';
          }}
        >
          Open email app
        </Button>
      </div>
      <div className="text-center mt-10">
        <BackToLogin />
      </div>
    </>
  );
};
