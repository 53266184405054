import { FC } from 'react';
import { Icon } from '@ui/Icon/Icon';
import { ROUTES } from '@utils/routes';
import { Link } from 'react-router-dom';

import styles from './ForgotPassword.module.scss';

export const BackToLogin: FC = () => {
  return (
    <Link className={styles.loginLink} to={ROUTES.AUTH.LOGIN}>
      <Icon name="backArrow" className={styles.backIcon} />
      <span>Back to log in</span>
    </Link>
  );
};
