import axios from 'axios';
import { endOfDay, endOfMonth, format, getUnixTime, startOfMonth } from 'date-fns';

import config from '../../../../app-config';
import { FapiResponse } from '../models/FapiResponse';

const API_URL = config.analyticsUrl;

const GET_ACCOUNT_EMAILS_SMS_COUNT_URL = () => `${API_URL}/api/v0/query`;
const GET_ACCOUNT_ACTION_COUNT = () => `${API_URL}/api/v0/report/workflow`;

export type EmailsSmsCountResponse = {
  sent: number;
};

export const getAccountEmailsSmsCount = async () => {
  const now = new Date();
  const startOfCurrentMonth = startOfMonth(now);
  const currentMonthStart = format(startOfCurrentMonth, 'yyyy-MM-dd HH:mm:ss');

  const endOfCurrentDay = endOfDay(now);
  const currentDayEnd = format(endOfCurrentDay, 'yyyy-MM-dd HH:mm:ss');

  const currentMonth = {
    select: ['sum(`sent`)'],
    from: 'mailing',
    filter: [
      ['stats_date', '>=', `${currentMonthStart}`],
      ['stats_date', '<=', `${currentDayEnd}`],
    ],
    list_id: 'all',
  };

  return await axios
    .post<FapiResponse<EmailsSmsCountResponse>>(GET_ACCOUNT_EMAILS_SMS_COUNT_URL(), currentMonth)
    .then(({ data }) => data);
};

export type ActionsCountResponse = {
  total: number;
};

export const getAccountActionsCount = async () => {
  const now = new Date();

  const params = {
    date_from: getUnixTime(startOfMonth(now)),
    date_to: getUnixTime(endOfMonth(now)),
    list_id: 'all',
  };

  return await axios
    .get<FapiResponse<ActionsCountResponse>>(GET_ACCOUNT_ACTION_COUNT(), { params })
    .then(({ data }) => data);
};
