import { useSidebarLists } from '@module/list/hooks/list-queries';
import { useAllListsSelected, useSelectedListActions, useSelectedListId } from '@module/list/store/useListStore';
import { SidebarLayout } from '@module/sidebar/SidebarLayout';
import { ROUTES, useTransformCurrentPathUsingList } from '@utils/routes';
import { useEffect, useState } from 'react';
import { matchPath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { reInitMenu } from '../helpers';
import { Content } from './components/content';
import { ToolbarWrapper } from './components/toolbar';
import { PageDataProvider } from './core';

const NATIVE_PAGES = [
  ROUTES.LIST.DASHBOARD,
  ROUTES.LIST.IMPORT,
  ROUTES.LIST.EXPORT,
  ROUTES.LIST.SEGMENTS,
  ROUTES.LIST.CONTACTS_MANAGER,
  ROUTES.LIST.NEW_CONTACT_SEARCH,
  ROUTES.LIST.CONTACT_SEARCH_REPORT,
  ROUTES.LIST.FORM_BUILDER,
  ROUTES.LIST.CONVERSION_POINTS,
  ROUTES.LIST.NEW_CONVERSION_POINTS,
  ROUTES.LIST.EDIT_CONVERSION_POINT,
  ROUTES.LIST.SETTINGS,
  ROUTES.SETTINGS.PROFILE,
  ROUTES.SETTINGS.ACCOUNT_SPECS,
  ROUTES.SETTINGS.DOMAIN_ROUTING,
  ROUTES.SETTINGS.ACCOUNT_SETTINGS,
  ROUTES.SETTINGS.TRACKING_IMAGE_DOMAINS,
  ROUTES.SETTINGS.USER_MANAGEMENT,
  ROUTES.SETTINGS.LIST_MANAGEMENT,
  ROUTES.AUTOMATION.ANALYTICS,
  ROUTES.AUTOMATION.ANALYTICS_BY_WORKFLOW,
  ROUTES.VALIDATION.INDEX,
  ROUTES.VALIDATION.VALIDATION_FORM,
  ROUTES.VENDORS.CONNECTIONS,
  ROUTES.PRICING.INDEX,
];

export const MasterLayout = () => {
  const location = useLocation();
  const [isIframePage, setIsIframePage] = useState(() =>
    !NATIVE_PAGES.find(r => matchPath(r, location.pathname))
  );
  const allListsSelected = useAllListsSelected();

  const selectedListId = useSelectedListId();
  const { setList: setSelectedList, selectAllLists } = useSelectedListActions();

  const { listId } = useParams();
  const { data: lists, isLoading } = useSidebarLists();

  const navigate = useNavigate();
  const transformCurrentPathUsingList = useTransformCurrentPathUsingList();

  useEffect(() => {
    reInitMenu();
    setIsIframePage(!NATIVE_PAGES.find(r => matchPath(r, location.pathname)));
  }, [location.key]);

  useEffect(() => {
    if (lists?.length) {
      const defaultList = lists?.find((list) => list.default) ?? lists[0];
      const listToUse = lists?.find((list) => list.id.toString() === listId?.toString()) || defaultList;
      setSelectedList(listToUse);

      if (listId !== listToUse.id.toString()) {
        navigate(transformCurrentPathUsingList(listId === 'all' ? 'all' : listToUse.id))
      }

      if (listId === 'all') {
        selectAllLists();
      }
    }
  }, [lists, selectedListId, listId]);

  return (
    <PageDataProvider>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
          <SidebarLayout
            className="app-wrapper my-0 flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <ToastContainer
              transition={Slide}
              pauseOnFocusLoss={false}
              pauseOnHover={false}
              position="top-right"
              containerId='top'
              closeOnClick
              hideProgressBar
            />
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div className="d-flex flex-column flex-column-fluid">
                {isLoading || selectedListId || allListsSelected ? (
                  <>
                    {isIframePage ? (
                      <Outlet />
                    ) : (
                      <>
                        <ToolbarWrapper />
                        <Content>
                          <Outlet />
                        </Content>
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </SidebarLayout>
        </div>
      </div>
    </PageDataProvider>
  );
};
