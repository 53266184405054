import { FC, useRef, useState } from 'react';
import { Ranger, useRanger } from '@tanstack/react-ranger';
import { formatNumberToLocale } from '@utils/formatNumber';
import clsx from 'clsx';

import styles from './PriceRangeSlider.module.scss';
import { monthlyPaymentLinks, yearlyPaymentLinks } from '../data';
import {
  ANNUAL_DISCOUNT,
  applyDiscount,
  EDGE_VALUES,
  formatPriceValue,
  FREQUENCY,
  PRICING_TYPE,
} from '../utils';

type Props = {
  pricingType: PRICING_TYPE;
  contactRangeData: number[];
  emailsRangeData: number[];
  priceData: number[];
  showCta: boolean;
};

export const PriceRangeSlider: FC<Props> = ({
  pricingType,
  contactRangeData,
  emailsRangeData,
  priceData,
  showCta,
}) => {
  const rangerRef = useRef<HTMLDivElement>(null);

  // Get current range and price data based on pricing type
  const currentRangeData =
    pricingType === PRICING_TYPE.PER_CONTACT ? contactRangeData : emailsRangeData;

  const [frequency, setFrequency] = useState<FREQUENCY>(FREQUENCY.MONTHLY);
  const [contactValues, setContactValues] = useState<number[]>([contactRangeData[0]]);
  const [emailValues, setEmailValues] = useState<number[]>([emailsRangeData[0]]);

  // Map non-linear rangeData to a scaled linear range
  const scaledValues = currentRangeData.map((_, index) => index);
  const valueToIndex = (value: number) => currentRangeData.indexOf(value);
  const indexToValue = (index: number) => currentRangeData[index];

  // Selects the appropriate values array based on pricing type:
  // - For PER_CONTACT type, uses contactValues which stores contact range selections
  // - For PER_SENDING type, uses emailValues which stores email range selections
  // This allows maintaining separate slider positions when switching between pricing types
  const values = pricingType === PRICING_TYPE.PER_CONTACT ? contactValues : emailValues;

  const rangerInstance = useRanger<HTMLDivElement>({
    getRangerElement: () => rangerRef.current,
    values: values.map(valueToIndex),
    min: scaledValues[0],
    max: scaledValues[scaledValues.length - 1],
    steps: scaledValues,
    onDrag: (instance: Ranger<HTMLDivElement>) => {
      const newValues = instance.sortedValues.map(indexToValue);
      if (pricingType === PRICING_TYPE.PER_CONTACT) {
        setContactValues(newValues);
      } else {
        setEmailValues(newValues);
      }
    },
  });

  // Price display logic
  const getCurrentPrice = (): number => {
    const currentValue = values[0];
    const priceIndex = currentRangeData.indexOf(currentValue);
    return priceData[priceIndex] || priceData[0];
  };

  const getPaymentLink = (frequency: FREQUENCY): string => {
    return frequency === FREQUENCY.MONTHLY
      ? monthlyPaymentLinks[valueToIndex(values[0])]
      : yearlyPaymentLinks[valueToIndex(values[0])];
  };

  return (
    <>
      <div className={styles.priceContainer}>
        {getCurrentPrice() === EDGE_VALUES.MAX_PRICE ? (
          <p className={styles.price}>Contact sales for pricing</p>
        ) : (
          <p className={styles.price}>
            $
            <span>
              {frequency === FREQUENCY.MONTHLY
                ? formatNumberToLocale(getCurrentPrice())
                : formatNumberToLocale(applyDiscount(getCurrentPrice()))}
            </span>
            /month
          </p>
        )}
      </div>
      <p>Billed {frequency === FREQUENCY.MONTHLY ? 'monthly' : 'annually'}.</p>
      <div className={styles.frequency}>
        <button
          className={clsx(frequency === FREQUENCY.MONTHLY && styles.active)}
          onClick={() => setFrequency(FREQUENCY.MONTHLY)}
        >
          Monthly
        </button>
        <button
          className={clsx(frequency === FREQUENCY.ANNUALLY && styles.active)}
          onClick={() => setFrequency(FREQUENCY.ANNUALLY)}
        >
          Annually <span>-{ANNUAL_DISCOUNT}%</span>
        </button>
      </div>
      <p className={styles.sliderInfo}>
        How many{' '}
        {pricingType === PRICING_TYPE.PER_CONTACT ? (
          <>
            <strong>contacts</strong> will you manage in your account
          </>
        ) : (
          <>
            <strong>emails</strong> do you send per month
          </>
        )}
        ?
      </p>

      <div ref={rangerRef} className={styles.ranger}>
        {rangerInstance.getSteps().map(({ width }, i) => (
          <div
            key={`interval-${i}`}
            style={{
              width: `${width}%`,
            }}
          />
        ))}
        {rangerInstance
          .handles()
          .map(({ value, onKeyDownHandler, onMouseDownHandler, onTouchStart }, i) => (
            <button
              key={i}
              className={styles.handle}
              onKeyDown={onKeyDownHandler}
              onMouseDown={onMouseDownHandler}
              onTouchStart={onTouchStart}
              role="slider"
              aria-valuemin={rangerInstance.options.min}
              aria-valuemax={rangerInstance.options.max}
              aria-valuenow={indexToValue(value)} // Map index back to value
              style={{
                left: `${rangerInstance.getPercentageForValue(value)}%`,
              }}
            >
              <span>{formatPriceValue(values[0], pricingType)}</span>
            </button>
          ))}
      </div>
      <br />
      <p className={styles.details}>
        Includes{' '}
        {pricingType === PRICING_TYPE.PER_CONTACT ? (
          <>
            <span>
              {formatPriceValue(emailsRangeData[valueToIndex(values[0])], PRICING_TYPE.PER_SENDING)}
            </span>{' '}
            emails
          </>
        ) : (
          <>
            <span>
              {formatPriceValue(
                contactRangeData[valueToIndex(values[0])],
                PRICING_TYPE.PER_CONTACT,
              )}
            </span>{' '}
            contacts
          </>
        )}
      </p>
      {showCta && (
        <div className={styles.ctaContainer}>
          <a role="button" className={styles.cta} href={getPaymentLink(frequency)}>
            {getCurrentPrice() === EDGE_VALUES.MAX_PRICE ? 'Contact us' : 'Start 14-day free trial'}
          </a>
        </div>
      )}
    </>
  );
};
