import { useMemo } from 'react';
import clsx from 'clsx';
import ReactSelect, {
  ClassNamesConfig,
  ClearIndicatorProps,
  components,
  type ControlProps,
  type DropdownIndicatorProps,
  type GroupBase,
  MultiValueRemoveProps,
  type OptionProps,
  type Props as ReactSelectProps,
  ValueContainerProps,
} from 'react-select';

import styles from './Select.module.scss';
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils';
import { Icon } from '../Icon/Icon';

// Fix auto clearing unused imports issue
export * as never from 'react-select/base';
declare module 'react-select/base' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    error?: boolean;
    leftSection?: React.ReactNode;
  }
}

const getValueFontWeight = (isDisabled: boolean) => (isDisabled ? 400 : 500);
const getValueColor = (isDisabled: boolean) =>
  isDisabled ? getCSSVariableValue('--ongage-gray-500') : getCSSVariableValue('--ongage-gray-900');

type SelectOption = {
  label: string;
  value: string | number;
};

export interface SelectProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends Omit<
    ReactSelectProps<Option, IsMulti, Group>,
    'placeholder' | 'unstyled' | 'isDisabled' | 'theme'
  > {
  disabled?: boolean;
  placeholder?: string;
  classNames?: ClassNamesConfig<Option, IsMulti, Group>;
}

export const Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  disabled = false,
  placeholder = '',
  hideSelectedOptions = false,
  ...props
}: SelectProps<Option, IsMulti, Group>) => {
  const { isMulti, error, classNames: customClassNames = {} } = props;

  const defaultClassNames = useMemo<ClassNamesConfig<Option, IsMulti, Group>>(() => {
    const { control, placeholder, menu, option, valueContainer, multiValue, ...restClassNames } =
      customClassNames;

    return {
      control: (state) =>
        clsx(styles.control, control?.(state), {
          [styles.focused]: state.isFocused,
          [styles.error]: error,
          [styles.disabled]: state.isDisabled,
        }),
      placeholder: (state) => clsx(styles.placeholder, placeholder?.(state)),
      menu: (state) => clsx(styles.menu, menu?.(state)),
      option: (state) =>
        clsx(styles.option, option?.(state), {
          [styles.focused]: state.isFocused,
          [styles.selected]: state.isSelected,
          [styles.disabled]: state.isDisabled,
        }),
      valueContainer: (state) =>
        clsx(styles.valueContainer, valueContainer?.(state), {
          [styles.multi]: isMulti,
        }),
      multiValue: (state) => clsx(styles.multiValue, multiValue?.(state)),
      ...restClassNames,
    };
  }, [isMulti, error, customClassNames]);

  return (
    <ReactSelect
      {...props}
      unstyled
      closeMenuOnSelect={props.closeMenuOnSelect ?? !isMulti}
      hideSelectedOptions={hideSelectedOptions}
      className={clsx(styles.select, 'fw-normal', className)}
      placeholder={placeholder}
      classNames={defaultClassNames}
      isDisabled={disabled}
      components={{
        Control,
        DropdownIndicator,
        Option,
        MultiValueRemove,
        ClearIndicator,
        ValueContainer,
        IndicatorSeparator: null,
        ...props.components,
      }}
    />
  );
};

const Control = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: ControlProps<Option, IsMulti, Group>) => {
  const { leftSection } = props.selectProps;
  const title = props.getValue()[0] as SelectOption;
  return (
    <components.Control {...props}>
      {leftSection && <div className={styles.leftSection}>{leftSection}</div>}
      <div title={!props.isMulti ? title?.label : undefined} className={styles.title}>
        {children}
      </div>
    </components.Control>
  );
};

const DropdownIndicator = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <Icon name="chevronDown" />
  </components.DropdownIndicator>
);

const Option = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  return (
    <components.Option {...props}>
      {children}
      {props.isSelected && (
        <span>
          <Icon name="check" stroke="primary" />
        </span>
      )}
    </components.Option>
  );
};

const MultiValueRemove = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) => (
  <components.MultiValueRemove {...props}>
    <Icon name="xNoCircle" width={16} height={16} />
  </components.MultiValueRemove>
);

const ClearIndicator = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ClearIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.ClearIndicator {...props}>
    <Icon name="xNoCircle" />
  </components.ClearIndicator>
);

const ValueContainer = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
  let values = children?.[0];
  const input = children?.[1];

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? '' : 's';
    values = (
      <span
        style={{
          color: `${getValueColor(props.isDisabled || false)}`,
          fontSize: '16px',
          fontWeight: getValueFontWeight(props.isDisabled || false),
        }}
      >
        {`${values.length} item${plural} selected`}
      </span>
    );
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};
