import { FC, MouseEvent, useState } from 'react';
import Yup from '@module/shared/validation/yupConfig';
import { Button } from '@ui/Button';
import { FormSection } from '@ui/FormSection';
import { Icon } from '@ui/Icon/Icon';
import { TextField } from '@ui/TextField';
import { toastError, toastSuccess } from '@ui/Toasts';
import { Tooltip } from '@ui/Tooltip';
import { ROUTES } from '@utils/routes';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BackToLogin } from './BackToLogin';
import styles from './ForgotPassword.module.scss';
import { useUpdatePassword } from '../queries';

export type SetNewPasswordFormFields = {
  password: string;
  re_password: string;
};

enum PwdErrorTypes {
  NUM = 'number',
  SYMBOL = 'symbol',
  LOWERCASE = 'lowercase',
  UPPERCASE = 'uppercase',
  REQ = 'required',
  LENGTH = 'length',
  MATCH = 'match',
}

const SetNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, PwdErrorTypes.LENGTH)
    .matches(/[0-9]/, PwdErrorTypes.NUM)
    .matches(/[a-z]/, PwdErrorTypes.LOWERCASE)
    .matches(/[A-Z]/, PwdErrorTypes.UPPERCASE)
    .matches(/[!@#$%^&*(),.?":{}|<>-]/, PwdErrorTypes.SYMBOL)
    .required(PwdErrorTypes.REQ),
  re_password: Yup.string()
    .oneOf([Yup.ref('password'), undefined], PwdErrorTypes.MATCH)
    .required(PwdErrorTypes.REQ),
});

// to show all available errors at once:
const validatePasswordSchema = async (values: SetNewPasswordFormFields): Promise<CustomErrors> => {
  try {
    await SetNewPasswordSchema.validate(values, { abortEarly: false });
    return { password: [], re_password: [] }; // no errors
  } catch (err) {
    if (!(err instanceof Yup.ValidationError)) {
      throw new Error('Not a validation error');
    }

    const errors: Record<string, string[]> = {};
    err.inner.forEach((element) => {
      // path is undefined when the error relates to the root object
      const path = element.path || 'root';
      // compile errors for same field into one array
      errors[path] ? errors[path].push(element.message) : (errors[path] = [element.message]);
    });

    return errors as CustomErrors;
  }
};

type CustomErrMsgProps = {
  errors: boolean | undefined;
  touched: boolean | undefined;
  descrText: string;
  ready: boolean;
};

const CustomErrorMessage: FC<CustomErrMsgProps> = ({ errors, descrText, ready }) => {
  const validated = ready && !errors;

  return (
    <div className="fs-6 d-flex align-items-center mb-2">
      {ready && errors ? (
        <Icon name="xCircle" width={17} height={17} stroke="danger" />
      ) : (
        <Icon
          name="checkCircle"
          width={17}
          height={17}
          stroke={`${validated ? 'success' : 'dark'}`}
        />
      )}
      <span className="ms-2">{descrText}</span>
    </div>
  );
};

type CustomErrors = {
  password: string[];
  re_password: string[];
};

export const SetNewPassword: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { mutate: updatePassword, isPending } = useUpdatePassword();

  const [customErrors, setCustomErrors] = useState<CustomErrors>({ re_password: [], password: [] });

  const [okToValidate, setOkToValidate] = useState<boolean>(false);
  const [inputs, setInputs] = useState<string[]>([]);

  const changeInputType = (event: MouseEvent) => {
    event.preventDefault();
    const id = event.currentTarget.id;
    if (inputs.includes(id)) {
      const newInputs = inputs.filter((item) => item !== id);
      setInputs(newInputs);
    } else {
      setInputs((prev) => [...prev, id]);
    }
  };

  return (
    <Formik<SetNewPasswordFormFields>
      initialValues={{
        password: '',
        re_password: '',
      }}
      validationSchema={SetNewPasswordSchema}
      validate={async (values) => {
        const result = await validatePasswordSchema(values);
        setCustomErrors(result);
      }}
      onSubmit={(values, { resetForm }) => {
        if (token) {
          const data = {
            password: values.password,
            token: token,
          };
          updatePassword(data, {
            onSuccess: () => {
              toastSuccess({
                title: 'Update password',
                text: `Password updated.`,
              });
              resetForm();
              navigate(`${ROUTES.AUTH.FORGOT_PASSWORD}/pwd-reset-complete`);
            },
            onError: (err) => {
              toastError({
                title: 'Update password',
                text:
                  err instanceof AxiosError
                    ? err.response?.data?.payload?.message
                    : 'Something went wrong. Please refresh the page and try again.',
              });
            },
          });
        } else {
          toastError({
            title: 'Update password',
            text: 'Token is missing.',
          });
        }
      }}
    >
      {({ handleSubmit, handleReset, touched, ...formik }) => {
        return (
          <>
            <div className="d-flex justify-content-center">
              <div className={styles.iconContainer}>
                <Icon name="lock" width={25} height={25} />
              </div>
            </div>
            <form onSubmit={handleSubmit} onReset={handleReset} id="set_new_pwd_form">
              <FormSection
                className={styles.resetPwd}
                title="Set new password"
                description="Your new password must be different to previously used passwords."
                descrClassName="fs-4 text-center"
              >
                <div className="row gy-5 mx-auto">
                  <div className="col-12">
                    <TextField
                      {...formik.getFieldProps('password')}
                      type={inputs.includes('password') ? 'text' : 'password'}
                      label="Password"
                      placeholder=""
                      onBlur={() => setOkToValidate(true)}
                      rightSection={
                        <Tooltip
                          colorTheme="dark"
                          description="Toggle password visibility"
                          placement="top-start"
                        >
                          <span
                            tabIndex={0}
                            className="d-inline-flex align-items-center justify-content-center"
                            style={{
                              pointerEvents: 'all',
                            }}
                            id="password"
                            onClick={(event) => changeInputType(event)}
                            role="button"
                          >
                            <Icon name={inputs.includes('password') ? 'eyeOff' : 'eye'} />
                          </span>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      {...formik.getFieldProps('re_password')}
                      type={inputs.includes('re_password') ? 'text' : 'password'}
                      label="Confirm password"
                      placeholder=""
                      onBlur={() => setOkToValidate(true)}
                      rightSection={
                        <Tooltip
                          colorTheme="dark"
                          description="Toggle password visibility"
                          placement="top-start"
                        >
                          <span
                            tabIndex={0}
                            className="d-inline-flex align-items-center justify-content-center"
                            style={{
                              pointerEvents: 'all',
                            }}
                            id="re_password"
                            onClick={(event) => changeInputType(event)}
                            role="button"
                          >
                            <Icon name={inputs.includes('re_password') ? 'eyeOff' : 'eye'} />
                          </span>
                        </Tooltip>
                      }
                    />
                  </div>
                </div>
              </FormSection>

              <div className="px-3 pt-6">
                <CustomErrorMessage
                  ready={okToValidate && !!formik.values.password}
                  errors={customErrors.password?.includes(PwdErrorTypes.LENGTH)}
                  touched={touched.password}
                  descrText="Minimum 8 characters"
                />
                <CustomErrorMessage
                  ready={okToValidate && !!formik.values.password}
                  errors={customErrors.password?.includes(PwdErrorTypes.NUM)}
                  touched={touched.password}
                  descrText="At least 1 number (1-9)"
                />
                <CustomErrorMessage
                  ready={okToValidate && !!formik.values.password}
                  errors={
                    customErrors.password?.includes(PwdErrorTypes.LOWERCASE) ||
                    customErrors.password?.includes(PwdErrorTypes.UPPERCASE) ||
                    customErrors.password?.includes(PwdErrorTypes.SYMBOL)
                  }
                  touched={touched.password}
                  descrText="One lower case letter, one capital letter and one symbol"
                />
                <CustomErrorMessage
                  ready={okToValidate && !!formik.values.re_password}
                  errors={customErrors.re_password?.includes(PwdErrorTypes.MATCH)}
                  touched={touched.re_password}
                  descrText="Passwords must match"
                />
              </div>

              <div className="d-flex flex-column justify-content-center pt-4 px-3">
                <Button
                  type="submit"
                  variant="info"
                  form="set_new_pwd_form"
                  className="w-100"
                  size="lg"
                  isLoading={isPending}
                >
                  Reset password
                </Button>
                <BackToLogin />
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};
