import { DecodedJWT } from '@module/shared/models/models';
import { jwtDecode } from 'jwt-decode';
import { PostHog } from 'posthog-js';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AuthTokensState = {
  accessToken: string;
  refreshToken: string;
  decodedToken?: DecodedJWT;
  rememberMe: boolean;
  previousAccessToken?: string;
  previousRefreshToken?: string;
  actions: {
    setTokens: (
      accessToken: string,
      refreshToken: string,
      postHog: PostHog,
      rememberMe: boolean,
    ) => void;
    logout: () => void;
    savePreviousTokens: () => void;
    restorePreviousTokens: () => void;
  };
};

export const useAuthTokensStore = create<AuthTokensState>()(
  persist(
    (set, get) => ({
      accessToken: '',
      refreshToken: '',
      rememberMe: false,
      decodedToken: undefined,
      previousAccessToken: undefined,
      previousRefreshToken: undefined,
      actions: {
        setTokens: (accessToken, refreshToken, postHog, rememberMe) => {
          const decodedToken: DecodedJWT = jwtDecode(accessToken);
          postHog.identify(decodedToken.user_id.toString(), {
            account_id: decodedToken.account_id,
            user_id: decodedToken.user_id.toString(),
            token: accessToken,
            project: 'new-ui',
            account_name: decodedToken.account_identifier,
            user_name: decodedToken.identifier,
          });
          return set(() => ({ accessToken, refreshToken, decodedToken, rememberMe }));
        },
        logout: () => {
          const { actions } = get();
          const { previousAccessToken, previousRefreshToken } = get();

          // Check if previous tokens exist
          if (previousAccessToken && previousRefreshToken) {
            return actions.restorePreviousTokens();
          } else {
            return set(() => ({
              accessToken: '',
              refreshToken: '',
              decodedToken: undefined,
              rememberMe: false,
              previousAccessToken: undefined,
              previousRefreshToken: undefined,
            }));
          }
        },
        savePreviousTokens: () => {
          const { accessToken, refreshToken } = get();
          return set(() => ({
            previousAccessToken: accessToken,
            previousRefreshToken: refreshToken,
          }));
        },
        restorePreviousTokens: () => {
          const { previousAccessToken, previousRefreshToken, rememberMe } = get();

          if (previousAccessToken && previousRefreshToken) {
            const decodedToken: DecodedJWT = jwtDecode(previousAccessToken);
            return set(() => ({
              accessToken: previousAccessToken,
              refreshToken: previousRefreshToken,
              rememberMe,
              decodedToken,
              previousAccessToken: undefined, // Clear after restoration
              previousRefreshToken: undefined,
            }));
          }
        },
      },
    }),
    {
      name: 'oauth',
      partialize: (state) => ({
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        decodedToken: state.decodedToken,
        rememberMe: state.rememberMe,
        previousAccessToken: state.previousAccessToken,
        previousRefreshToken: state.previousRefreshToken,
      }),
    },
  ),
);

export const useAccessToken = () => useAuthTokensStore((state) => state.accessToken);
export const useRefreshToken = () => useAuthTokensStore((state) => state.refreshToken);
export const useRememberMe = () => useAuthTokensStore((state) => state.rememberMe);
export const useAuthTokensActions = () => useAuthTokensStore((state) => state.actions);
export const useDecodedToken = () => useAuthTokensStore((state) => state.decodedToken);
export const usePreviousRefreshToken = () =>
  useAuthTokensStore((state) => state.previousRefreshToken);
