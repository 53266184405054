import { FC, PropsWithChildren, useMemo } from 'react';
import { ComponentPropsWithoutRef } from 'react';
import { Link } from 'react-router-dom';

type LogoVariant = 'default' | 'small';

export interface OngageLogoProps
  extends Pick<ComponentPropsWithoutRef<'img'>, 'height' | 'width' | 'className' | 'style'> {
  linkTo?: string;
  variant?: LogoVariant;
}

const IMAGE_VARIANTS: Readonly<Record<LogoVariant, string>> = {
  default: '/media/logos/default.svg',
  small: '/media/logos/default-small.svg',
} as const;

const IMAGE_WIDTHS: Readonly<Record<LogoVariant, number>> = {
  default: 105,
  small: 21,
} as const;

export const OngageLogo: FC<OngageLogoProps> = ({
  linkTo,
  className,
  variant = 'default',
  width,
  height,
  ...props
}) => {
  const imageSize = useMemo(() => {
    if (width && height) {
      return { width, height };
    }

    if (width) {
      return { width, height: 'auto' };
    }

    if (height) {
      return { width: 'auto', height };
    }

    return { width: IMAGE_WIDTHS[variant], height: 'auto' };
  }, [width, height, variant]);

  return (
    <Wrapper linkTo={linkTo}>
      <img
        alt="Ongage logo"
        src={IMAGE_VARIANTS[variant]}
        className={className}
        height={imageSize.height}
        width={imageSize.width}
        {...props}
      />
    </Wrapper>
  );
};

export default OngageLogo;

const Wrapper: FC<PropsWithChildren<{ linkTo: OngageLogoProps['linkTo'] }>> = ({
  children,
  linkTo,
}) => {
  if (!linkTo) {
    return <>{children}</>;
  }

  return <Link to={linkTo}>{children}</Link>;
};
