import { isValidPhoneNumber } from 'react-phone-number-input';
import { StringSchema } from 'yup';

declare module 'yup' {
  interface StringSchema {
    validPhoneNumber(): StringSchema;
  }
}

StringSchema.prototype.validPhoneNumber = function () {
  return this.test('validPhoneNumber', 'Enter valid phone number', function (value) {
    if (value === undefined) {
      return true; // Skip validation for undefined values
    } else {
      return isValidPhoneNumber(value);
    }
  });
};
