import { FC, PropsWithChildren, useContext } from 'react';
import { ListIdPath, useWrappedTo } from '@routing/to';
import { Icon } from '@ui/Icon/Icon';
import { ConditionalWrapper } from '@utils/ConditionalWrapper';
import clsx from 'clsx';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link, matchPath, useLocation } from 'react-router-dom';

import styles from './MenuItem.module.scss';
import { NavItem } from '../NavigationMenu';
import { isCustomItem, isLink } from '../utils';

interface MenuItemProps {
  id: number;
  item: NavItem;
  isActive?: boolean;
  isMinimized?: boolean;
  isAnimating?: boolean;
  isLoading?: boolean;
  isVisible?: boolean;
}

export const MenuItem: FC<MenuItemProps> = ({
  item,
  isMinimized = false,
  id,
  isActive,
  isAnimating = false,
  isLoading = false,
  isVisible = true,
}) => {
  const to = useWrappedTo();

  if (isLoading) {
    return (
      <div
        className={clsx(styles.menuItem, 'd-flex', {
          'flex-grow-1 flex-column align-items-center': isMinimized,
          [styles.active]: isActive,
        })}
      >
        <Skeleton containerClassName="w-100" height={40} />
      </div>
    );
  }

  if (!isVisible) {
    return <></>;
  }

  if (isCustomItem(item)) {
    return item.render(
      <div
        className={clsx(styles.menuItem, 'd-flex', {
          'flex-grow-1 flex-column align-items-center': isMinimized,
          [styles.active]: isActive,
        })}
      >
        <a
          aria-disabled={true}
          data-disabled={item.disabled}
          className={clsx(
            styles.menuItemLink,
            'd-flex flex-grow-1 py-3 gap-3 without-sub align-items-center fs-6 fw-bold text-reset cursor-pointer',
            {
              'shadow-xs': isActive,
              [styles.active]: isActive,
            },
          )}
        >
          {item.icon && (
            <span className="mx-0 justify-content-center flex-grow-0">{item.icon}</span>
          )}
          {!isMinimized && !isAnimating && <span className="flex-grow-1">{item.title}</span>}
          {!isMinimized && !isAnimating && item.badge}
        </a>
      </div>,
    );
  }

  if (isLink(item)) {
    return (
      <div
        className={clsx(styles.menuItem, 'd-flex', {
          'flex-grow-1 flex-column align-items-center': isMinimized,
          [styles.active]: isActive,
        })}
      >
        <ConditionalWrapper
          condition={!item.disabled}
          wrapper={(children) => (
            <Link
              to={to(item.to as ListIdPath)}
              className={clsx(
                styles.menuItemLink,
                'd-flex flex-grow-1 py-3 gap-3 without-sub align-items-center fs-6 fw-bold text-reset',
                {
                  'shadow-xs': isActive,
                  [styles.active]: isActive,
                },
              )}
            >
              {children}
            </Link>
          )}
          elseWrapper={(children) => (
            <a
              aria-disabled={true}
              data-disabled={item.disabled}
              className={clsx(
                styles.menuItemLink,
                'd-flex flex-grow-1 py-3 gap-3 without-sub align-items-center fs-6 fw-bold text-reset text-gray-400',
                {
                  'shadow-xs': isActive,
                  [styles.active]: isActive,
                },
              )}
            >
              {children}
            </a>
          )}
        >
          <>
            {item.icon && (
              <span className="mx-0 justify-content-center flex-grow-0">{item.icon}</span>
            )}
            {!isMinimized && !isAnimating && <span className="flex-grow-1">{item.title}</span>}
            {!isMinimized && !isAnimating && item.badge}
          </>
        </ConditionalWrapper>
      </div>
    );
  }

  return (
    <div className={clsx('d-flex flex-column', { 'align-items-center': isMinimized })}>
      <div className={clsx('d-flex', { active: isActive })}>
        <SubmenuToggle
          eventKey={id.toString()}
          isMinimized={isMinimized}
          isAnimating={isAnimating}
          isActive={isActive}
          disabled={item.disabled}
        >
          <span className="flex-grow-0">{item.icon}</span>
          {!isMinimized && !isAnimating && <span className="flex-grow-1">{item.title}</span>}
        </SubmenuToggle>
      </div>
      <Accordion.Collapse eventKey={isAnimating ? '-1' : id.toString()} as="div">
        {isAnimating ? <></> : <Submenu items={item.sub} />}
      </Accordion.Collapse>
    </div>
  );
};

const Submenu: FC<{
  items: NavItem[];
}> = ({ items }) => {
  const { pathname } = useLocation();

  return (
    <div className={clsx(styles.submenu, 'd-flex flex-column py-3 gap-1')}>
      {items.map((item, index) => (
        <MenuItem
          item={item}
          id={index}
          key={index}
          isActive={isLink(item) ? !!matchPath(item.to, pathname) : false}
        />
      ))}
    </div>
  );
};

const SubmenuToggle: FC<
  PropsWithChildren<{
    isActive?: boolean;
    isMinimized?: boolean;
    isAnimating?: boolean;
    hideToggleArrow?: boolean;
    eventKey: string;
    disabled?: boolean;
    callback?: (eventKey: string) => void;
  }>
> = ({
  children,
  eventKey,
  callback,
  isMinimized = false,
  isActive = false,
  isAnimating = false,
  disabled = false,
  hideToggleArrow = false,
}) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={decoratedOnClick}
      className={clsx(
        styles.submenuTrigger,
        'd-flex flex-grow-1 align-items-center text-start py-3 px-4 gap-3 fs-6 fw-bold',
        {
          [styles.active]: isActive,
          'shadow-xs': isActive,
          'text-gray-400': disabled,
        },
      )}
    >
      {children}
      {!isMinimized && !isAnimating && !hideToggleArrow && (
        <span className="flex-grow-0">
          <Icon
            name="chevronDown"
            className={clsx(styles.chevron, {
              [styles.active]: activeEventKey === eventKey,
            })}
          />
        </span>
      )}
    </button>
  );
};
