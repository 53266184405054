import { FC, useState } from 'react';
import { FaqAccordion } from '@modules/pricing/components/FaqAccordion';
import { Feature } from '@modules/pricing/components/Feature';
import { PriceRangeSlider } from '@modules/pricing/components/PriceRangeSlider';
import {
  features,
  moreFeatures,
  faqs,
  priceData,
  allowedContactsData,
  sendingVolumeData,
} from '@modules/pricing/data';
import { divideArrayIntoThree, PRICING_TYPE } from '@modules/pricing/utils';
import { Icon } from '@ui/Icon/Icon';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import styles from '../../modules/pricing/styles/Pricing.module.scss';

const paymentLogos = ['visa', 'mastercard', 'amex', 'discover', 'diners-club', 'jcb', 'union-pay'];

export const PricingPage: FC = () => {
  const [searchParams] = useSearchParams();
  const [featuresColumn1, featuresColumn2, featuresColumn3] = divideArrayIntoThree(moreFeatures);
  const [pricingType, setPricingType] = useState<PRICING_TYPE>(PRICING_TYPE.PER_CONTACT);

  const showCta = searchParams.get('showCta');

  return (
    <main className={styles.main}>
      <section className={styles.gradient}>
        <div className={styles.constellation}></div>
        <div className={styles.header}>
          <h1>Ongage pricing for growing businesses</h1>
          <p>
            Just starting? Need to scale and keep it cost effective?
            <br />
            Try our customizable packages!
          </p>
          <div className={styles.toggle}>
            <button
              className={clsx(pricingType === PRICING_TYPE.PER_CONTACT && styles.active)}
              onClick={() => setPricingType(PRICING_TYPE.PER_CONTACT)}
            >
              Price {PRICING_TYPE.PER_CONTACT}
            </button>
            <button
              className={clsx(pricingType === PRICING_TYPE.PER_SENDING && styles.active)}
              onClick={() => setPricingType(PRICING_TYPE.PER_SENDING)}
            >
              Price {PRICING_TYPE.PER_SENDING}
            </button>
          </div>
        </div>
        <div className={styles.pricingCard}>
          <div className={styles.pricingIcon}>
            <Icon
              name={pricingType === PRICING_TYPE.PER_SENDING ? 'mail' : 'users'}
              stroke="rose"
              width={22}
              height={22}
            />
          </div>
          <h2>Price per {pricingType === PRICING_TYPE.PER_SENDING ? 'sending' : 'contact'}</h2>
          <p className={styles.info}>
            Ask for the Ongage SMTP, bring your own SMTP connection, or run Multiple SMTPs!
          </p>
          <PriceRangeSlider
            pricingType={pricingType}
            contactRangeData={allowedContactsData}
            emailsRangeData={sendingVolumeData}
            priceData={priceData}
            showCta={!!showCta}
          />
        </div>
      </section>

      <section className={styles.features}>
        <div className={styles.featuresDescr}>
          <p className={styles.subheading}>Features</p>
          <h2 className={styles.h2Heading}>A flexible platform that scales with you</h2>
          <p className={styles.lead}>
            A powerful sending and automation solution to help you convert, engage, and retain
            subscribers, users, and customers. Trusted by thousands of publishers and creators
          </p>
        </div>
        <div className={styles.featureCards}>
          {features.map((feature, index) => (
            <div key={index} className={styles.featureCard}>
              <div className={styles.featureIcon}>
                <Icon name={feature.icon} stroke="rose" width={22} height={22} />
              </div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.features}>
        <h2 className={styles.h2Heading}>And many more...</h2>
        <div className={styles.moreFeatures}>
          {featuresColumn1 && <Feature features={featuresColumn1} />}
          {featuresColumn2 && <Feature features={featuresColumn2} />}
          {featuresColumn3 && <Feature features={featuresColumn3} />}
        </div>
      </section>

      <section className={styles.payment}>
        <Image src="media/svg/general/blue-snap.svg" alt="BlueSnap logo." />
        <div className={styles.paymentLogos}>
          {paymentLogos.map((logo) => (
            <Image
              key={logo}
              src={`media/svg/payment/${logo}.svg`}
              alt={`${logo} logo.`}
              width={40}
            />
          ))}
        </div>
      </section>

      <section className={styles.testimonials}>
        <div className={styles.testimonialAuthor}>
          <Image
            src="media/illustrations/scott-colson.png"
            alt="Testimonial author headshot."
            width={64}
          />
          <div className={styles.author}>
            <p className={styles.name}>Scott Colson</p>
            <p className={styles.jobTitle}>
              VP Marketing and Principal owner @322 Media, GlobalBank
            </p>
          </div>
          <div className={styles.stats}>
            <div className={styles.revenue}>
              <p>+300%</p>
              <p>Revenue growth</p>
            </div>
            <div className={styles.statsDivider}></div>
            <div className={styles.circulation}>
              <p>+150%</p>
              <p>Increase in circulation</p>
            </div>
          </div>
          <p className={styles.testimonialQuote}>
            ”Ongage was the easy and right choice. We used other platforms in the past, and I’ll be
            honest, Ongage has blown them out of the water. You guys have done a great job with the
            technology.”
          </p>
        </div>
        <div className={styles.testimonialLogos}>
          <Image
            src="media/illustrations/testimonials-logos.png"
            alt="Testimonials logos"
            fluid
            className={styles.responsiveImage}
          />
        </div>
      </section>

      <section className={styles.faqs}>
        <h2 className={styles.h2Heading}>Keep Calm, We have FAQs!</h2>
        <p className={styles.lead}>Everything you need to know about the product and billing.</p>
        <div className={styles.faqAccordion}>
          <FaqAccordion faqs={faqs} />
        </div>
      </section>
    </main>
  );
};
