import {
  addDomain,
  deleteDomain,
  DomainFilters,
  getDomainsExport,
  getDomainsSetupList,
  updateDomain,
  verifyDomain,
} from '@module/shared/api/domains-setup';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { ExportToCSVParams, SearchParams } from '@module/shared/models/models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DomainSetup } from '../store/models';
import { useSelectedListId } from '../store/useListStore';

export const domainKeys = {
  single: (listId: number | 'all' | undefined, id: number) =>
    [
      {
        listId,
        id,
        scope: 'domains',
      },
    ] as const,
  list: (listId: number | 'all' | undefined) =>
    [
      {
        listId,
        scope: 'domains',
      },
    ] as const,
  filteredList: (listId: number | 'all' | undefined, filters?: DomainFilters & SearchParams) =>
    [
      {
        ...domainKeys.list(listId)[0],
        filters,
      },
    ] as const,
};

export const useDomainsList = () => {
  const listId = useSelectedListId();

  const { filters: searchParams } = useTableFilters<DomainFilters>();

  return useQuery({
    queryKey: domainKeys.filteredList(listId, searchParams),
    queryFn: ({ queryKey: [{ listId, filters }] }) => getDomainsSetupList(listId!, filters),
    enabled: !!listId,
  });
};

export const useDomain = (id: DomainSetup['id']) => {
  const listId = useSelectedListId();

  return useQuery({
    queryKey: domainKeys.single(listId, id),
    queryFn: ({ queryKey: [{ listId, id }] }) => getDomainsSetupList(listId!, { ids: [id] }),
    enabled: !!listId,
    select: (d) => d.payload[0],
  });
};

export const useDomainExport = (filters: DomainFilters) => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (
      params: Pick<ExportToCSVParams, 'csv_title' | 'columns' | 'format' | 'time_zone'>,
    ) => getDomainsExport(listId!, params, filters),
  });
};

export const useDomainsListSelect = (filters: Partial<DomainFilters> & SearchParams) => {
  const listId = useSelectedListId();

  return useQuery({
    queryKey: domainKeys.filteredList(listId, filters),
    queryFn: ({ queryKey: [{ listId, filters }] }) => getDomainsSetupList(listId!, filters),
    enabled: !!listId,
  });
};

export const useDomainVerify = () => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (domain_id: number) => verifyDomain(listId!, domain_id),
  });
};

export const useAddDomain = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (domain: Partial<DomainSetup> & { isHttps?: boolean }) =>
      addDomain({ listId: listId!, ...domain }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: domainKeys.list(listId) }),
  });
};

export const useUpdateDomain = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (domain: Partial<DomainSetup>) => updateDomain({ listId: listId!, ...domain }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: domainKeys.list(listId) }),
  });
};

export const useRemoveDomain = () => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (domainId: DomainSetup['id']) => deleteDomain({ listId: listId!, domainId }),
  });
};
