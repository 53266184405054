import { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

import { Checkbox, CheckboxProps } from '../Checkbox';

type PartialCheckboxProps = Omit<CheckboxProps, 'error' | 'name'>;

export interface CheckboxFieldProps extends PartialCheckboxProps {
  name: string;
  label?: ReactNode;
  description?: string;
  hint?: string;
  className?: string;
}

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ label, description, className, ...checkboxProps }, ref) => {
    const checkboxId = `checkbox-${checkboxProps.name}`;

    return (
      <div
        className={clsx(
          'form-check form-check-custom form-check-inline d-flex align-items-center',
          className,
        )}
      >
        <Checkbox id={checkboxId} className="align-self-start" {...checkboxProps} ref={ref} />
        <div className="d-flex flex-column" aria-labelledby={checkboxId}>
          {label && (
            <span
              id={`${checkboxId}-label`}
              className="form-check-label fs-6 fw-semibold text-gray-700"
            >
              {label}
            </span>
          )}

          {description && (
            <span
              id={`${checkboxId}-description`}
              className="form-check-label text-gray-500 fs-7 fw-semibold"
            >
              {description}
            </span>
          )}
        </div>
      </div>
    );
  },
);
