import { FC } from 'react';
import { PrefilledFormDataType } from '@module/settings/components/account-settings/components/PrefilledFormData';
import { Icon } from '@ui/Icon/Icon';
import { Modal, ModalProps } from '@ui/Modal';

import { SupportForm } from './SupportForm';
import styles from '../../user-management/components/Modal.module.scss';

type PrefilledFormDataTypeProps = {
  initialData?: PrefilledFormDataType;
};
export const SupportFormModal: FC<ModalProps & PrefilledFormDataTypeProps> = ({
  show,
  onClose,
  initialData,
}) => {
  return (
    <Modal show={show} onClose={onClose} dialogClassName={styles.modalDialog} backdrop="static">
      <Modal.Header withCloseButton>
        <Icon
          name="backgroundCirclesPattern"
          className="position-absolute top-0 start-0"
          width={400}
          height={400}
        />
        <div className={styles.icon}>
          <Icon name="msgQuestionCircle" />
        </div>
      </Modal.Header>

      <Modal.Body>
        <SupportForm closeModal={onClose} prefilledFormData={initialData} />
      </Modal.Body>
    </Modal>
  );
};
