import { createContext, PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { TableColumn } from '@ui/table';
import { createStore, StoreApi, useStore } from 'zustand';
import { persist } from 'zustand/middleware';

interface StoredDataProps<T, K> {
  columns: TableColumn<T>[];
  tableKey: string;
  query: UseQueryResult<K, unknown>;
}

export interface TableDataState<T, K> extends StoredDataProps<T, K> {
  actions: {
    setColumns: (columns: StoredDataProps<T, K>['columns']) => void;
    setQuery: (columns: StoredDataProps<T, K>['query']) => void;
    hideColumn: (column: TableColumn<T>['accessorField']) => void;
    showColumn: (column: TableColumn<T>['accessorField']) => void;
    resetColumns: () => void;
  };
}

export type TableDataStore<T, K> = StoreApi<TableDataState<T, K>>;
const createTableColumnStore = <T, K>(initialProps: StoredDataProps<T, K>) => {
  return createStore<TableDataState<T, K>>()(
    persist(
      (set, get) => ({
        ...initialProps,
        actions: {
          setColumns: (columns) => {
            const oldColumns = get().columns;
            set({
              columns: columns.map((c) => {
                const column = oldColumns.find((oc) => oc.accessorField === c.accessorField);
                return {
                  ...c,
                  omit: getColumnOmitState(c, column),
                };
              }),
            });
          },
          setQuery: (query) => {
            set({ query });
          },
          hideColumn: (columnName) => {
            set({
              columns: get().columns.map((c) =>
                [c.id, c.accessorField].includes(columnName) ? { ...c, omit: true } : c,
              ),
            });
          },
          showColumn: (columnName) => {
            set({
              columns: get().columns.map((c) =>
                [c.id, c.accessorField].includes(columnName) ? { ...c, omit: false } : c,
              ),
            });
          },
          resetColumns: () => {
            set({ columns: get().columns.map((c) => ({ ...c, omit: false })) });
          },
        },
      }),
      {
        name: initialProps?.tableKey || 'index',
        partialize: (state) => ({
          columns: state.columns.map((c) => ({ accessorField: c.accessorField, omit: c.omit })),
          tableKey: state.tableKey,
        }),
        merge: (persistedState, currentState) => ({
          ...currentState,
          columns: currentState.columns.map((c) => ({
            ...c,
            omit: (
              persistedState as {
                columns: {
                  accessorField: TableColumn<T>['accessorField'];
                  omit: TableColumn<T>['omit'];
                }[];
              }
            )?.columns.find((pc) => pc.accessorField === c.accessorField)?.omit,
          })),
        }),
      },
    ),
  );
};

const getColumnOmitState = <T,>(column: TableColumn<T>, oldColumn: TableColumn<T> | undefined) => {
  // TODO Think how to work with state without hideFromTable, it doesn't make sense
  if (column.hideFromTableHidingComponent) {
    return true;
  }

  // always show columns which have "dynamic" omit state
  if (oldColumn?.omit && column.omit === false) {
    return false;
  }

  return oldColumn && 'omit' in oldColumn ? oldColumn.omit : column?.omit;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableDataContext = createContext<TableDataStore<any, any> | null>(null);

export const TableDataProvider = <T, K>({
  children,
  ...props
}: PropsWithChildren<StoredDataProps<T, K>>) => {
  const storeRef = useRef<ReturnType<typeof createTableColumnStore<T, K>>>();
  if (!storeRef.current) {
    storeRef.current = createTableColumnStore<T, K>(props);
  }

  useEffect(() => {
    storeRef.current?.getState().actions.setColumns(props.columns);
  }, [props.columns]);

  useEffect(() => {
    storeRef.current?.getState().actions.setQuery(props.query);
  }, [props.query]);

  return <TableDataContext.Provider value={storeRef.current}>{children}</TableDataContext.Provider>;
};

export const useTableDataContext = <T, U, K>(selector: (state: TableDataState<T, K>) => U) => {
  const store = useContext(TableDataContext) as TableDataStore<T, K>;
  if (!store) {
    throw new Error('useTableColumnContext must be used within TableColumnProvider');
  }
  return useStore(store, selector);
};
