import { FC, useMemo, useState } from 'react';
import {
  useNewSupportTicket,
  useSupportFormErrors,
  useUploadSupportFile,
  useUserInfo,
} from '@module/list/hooks/user-queries';
import { PrefilledFormDataType } from '@module/settings/components/account-settings/components/PrefilledFormData';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import Yup from '@module/shared/validation/yupConfig';
import { Button } from '@ui/Button';
import { Dropzone } from '@ui/Dropzone/Dropzone';
import { FormikTextArea } from '@ui/formik/TextArea/TextArea';
import { FormikTextField } from '@ui/formik/TextField';
import { FormSection } from '@ui/FormSection';
import { Icon } from '@ui/Icon/Icon';
import { Select } from '@ui/Select';
import { toastError, toastSuccess } from '@ui/Toasts';
import { Tooltip } from '@ui/Tooltip';
import {
  fileSizeValidation,
  formatFileSize,
  isImage,
  maxFileSize,
  maxSupportFiles,
} from '@utils/common';
import useDebouncedTooltip from '@utils/hooks/useDebouncedTooltip';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { format, fromUnixTime } from 'date-fns';
import { FieldArray, Formik } from 'formik';

import { SeverityLevelsExplained } from './SeverityLevelsExplained';
import styles from './SupportForm.module.scss';

export type SupportFormFields = {
  email: string;
  problem_area: string;
  entity_identifier: string;
  problem_type: string;
  error_id?: string;
  subject: string;
  description: string;
  file_uploads_names?: string[];
  error_id_input: string;
  error_id_select: string;
  support_file: File[];
  severity_level?: string;
  custom_field_ids: string[];
};

export type SupportUploadFields = {
  action: string;
  support_file: File;
};

enum SupportUploadActions {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum ProblemType {
  TECH_ISSUE = 'Technical Issue',
  SALES_REQUEST = 'Sales Request',
  PRODUCT_QUESTIONS = 'Question about the product',
  OTHER = 'Other',
}

enum ProblemArea {
  GENERAL = 'General',
  CAMPAIGN = 'Campaign',
  EVENT = 'Event',
  LIST = 'List',
  CONTENT = 'Content',
  ANALYTICS = 'Analytics',
  NETWORKING = 'Networking',
}

const basicProblemAreas = [ProblemArea.GENERAL, ProblemArea.CONTENT, ProblemArea.ANALYTICS];

enum SeverityLevel {
  LEVEL_0 = '--NONE--',
  LEVEL_1 = 'Level 1 - Critical',
  LEVEL_2 = 'Level 2 - High',
  LEVEL_3 = 'Level 3 - Medium',
  LEVEL_4 = 'Level 4 - Low',
}

const problemTypeValues = Object.values(ProblemType);
const problemAreaValues = Object.values(ProblemArea);
const severityLevelValues = Object.values(SeverityLevel);

const enableIdentField = (value: string) => {
  if (
    value === ProblemArea.CAMPAIGN ||
    value === ProblemArea.EVENT ||
    value === ProblemArea.LIST ||
    value === ProblemArea.NETWORKING
  ) {
    return true;
  } else {
    return false;
  }
};

const identFieldLabel = (value: string) => {
  switch (value) {
    case ProblemArea.CAMPAIGN:
      return 'CID';
    case ProblemArea.EVENT:
      return 'Event ID';
    case ProblemArea.LIST:
      return 'List ID';
    case ProblemArea.NETWORKING:
      return 'ESP Name';
    default:
      return 'ID';
  }
};

const SupportFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').required('Email is required'),
  problem_type: Yup.string()
    .oneOf(problemTypeValues, 'Invalid problem type')
    .required('Problem type is required'),
  error_id_select: Yup.string().optional(),
  error_id_input: Yup.string().optional(),
  problem_area: Yup.string()
    .oneOf(problemAreaValues, 'Invalid problem area')
    .required('Problem area is required'),
  entity_identifier: Yup.string().when('problem_area', {
    is: (value: string) => enableIdentField(value),
    then: () => Yup.string().max(10, 'Max 10 digits').required('Enter ID'),
    otherwise: () => Yup.string().optional(),
  }),
  severity_level: Yup.string().oneOf(severityLevelValues, 'Invalid severity level').optional(),
  subject: Yup.string().min(2).max(128).required('Please enter subject'),
  description: Yup.string()
    .min(2, 'Min 2 chars')
    .max(512, 'Max 512 chars')
    .required('Describe the problem you are having'),
  support_file: Yup.array()
    .max(3, 'You can only upload up to 3 files.')
    .test('support_file', 'File should be less than 5MB each.', fileSizeValidation)
    .optional(),
});

type SupportFormProps = {
  closeModal: () => void;
  prefilledFormData?: PrefilledFormDataType;
};

export const SupportForm: FC<SupportFormProps> = ({ closeModal, prefilledFormData }) => {
  const { data: user } = useUserInfo();
  const { data: account } = useAccountInfo();
  const { data: errors } = useSupportFormErrors();

  const { mutate: openSupportTicket, isPending: isPendingNewTicket } = useNewSupportTicket();
  const { mutate: uploadSupportFile, isPending: isPendingFileUpload } = useUploadSupportFile();

  const problemTypeOptions = useMemo(
    () => [
      { value: ProblemType.TECH_ISSUE, label: ProblemType.TECH_ISSUE },
      { value: ProblemType.SALES_REQUEST, label: ProblemType.SALES_REQUEST },
      { value: ProblemType.PRODUCT_QUESTIONS, label: ProblemType.PRODUCT_QUESTIONS },
      { value: ProblemType.OTHER, label: ProblemType.OTHER },
    ],
    [],
  );

  const [errPlaceholder, setErrPlaceholder] = useState<string>('Select');
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [showTooltip, debouncedShow] = useDebouncedTooltip();
  const [fileUploadTooltip, debouncedFileUploadTooltip] = useDebouncedTooltip();

  const errorIdSelectOptions = errors?.payload.map((err) => ({
    value: err.id,
    label: `Error ID #${err.id} from ${format(fromUnixTime(+err.error_date), 'dd MMM yyyy HH:mm')} ("${err.frontend_url || err.api_url}")`,
  }));

  const problemAreaOptions = useMemo(
    () => [
      { value: ProblemArea.GENERAL, label: ProblemArea.GENERAL },
      { value: ProblemArea.CAMPAIGN, label: ProblemArea.CAMPAIGN },
      { value: ProblemArea.EVENT, label: ProblemArea.EVENT },
      { value: ProblemArea.LIST, label: ProblemArea.LIST },
      { value: ProblemArea.CONTENT, label: ProblemArea.CONTENT },
      { value: ProblemArea.ANALYTICS, label: ProblemArea.ANALYTICS },
      { value: ProblemArea.NETWORKING, label: ProblemArea.NETWORKING },
    ],
    [],
  );

  const severityLevelOptions = useMemo(
    () => [
      { value: SeverityLevel.LEVEL_0, label: SeverityLevel.LEVEL_0 },
      { value: SeverityLevel.LEVEL_1, label: SeverityLevel.LEVEL_1 },
      { value: SeverityLevel.LEVEL_2, label: SeverityLevel.LEVEL_2 },
      { value: SeverityLevel.LEVEL_3, label: SeverityLevel.LEVEL_3 },
      { value: SeverityLevel.LEVEL_4, label: SeverityLevel.LEVEL_4 },
    ],
    [],
  );

  const createNewSupportTicket = (data: SupportFormFields, resetForm: () => void) => {
    openSupportTicket(data, {
      onSuccess: () => {
        toastSuccess({
          title: 'Support ticket',
          text: 'Your support ticket was successfully submitted.',
        });
        resetForm();
        setSuccessMessage(true);
      },
      onError: (err) => {
        toastError({
          title: 'Support ticket',
          text:
            err instanceof AxiosError
              ? err.response?.data?.payload?.message
              : 'Something went wrong. Please refresh the page and try again.',
        });
      },
    });
  };

  const validateFiles = (files: File[]) => {
    // Check file count
    if (files.length > 3) {
      toastError({
        title: 'File upload',
        text: (
          <p className="m-0">
            File upload limit reached. You can upload a maximum of {maxSupportFiles} files.
          </p>
        ),
      });
    }

    // Check for oversized files only when within the limit
    const oversizedFiles = files.filter((file) => file.size > maxFileSize);
    if (files.length <= 3 && oversizedFiles.length > 0) {
      toastError({
        title: 'File upload',
        text: (
          <p className="m-0">
            File size exceeds the {parseFloat(formatFileSize(maxFileSize))}MB limit. Please upload a
            smaller file.
          </p>
        ),
      });
    }

    return files.slice(0, maxSupportFiles).filter((file) => file.size <= maxFileSize);
  };

  if (successMessage) {
    return <SuccessMessage onClose={closeModal} />;
  }

  return (
    <Formik<SupportFormFields>
      initialValues={{
        email: user?.payload.email || '',
        problem_type: prefilledFormData?.problem_type || ProblemType.TECH_ISSUE,
        error_id_select: '',
        error_id_input: '',
        problem_area: ProblemArea.GENERAL,
        entity_identifier: '',
        severity_level: SeverityLevel.LEVEL_0,
        subject: prefilledFormData?.subject || '',
        description: prefilledFormData?.description || '',
        support_file: [],
        custom_field_ids: [],
      }}
      enableReinitialize
      validationSchema={SupportFormValidationSchema}
      onSubmit={(values, { resetForm }) => {
        const { error_id_input, error_id_select } = values;
        const data = { ...values, error_id: error_id_input || error_id_select };

        if (values.support_file.length) {
          // we have files to upload
          const supportUploadFields = values.support_file.map((file) => ({
            action: SupportUploadActions.ADD,
            support_file: file,
          }));

          uploadSupportFile(supportUploadFields, {
            onSuccess: (filenames) => {
              // create new ticket with uploaded filenames
              createNewSupportTicket({ ...data, file_uploads_names: filenames }, resetForm);
            },
            onError: (err) => {
              toastError({
                title: 'File upload',
                text:
                  err instanceof AxiosError
                    ? err.response?.data?.payload?.message
                    : 'Something went wrong. Please refresh the page and try again.',
              });
            },
          });
        } else {
          // we have simple ticket without uploaded files
          createNewSupportTicket(data, resetForm);
        }
      }}
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} id="support_form">
            <div className="px-3 position-relative z-index-2">
              <div className="d-flex align-items-center">
                <p className="fs-1 fw-bold mb-1">Support</p>
                <span className="ms-2 ps-2 fw-semibold fs-6 border-start">
                  {user?.payload.first_name} {user?.payload.last_name}
                </span>
                <span>
                  <span className="ms-2">{account?.name}</span>
                </span>
              </div>
              <p className="fs-6 mb-8">
                Our team works 24/7 and is happy to assist you. Please fill out the form below, and
                we’ll get back to you quickly. While you’re waiting, please check our{' '}
                <a target="blank" href="https://ongage.atlassian.net/wiki/display/HELP/Home">
                  Help Wiki
                </a>
                . You can find there everything you need to get started.
              </p>
            </div>
            <FormSection title="">
              <div className="row gy-6 mx-auto">
                <div className="col-6">
                  <FormikTextField name="email" label="Reply to email" />
                </div>

                <div className="col-6">
                  <FormikTextField
                    name="problem_type"
                    label="Problem type"
                    renderInput={({ value, onChange: _, defaultValue: __, ...inputProps }) => {
                      return (
                        <Select
                          {...inputProps}
                          options={problemTypeOptions}
                          value={problemTypeOptions.filter((format) => value === format.value)}
                          onChange={(option) =>
                            option?.value && formik.setFieldValue('problem_type', option.value)
                          }
                        />
                      );
                    }}
                  />
                </div>

                <div className={formik.values.error_id_select ? 'col-12' : 'col-6'}>
                  <FormikTextField
                    name="error_id_select"
                    label="Error"
                    renderInput={({ value, onChange: _, defaultValue: __, ...inputProps }) => {
                      return (
                        <Select
                          {...inputProps}
                          isClearable
                          isSearchable
                          placeholder={errPlaceholder}
                          onFocus={() => setErrPlaceholder('Select or type to search')}
                          onBlur={() => setErrPlaceholder('Select')}
                          options={errorIdSelectOptions}
                          value={
                            errorIdSelectOptions?.filter((format) => value === format.value) || null
                          }
                          onChange={(option) => {
                            formik.setFieldValue('error_id_select', option ? option.value : '');
                            // clear manually entered ID as this input is not visible now
                            formik.setFieldValue('error_id_input', option?.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                {formik.values.error_id_select === '' && (
                  <div className="col-6">
                    <FormikTextField
                      name="error_id_input"
                      label="or insert Error ID"
                      placeholder="Insert Error ID"
                    />
                  </div>
                )}

                <div className="col-6">
                  <FormikTextField
                    name="problem_area"
                    label="Problem area"
                    renderInput={({ value, onChange: _, defaultValue: __, ...inputProps }) => {
                      return (
                        <Select
                          {...inputProps}
                          options={problemAreaOptions}
                          value={problemAreaOptions.filter((format) => value === format.value)}
                          onChange={(option) => {
                            if (option?.value) {
                              formik.setFieldValue('problem_area', option.value);
                              basicProblemAreas.includes(option.value) &&
                                formik.setFieldValue('entity_identifier', '');
                            }
                          }}
                        />
                      );
                    }}
                  />
                </div>

                {enableIdentField(formik.values.problem_area) && (
                  <div className="col-6">
                    <FormikTextField
                      name="entity_identifier"
                      label={identFieldLabel(formik.values.problem_area)}
                    />
                  </div>
                )}

                <div
                  className={`${enableIdentField(formik.values.problem_area) ? 'col-12 ' : 'col-6'}`}
                >
                  <div className="d-flex">
                    <p className="form-label fw-semibold text-gray-900 fs-6">Severity level</p>
                    <span
                      onMouseEnter={() => debouncedShow(true)}
                      onMouseLeave={() => debouncedShow(false)}
                    >
                      <Tooltip
                        placement="top"
                        colorTheme="dark"
                        show={showTooltip}
                        description={<SeverityLevelsExplained />}
                      >
                        <span className="ms-3" tabIndex={0}>
                          <Icon name="infoIcon" width={14} height={14} cursor="pointer" />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <FormikTextField
                    name="severity_level"
                    placeholder="--NONE--"
                    renderInput={({ value, onChange: _, defaultValue: __, ...inputProps }) => {
                      return (
                        <Select
                          {...inputProps}
                          options={severityLevelOptions}
                          value={severityLevelOptions.filter((format) => value === format.value)}
                          onChange={(option) =>
                            option?.value && formik.setFieldValue('severity_level', option.value)
                          }
                        />
                      );
                    }}
                  />
                </div>

                <div className="col-12">
                  <FormikTextField
                    name="subject"
                    label="Subject"
                    placeholder="Enter your subject"
                  />
                </div>

                <div className="col-12">
                  <FormikTextArea
                    name="description"
                    label="Description"
                    rows={4}
                    placeholder="Leave us a message"
                  />
                </div>

                <div className="col-12 mb-5">
                  <Dropzone
                    multiple={true}
                    accept={{
                      'image/jpeg': ['.jpg', '.jpeg'],
                      'image/png': ['.png'],
                      'image/gif': ['.gif'],
                      'image/bmp': ['.bmp'],
                      'text/plain': ['.txt'],
                      'text/csv': ['.csv'],
                      'text/xml': ['.xml'],
                      'application/vnd.ms-excel': ['.xls'],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                        '.xlsx',
                      ],
                      'application/msword': ['.doc'],
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                        '.docx',
                      ],
                      'text/html': ['.html'],
                      'application/pdf': ['.pdf'],
                      'application/x-zip-compressed': ['.zip'],
                      'application/x-rar-compressed': ['.rar'],
                    }}
                    noClick
                    noKeyboard
                    onDrop={(files) => {
                      // All files currently added plus those that are being dropped in right now
                      const filesToCheck = [...formik.values.support_file, ...files];
                      formik.setFieldValue('support_file', validateFiles(filesToCheck));
                    }}
                    additionalInfoText={`Images, text files, zip archives (max. ${parseFloat(formatFileSize(maxFileSize))}MB.)`}
                    tooltip={
                      <span
                        onMouseEnter={() => debouncedFileUploadTooltip(true)}
                        onMouseLeave={() => debouncedFileUploadTooltip(false)}
                      >
                        <Tooltip
                          placement="top"
                          colorTheme="dark"
                          show={fileUploadTooltip}
                          description="Accepted file formats: jpg, jpeg, png, gif, bmp, txt, csv, xml, xls, xlsx, doc, docx, html, pdf, zip, rar."
                        >
                          <span className="ms-2" tabIndex={0}>
                            <Icon name="infoIcon" width={14} height={14} cursor="pointer" />
                          </span>
                        </Tooltip>
                      </span>
                    }
                  />
                </div>

                <FieldArray name="support_file">
                  {({ remove }) => (
                    <div className="mt-1">
                      {formik.values.support_file.map((file, index) => (
                        <div
                          key={`${file.name}-${index}`}
                          className="border rounded fs-5 my-4 p-4 d-flex align-items-center"
                        >
                          {isImage(formik.values.support_file[index]) && (
                            <img
                              src={URL.createObjectURL(formik.values.support_file[index])}
                              alt="Account logo"
                              className={styles.uploadImagePreview}
                            />
                          )}
                          <span
                            className={clsx('me-3 fw-medium', styles.filename, {
                              [styles.noImageSpacer]:
                                formik.values.support_file.some((file) => isImage(file)) &&
                                !isImage(formik.values.support_file[index]),
                            })}
                          >
                            {file.name}
                          </span>{' '}
                          <span className={clsx('me-3', styles.fileSize)}>
                            {formatFileSize(file.size)}
                          </span>
                          <Icon
                            name="trash"
                            className={styles.icon}
                            hoverStroke="danger"
                            onClick={() => remove(index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </div>
            </FormSection>

            <div className="d-flex gap-3 justify-content-end pt-6 px-3">
              <Button
                type="button"
                data-bs-dismiss="modal"
                variant="secondary"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="info"
                form="support_form"
                isLoading={isPendingFileUpload || isPendingNewTicket}
              >
                Send
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

type SuccessMsgProps = {
  onClose: () => void;
};

const SuccessMessage: FC<SuccessMsgProps> = ({ onClose }) => {
  return (
    <>
      <h1 className="z-index-2 position-relative">Support</h1>
      <h2 className="mt-10">Thank you for contacting Ongage Support</h2>
      <p>Your inquiry is important to us and will be addressed as soon as possible.</p>
      <div className="d-flex justify-content-end">
        <Button variant="info" onClick={() => onClose()}>
          Close
        </Button>
      </div>
    </>
  );
};
