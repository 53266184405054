export enum CONTACT_STATUSES {
  ACTIVE = 'active',
  HARD_BOUNCED = 'hard_bounced',
  COMPLAINT = 'complaint',
  UNSUBSCRIBED = 'unsubscribed',
}

export enum EMAIL_VALIDATION_ENTITY_TYPE {
  LIST = 'LIST',
  SEGMENT = 'SEGMENT',
}

export enum EMAIL_VALIDATION_STATUS {
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
  PREPARED = 'PREPARED',
  IN_PROGRESS = 'IN_PROGRESS',
  QUEUED = 'QUEUED',
}

export type EmailValidationEntity = {
  id: number;
  list_id: number;
  account_id: number;
  contact_statuses: CONTACT_STATUSES[];
  name: string;
  validated_entity_type: EMAIL_VALIDATION_ENTITY_TYPE;
  validated_entity_id: number;
  notify_oncomplete: boolean;
  status: EMAIL_VALIDATION_STATUS;
  display_status: EMAIL_VALIDATION_STATUS;
  status_date: number;
  status_code: string; // TODO: study fapi code a little bit more to find out if it can be enumed
  total_contacts_count: number;
  validated_contacts_count: number;
  unknown_contacts_count: number;
};
