import { FC, useState } from 'react';
import { ICONS } from '@styles/icons';
import { Icon } from '@ui/Icon/Icon';
import { Accordion } from 'react-bootstrap';

import accordionStyles from './Accordion.module.scss';
import { CustomToggle } from './CustomToggle';
import styles from './Feature.module.scss';
import commonStyles from '../styles/Pricing.module.scss';

export type Feature = {
  icon: keyof typeof ICONS;
  title: string;
  description: string;
};

export const Feature: FC<{ features: Feature[] }> = ({ features }) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  return (
    <Accordion>
      {features.map((feature, index) => (
        <Accordion.Item
          eventKey={index.toString()}
          key={index}
          className={accordionStyles.item}
          onClick={() => handleSelect(index)}
        >
          <Accordion.Header className={accordionStyles.header}>
            <CustomToggle
              eventKey={index.toString()}
              isActive={activeKey === index}
              openIcon="chevronUp"
              closedIcon="chevronDown"
              stroke="dark"
              leftAligned
            >
              <div className={styles.featureHeader}>
                <div className={commonStyles.featureIcon}>
                  <Icon name={feature.icon} stroke="rose" width={22} height={22} />
                </div>
                <h3>{feature.title}</h3>
              </div>
            </CustomToggle>
          </Accordion.Header>
          <Accordion.Body className={accordionStyles.body}>
            <p className={styles.featuresDescr}>{feature.description}</p>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
