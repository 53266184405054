import { FC } from 'react';
import { useAllListsSelected, useSelectedList } from '@module/list/store/useListStore';
import { Icon } from '@ui/Icon/Icon';
import clsx from 'clsx';

import { ListOfLists } from './ListOfLists';
import styles from './ListOfLists.module.scss';
import { useSidebarAnimating, useSidebarMinimized } from '../sidebar-store';

export const ListOfListsTrigger: FC = () => {
  const selectedList = useSelectedList();
  const allListsSelected = useAllListsSelected();
  const isSidebarMinimized = useSidebarMinimized();
  const isSidebarAnimating = useSidebarAnimating();

  const selectedListName = allListsSelected ? 'All lists' : selectedList?.name;

  return (
    <div className="menu menu-column" data-kt-menu="true">
      <div
        className={clsx('menu-item', styles.trigger, { 'ps-4': !isSidebarMinimized })}
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="right-start"
        data-kt-menu-offset="0,1"
      >
        <button
          className={clsx('btn d-flex align-items-center px-4 py-3 w-100 fs-6 fw-bold', {
            'justify-content-between': !isSidebarMinimized && !isSidebarAnimating,
            'justify-content-center': isSidebarMinimized,
            'justify-content-end': !isSidebarMinimized && isSidebarAnimating,
          })}
        >
          {!isSidebarMinimized && !isSidebarAnimating && (
            <span className="text-truncate">{selectedListName}</span>
          )}
          <span>
            <Icon className={styles.chevron} name="chevronDown" />
          </span>
        </button>
        <div
          className={clsx(
            {
              'menu-sub': !isSidebarAnimating && !isSidebarMinimized,
            },
            'menu-sub-dropdown rounded-0 w-250px vh-100 py-2 shadow-none bg-light-subtle z-2 overflow-auto border-end',
          )}
        >
          <ListOfLists />
        </div>
      </div>
    </div>
  );
};
