import { TimezoneData } from '@module/list/store/models';
import axios from 'axios';

import config from '../../../../app-config';
import { FapiResponse } from '../models/FapiResponse';

const API_URL = config.fapiUrl;
const SERVERSIDE_TIMEZONES_URL = `${API_URL}/api/timezone`;

export const getTimezones = async () =>
  axios.get<FapiResponse<Array<TimezoneData>>>(SERVERSIDE_TIMEZONES_URL).then(({ data }) => ({
    ...data,
    payload: data.payload.map((d, idx) => ({ ...d, id: idx })),
  }));
