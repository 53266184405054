import { Feature } from '../components/Feature';
import { EDGE_VALUES } from '../utils';

// features cards
export const features = [
  {
    icon: 'lineChart',
    title: 'Advanced Al analytics',
    description: 'Explore the full potential of our cutting-edge Advanced AI analytics feature.',
  },
  {
    icon: 'zap',
    title: 'Marketing automation builder',
    description: 'Experience the power of our new Marketing Automation Studio feature.',
  },
  {
    icon: 'dataflowVert',
    title: 'Data & behavioral segmentation',
    description:
      'Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.',
  },
  {
    icon: 'beaker',
    title: 'A/B testing',
    description:
      'Solve a problem or close a sale in real-time with chat. If no one is available, customers are seamlessly routed to email without confusion.',
  },
  {
    icon: 'mailOpen',
    title: 'Email template designer',
    description:
      'Explore 100+ integrations that make your day-to-day workflow more efficient and familiar. Plus, our extensive developer tools.',
  },
  {
    icon: 'messageSquare',
    title: 'SMS',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'file',
    title: 'Form builder',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'fileCode',
    title: 'API access',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'intersectCircle',
    title: 'Litmus Integration (additional cost)',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
] as Feature[];

// more features
export const moreFeatures = [
  {
    icon: 'archiveList',
    title: 'Mail-tester integration',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'cursorBox',
    title: 'Connect on-premise MTA',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'command',
    title: 'Custom data fields',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'database',
    title: 'Multi databases (Lists)',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'contrast',
    title: 'Dynamic content feed',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'cursorClick',
    title: 'STO',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'pin',
    title: 'Geolocation & device stats',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'command',
    title: 'Geolocation & device stats',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'usersPlus',
    title: 'User roles & permissions',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'crop',
    title: 'Customer success manager',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'userCheck',
    title: 'Premium consultation',
    description:
      'We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
  {
    icon: 'headphonesBig',
    title: '24/7 Premium support',
    description:
      'We’re an extension of your customer servic, and all of our resources are free. Chat to our friendly team 24/7 when you need help.',
  },
] as Feature[];

// faq
export const faqs = [
  {
    q: 'What sets Ongage apart from competitors?',
    a: 'Ongage is an platform email marketing platform that offers that best of two worlds, email marketing and data management. Ongage offers companies who run large email marketing operations unparalleled control and insights into their operation, while making it accessible simplifying campaign management, providing better control over operations that get out of hand otherwise, and aiding in micro examination of your overall performance in key metrics. All of the above allow you to make decisions that are based on data, and provides you with email marketing peace of mind.',
  },
  {
    q: 'How can I send emails via Ongage?',
    a: 'Ongage has its own SMTP relay called Ongage SMTP. In addition, you can send emails via our partners, which include Amazon SES, SparkPost, Mailgun, SocketLabs, Sendgrid, and many more. You can add your on-premise MTA like PowerMTA and SMS service providers that are included in our partner network.',
  },
  {
    q: 'Do you provide live training sessions/onboarding help?',
    a: 'All our plans provide live training, consultancy and onboarding help.',
  },
  {
    q: 'Can I get a free trial to evaluate the product?',
    a: 'Ongage is an platform email marketing platform...',
  },
  {
    q: 'How fast is your set up?',
    a: 'It’s relatively fast, no development required. We will be with you in every step of the way.',
  },
  {
    q: 'Do you offer special services for email marketers?',
    a: 'Yes, we do. Check out our Modules & Services tab above.',
  },
];

// Range slider data
export const sendingVolumeData = [
  200_000,
  300_000,
  500_000,
  700_000,
  900_000,
  1_200_000,
  1_500_000,
  2_000_000,
  2_500_000,
  3_000_000,
  3_500_000,
  4_000_000,
  5_000_000,
  6_000_000,
  7_000_000,
  8_000_000,
  10_000_000,
  12_000_000,
  15_000_000,
  20_000_000,
  EDGE_VALUES.MAX_SENDING,
];

export const allowedContactsData = [
  10_000,
  15_000,
  25_000,
  35_000,
  45_000,
  60_000,
  75_000,
  100_000,
  125_000,
  150_000,
  175_000,
  200_000,
  250_000,
  300_000,
  350_000,
  400_000,
  500_000,
  600_000,
  750_000,
  1_000_000,
  EDGE_VALUES.MAX_CONTACTS,
];

export const priceData = [
  149,
  219,
  359,
  499,
  625,
  819,
  999,
  1_299,
  1_559,
  1_799,
  1_999,
  2_199,
  2_499,
  2_799,
  2_999,
  3_259,
  3_699,
  4_099,
  4_699,
  5_799,
  EDGE_VALUES.MAX_PRICE,
];

export const monthlyPaymentLinks = [
  'https://sandbox.bluesnap.com/buynow/checkout?sku3096595=1&storeid=7235',
  'https://sandbox.bluesnap.com/buynow/checkout?sku3096597=1&storeid=7235',
];

export const yearlyPaymentLinks = [
  'https://sandbox.bluesnap.com/buynow/checkout?sku3096601=1&storeid=7235',
  'https://sandbox.bluesnap.com/buynow/checkout?sku3096603=1&storeid=7235',
];
