import { useMemo } from 'react';
import { PAGE_TITLE } from '@utils/routes';
import { matchPath, useLocation } from 'react-router-dom';

export const usePageTitleNameByLocation = (): string => {
  const location = useLocation();

  return useMemo(() => {
    // Match current location to routes
    const matchedRoute = Object.keys(PAGE_TITLE).find((route) =>
      matchPath({ path: route, end: true }, location.pathname),
    );

    return matchedRoute ? PAGE_TITLE[matchedRoute] : 'Ongage';
  }, [location.pathname]);
};
