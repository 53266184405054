import { FC, ReactElement, useState } from 'react';
import { SupportFormModal } from '@module/settings/components/account-settings/components/SupportFormModal';

export const SupportModalLink: FC<{ title: ReactElement }> = ({ title }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div onClick={() => setShow((s) => !s)}>{title}</div>
      <SupportFormModal show={show} onClose={() => setShow(false)} />
    </>
  );
};
