import { FC, PropsWithChildren } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

import { LayoutProvider } from '../../_metronic/layout/core';
import { MetronicSplashScreenProvider } from '../../_metronic/layout/core';
import { MasterInit } from '../../_metronic/layout/MasterInit';
import { ThemeModeProvider } from '../../_metronic/partials';

import '../../_metronic/assets/fonticon/fonticon.css';
import '../../_metronic/assets/keenicons/duotone/style.css';
import '../../_metronic/assets/keenicons/outline/style.css';
import '../../_metronic/assets/keenicons/solid/style.css';

import '../../_metronic/assets/sass/style.scss';
import '../../_metronic/assets/sass/plugins.scss';
import '../../_metronic/assets/sass/style.react.scss';

export const MetronicProviders: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <LayoutProvider>
      <ThemeModeProvider>
        <MetronicSplashScreenProvider>
          <SkeletonTheme>
            {children}
            <MasterInit />
          </SkeletonTheme>
        </MetronicSplashScreenProvider>
      </ThemeModeProvider>
    </LayoutProvider>
  );
};
