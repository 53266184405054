import React, { FC, lazy, PropsWithChildren, Suspense, useEffect } from 'react';
import { useAccessToken } from '@module/auth/store/authTokensStore';
import { useUserPermissions } from '@module/shared/hooks/useRoutePermissionsCheck';
import { UserPermissions } from '@utils/permissionsCheck';
import { ROUTES } from '@utils/routes';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import { useWrappedTo } from './to';
import { WithChildren } from '../../_metronic/helpers';
import { LayoutSplashScreen } from '../../_metronic/layout/core';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';

// Pages
const DashboardPage = lazy(() => import('@pages/list/dashboard/DashboardPage'));
const ImportPage = lazy(() => import('@pages/list/import/ImportPage'));
const ExportPage = lazy(() => import('@pages/list/export/ExportPage'));
const SegmentsPage = lazy(() => import('@pages/list/segments/SegmentsPage'));
const NewSegmentPage = lazy(() => import('@pages/list/segments/NewSegmentPage'));
const IframeEditSegmentPage = lazy(() => import('@pages/list/segments/EditSegmentPage'));
const ContactsManagerPage = lazy(() => import('@pages/list/contacts-manager/ContactsManagerPage'));
const NewContactsSearchPage = lazy(
  () => import('@pages/list/contacts-manager/NewContactsSearchPage'),
);
const ContactSearchReportPage = lazy(
  () => import('@pages/list/contacts-manager/ContactSearchReportPage'),
);
const FormBuilderPage = lazy(() => import('@pages/list/form-builder/FormBuilderPage'));
const NewFormBuilderPage = lazy(() => import('@pages/list/form-builder/NewFormBuilderPage'));
const ConversionPointsPage = lazy(
  () => import('@pages/list/conversion-points/ConversionPointsPage'),
);
const NewConversionPointPage = lazy(
  () => import('@pages/list/conversion-points/NewConversionPointPage'),
);
const EditConversionPointPage = lazy(
  () => import('@pages/list/conversion-points/EditConversionPointPage'),
);
const ListSettingsPage = lazy(() => import('../pages/list/settings/ListSettingsPage'));
const ProfileSettingsPage = lazy(() => import('../pages/settings/ProfileSettingsPage'));
const AccountSpecsPage = lazy(() => import('../pages/settings/AccountSpecsPage'));
const DomainRoutingPage = lazy(() => import('../pages/settings/DomainRoutingPage'));
const AccountSettingsPage = lazy(() => import('../pages/settings/AccountSettingsPage'));
const TrackingImageDomains = lazy(() => import('../pages/settings/TrackingImageDomains'));
const UserManagement = lazy(() => import('../pages/settings/UserManagement'));
const ListManagement = lazy(() => import('../pages/settings/ListManagement'));
const AutomationsAnalyticsPage = lazy(() => import('../pages/marketing-automation/AnalyticsPage'));
const WorkflowNodesAnalyticsPage = lazy(
  () => import('../pages/marketing-automation/WorkflowNodesAnalyticsPage'),
);

// IFrames
const MarketingAutomationPage = lazy(
  () => import('../pages/marketing-automation/MarketingAutomationPage'),
);

const AIToolPage = lazy(() => import('../pages/AI/AIToolPage'));
const AutomationRulesPage = lazy(() => import('@pages/list/automation-rules/AutomationRulesPage'));
const CampaignOverviewPage = lazy(() => import('../pages/campaign/OverviewPage'));
const ABPage = lazy(() => import('../pages/campaign/ABPage'));
const CalendarPage = lazy(() => import('../pages/campaign/CalendarPage'));
const JourneyPage = lazy(() => import('../pages/campaign/JourneyPage'));
const CampaignTransactionalPage = lazy(() => import('../pages/campaign/TransactionalPage'));
const EventsAndTriggersPage = lazy(() => import('../pages/campaign/EventsAndTriggersPage'));
const MyConnectionsPage = lazy(() => import('../pages/vendors/MyConnectionsPage'));
const SMSVendorsPage = lazy(() => import('../pages/vendors/SMSVendorsPage'));
const ESPAndSMTPVendorsPage = lazy(() => import('../pages/vendors/ESPAndSMTPVendorsPage'));
const EditConnectionPage = lazy(() => import('../pages/vendors/EditConnectionPage'));
const EmailMessagesPage = lazy(() => import('../pages/content/EmailMessagesPage'));
const EmailMessagesFormPage = lazy(() => import('../pages/content/EmailMessagesFormPage'));
const SMSMessagesPage = lazy(() => import('../pages/content/SMSMessagesPage'));
const ImageLibraryPage = lazy(() => import('../pages/content/ImageLibraryPage'));
const TemplatesLibraryPage = lazy(() => import('../pages/content/TemplatesLibraryPage'));
const TransactionalPage = lazy(() => import('../pages/analytics/TransactionalPage'));
const OverviewPage = lazy(() => import('../pages/analytics/OverviewPage'));
const AggregatePage = lazy(() => import('../pages/analytics/AggregatePage'));
const EventsPage = lazy(() => import('../pages/analytics/EventsPage'));
const MatrixPage = lazy(() => import('../pages/analytics/MatrixPage'));
const ContactActivityPage = lazy(() => import('../pages/analytics/ContactActivityPage'));
const InsightsPage = lazy(() => import('../pages/analytics/InsightsPage'));
const SplitCampaignsPage = lazy(() => import('../pages/analytics/SplitCampaignsPage'));
const CustomAggregateReportPage = lazy(
  () => import('../pages/analytics/CustomAggregateReportPage'),
);
const EmailValidationPage = lazy(() => import('../pages/email-validation/EmailValidationPage'));
const EmailValidationFormPage = lazy(
  () => import('../pages/email-validation/EmailValidationFormPage'),
);
const IframeEditFormBuilderPage = lazy(
  () => import('@pages/list/form-builder/EditFormBuilderPage'),
);
const IframeCopyFormBuilderPage = lazy(
  () => import('@pages/list/form-builder/CopyFormBuilderPage'),
);
const NopIndexPage = lazy(() => import('../pages/nop/NopIndexPage'));
const SetupWelcome = lazy(() => import('../pages/setup/SetupWelcome'));

const routes: Array<{
  path: string;
  element: React.LazyExoticComponent<React.FC>;
  supportsAllList?: boolean;
  isAccessible: keyof UserPermissions;
}> = [
  {
    path: ROUTES.LIST.DASHBOARD,
    element: DashboardPage,
    supportsAllList: true,
    isAccessible: 'hasDashboardViewPermission',
  },
  {
    path: ROUTES.LIST.IMPORT,
    element: ImportPage,
    isAccessible: 'hasListImportViewPermission',
  },
  {
    path: ROUTES.LIST.EXPORT,
    element: ExportPage,
    isAccessible: 'hasListExportViewPermission',
  },
  {
    path: ROUTES.LIST.SEGMENTS,
    element: SegmentsPage,
    isAccessible: 'hasSegmentsViewPermission',
  },
  {
    path: ROUTES.LIST.NEW_SEGMENTS,
    element: NewSegmentPage,
    isAccessible: 'hasNewSegmentsViewPermission',
  },
  {
    path: ROUTES.LIST.EDIT_SEGMENT,
    element: IframeEditSegmentPage,
    isAccessible: 'hasEditSegmentViewPermission',
  },
  {
    path: ROUTES.LIST.CONTACTS_MANAGER,
    element: ContactsManagerPage,
    isAccessible: 'hasContactsManagerViewPermission',
  },
  {
    path: ROUTES.LIST.NEW_CONTACT_SEARCH,
    element: NewContactsSearchPage,
    isAccessible: 'hasNewContactsSearchViewPermission',
  },
  {
    path: ROUTES.LIST.CONTACT_SEARCH_REPORT,
    element: ContactSearchReportPage,
    isAccessible: 'hasContactSearchReportViewPermission',
  },
  {
    path: ROUTES.LIST.FORM_BUILDER,
    element: FormBuilderPage,
    isAccessible: 'hasFormBuilderViewPermission',
  },
  {
    path: ROUTES.LIST.NEW_FORM_BUILDER,
    element: NewFormBuilderPage,
    isAccessible: 'hasNewFormBuilderViewPermission',
  },
  {
    path: ROUTES.LIST.EDIT_FORM_BUILDER,
    element: IframeEditFormBuilderPage,
    isAccessible: 'hasEditFormBuilderViewPermission',
  },
  {
    path: ROUTES.LIST.COPY_FORM_BUILDER,
    element: IframeCopyFormBuilderPage,
    isAccessible: 'hasCopyFormBuilderViewPermission',
  },
  {
    path: ROUTES.LIST.CONVERSION_POINTS,
    element: ConversionPointsPage,
    isAccessible: 'hasConversionPointsViewPermission',
  },
  {
    path: ROUTES.LIST.NEW_CONVERSION_POINTS,
    element: NewConversionPointPage,
    isAccessible: 'hasNewConversionPointsViewPermission',
  },
  {
    path: ROUTES.LIST.EDIT_CONVERSION_POINT,
    element: EditConversionPointPage,
    isAccessible: 'hasEditConversionPointViewPermission',
  },
  {
    path: ROUTES.LIST.AUTOMATION_RULES,
    element: AutomationRulesPage,
    isAccessible: 'hasAutomationRulesViewPermission',
  },
  {
    path: ROUTES.LIST.SETTINGS,
    element: ListSettingsPage,
    isAccessible: 'hasListSettingsViewPermission',
  },
  {
    path: ROUTES.AUTOMATION.ANALYTICS,
    element: AutomationsAnalyticsPage,
    supportsAllList: true,
    isAccessible: 'hasAutomationsAnalyticsViewPermission',
  },
  {
    path: ROUTES.AUTOMATION.ANALYTICS_BY_WORKFLOW,
    element: WorkflowNodesAnalyticsPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsByWorkflowViewPermission',
  },
  {
    path: ROUTES.AUTOMATION.LIST,
    element: MarketingAutomationPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsByWorkflowViewPermission',
  },
  {
    path: ROUTES.AI,
    element: AIToolPage,
    supportsAllList: true,
    isAccessible: 'hasAIViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.OVERVIEW,
    element: CampaignOverviewPage,
    supportsAllList: true,
    isAccessible: 'hasCampaignOverviewViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.A_B,
    element: ABPage,
    isAccessible: 'hasCampaignsABViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.CALENDAR,
    element: CalendarPage,
    supportsAllList: true,
    isAccessible: 'hasCampaignsCalendarViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.JOURNEYS,
    element: JourneyPage,
    isAccessible: 'hasCampaignsJourneysViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.TRANSACTIONAL,
    element: CampaignTransactionalPage,
    isAccessible: 'hasCampaignsTransactionalViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.EVENTS_AND_TRIGGERS,
    element: EventsAndTriggersPage,
    isAccessible: 'hasEventsAndTriggersViewPermission',
  },
  {
    path: ROUTES.VENDORS.CONNECTIONS,
    element: MyConnectionsPage,
    supportsAllList: true,
    isAccessible: 'hasConnectionsViewPermission',
  },
  {
    path: ROUTES.VENDORS.SMS,
    element: SMSVendorsPage,
    supportsAllList: true,
    isAccessible: 'hasSMSVendorsViewPermission',
  },
  {
    path: ROUTES.VENDORS.ESP_AND_SMTP,
    element: ESPAndSMTPVendorsPage,
    supportsAllList: true,
    isAccessible: 'hasESPAndSMTPVendorsViewPermission',
  },
  {
    path: ROUTES.VENDORS.EDIT_CONNECTION,
    element: EditConnectionPage,
    supportsAllList: true,
    isAccessible: 'hasEditConnectionViewPermission',
  },
  {
    path: ROUTES.CONTENT.EMAILS,
    element: EmailMessagesPage,
    isAccessible: 'hasContentEmailsViewPermission',
  },
  {
    path: ROUTES.CONTENT.EMAIL_FORM,
    element: EmailMessagesFormPage,
    isAccessible: 'hasContentEmailFormViewPermission',
  },
  {
    path: ROUTES.CONTENT.SMS,
    element: SMSMessagesPage,
    isAccessible: 'hasContentSMSViewPermission',
  },
  {
    path: ROUTES.CONTENT.IMAGES,
    element: ImageLibraryPage,
    isAccessible: 'hasContentImagesViewPermission',
  },
  {
    path: ROUTES.CONTENT.TEMPLATES,
    element: TemplatesLibraryPage,
    isAccessible: 'hasContentTemplatesViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.TRANSACTIONAL,
    element: TransactionalPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsTransactionalViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.OVERVIEW,
    element: OverviewPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsOverviewViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.AGGREGATE,
    element: AggregatePage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsAggregateViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.EVENTS,
    element: EventsPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsEventsViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.MATRIX,
    element: MatrixPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsMatrixViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.CONTACTS_ACTIVITY,
    element: ContactActivityPage,
    isAccessible: 'hasAnalyticsContactsActivityViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.INSIGHTS,
    element: InsightsPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsInsightsViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.SPLIT_CAMPAIGNS,
    element: SplitCampaignsPage,
    supportsAllList: true,
    isAccessible: 'hasAnalyticsSplitCampaignsViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.AGGREGATE_REPORT,
    element: CustomAggregateReportPage,
    isAccessible: 'hasAnalyticsAggregateReportViewPermission',
  },
  {
    path: ROUTES.VALIDATION.INDEX,
    element: EmailValidationPage,
    supportsAllList: true,
    isAccessible: 'hasValidationIndexViewPermission',
  },
  {
    path: ROUTES.VALIDATION.VALIDATION_FORM,
    element: EmailValidationFormPage,
    supportsAllList: true,
    isAccessible: 'hasValidationFormViewPermission',
  },
  {
    path: ROUTES.SETTINGS.PROFILE,
    element: ProfileSettingsPage,
    supportsAllList: true,
    isAccessible: 'hasProfilePermission',
  },
  {
    path: ROUTES.SETTINGS.ACCOUNT_SPECS,
    element: AccountSpecsPage,
    supportsAllList: true,
    isAccessible: 'hasAccountSpecsViewPermission',
  },
  {
    path: ROUTES.SETTINGS.DOMAIN_ROUTING,
    element: DomainRoutingPage,
    isAccessible: 'hasDomainRoutingViewPermission',
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.ACCOUNT_SETTINGS,
    element: AccountSettingsPage,
    supportsAllList: true,
    isAccessible: 'hasAccountSettingsViewPermission',
  },
  {
    path: ROUTES.SETTINGS.TRACKING_IMAGE_DOMAINS,
    element: TrackingImageDomains,
    supportsAllList: true,
    isAccessible: 'hasImageTrackingViewPermission',
  },
  {
    path: ROUTES.SETTINGS.USER_MANAGEMENT,
    element: UserManagement,
    supportsAllList: true,
    isAccessible: 'hasUserManagementViewPermission',
  },
  {
    path: ROUTES.SETTINGS.LIST_MANAGEMENT,
    element: ListManagement,
    supportsAllList: true,
    isAccessible: 'hasListManagementViewPermission',
  },
  {
    path: ROUTES.NOP.INDEX,
    element: NopIndexPage,
    supportsAllList: true,
    isAccessible: 'hasNopIndexViewPermission',
  },
  {
    path: ROUTES.SETUP.WELCOME,
    element: SetupWelcome,
    isAccessible: 'hasSetupWelcomeViewPermission',
  },
];

export const PrivateRoutes = () => {
  const accessToken = useAccessToken();
  const to = useWrappedTo();
  const { isLoading, permissions } = useUserPermissions();

  if (!accessToken) {
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }

  if (isLoading) {
    return <LayoutSplashScreen />;
  }

  const availableRoutes = routes.filter((route) =>
    permissions[route.isAccessible] ? true : false,
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to={to(ROUTES.LIST.DASHBOARD)} />} />
        {availableRoutes.map(({ path, element: Page, supportsAllList }, idx) => (
          <Route
            key={`${path}=${idx}`}
            path={path}
            element={
              <AllListsSupportGuard supportsAllLists={Boolean(supportsAllList)}>
                <SuspensedView>
                  <Page />
                </SuspensedView>
              </AllListsSupportGuard>
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

const AllListsSupportGuard: FC<
  PropsWithChildren<{
    supportsAllLists: boolean;
  }>
> = ({ supportsAllLists, children }) => {
  const to = useWrappedTo();
  const navigate = useNavigate();
  const { listId } = useParams();

  useEffect(() => {
    if (listId === 'all' && !supportsAllLists) {
      navigate(to(ROUTES.LIST.DASHBOARD));
    }
  }, [supportsAllLists, navigate, to, listId]);

  return <>{children}</>;
};
