import { ReactElement } from 'react';
import { matchPath } from 'react-router-dom';

import { NavItem } from './NavigationMenu';

export function isLink(item: NavItem): item is NavItem & { to: string } {
  return 'to' in item;
}

export function isSubmenu(item: NavItem): item is NavItem & { sub: NavItem[] } {
  return 'sub' in item;
}

export function isCustomItem(
  item: NavItem,
): item is NavItem & { render: (menuLink: ReactElement) => ReactElement } {
  return 'render' in item;
}

export function getActiveItemIdx(items: NavItem[], pathname: string): number | null {
  return items.findIndex(
    (item) =>
      (isLink(item) && !!matchPath(item.to, pathname)) ||
      (isSubmenu(item) && item.sub.some((item) => 'to' in item && !!matchPath(item.to, pathname))),
  );
}

export function ltrToUpperCase(ltr: string): string {
  const char = ltr.at(0);
  if (!char) {
    return ''; //?
  } else {
    return char.toLocaleUpperCase();
  }
}
