import { FC, useMemo } from 'react';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import { useUserPermissions } from '@module/shared/hooks/useRoutePermissionsCheck';
import { Badge } from '@ui/Badge';
import { Icon } from '@ui/Icon/Icon';
import { ROUTES } from '@utils/routes';
import { useIntl } from 'react-intl';

import { NavigationMenu, NavItem } from './NavigationMenu';

export const MainNavigationMenu: FC = () => {
  const intl = useIntl();

  const { data: accountSettings, isPending } = useAccountInfo();
  const { isLoading, permissions } = useUserPermissions();
  const menuItems = useMemo<NavItem[]>(
    () => [
      {
        title: 'List',
        icon: <Icon name="list" />,
        sub: [
          {
            title: intl.formatMessage({ id: 'MENU.LIST.DASHBOARD' }),
            to: ROUTES.LIST.DASHBOARD,
            disabled: !permissions.hasDashboardViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.IMPORT' }),
            to: ROUTES.LIST.IMPORT,
            disabled: !permissions.hasListImportViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.EXPORT' }),
            to: ROUTES.LIST.EXPORT,
            disabled: !permissions.hasListExportViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.SEGMENTS' }),
            to: ROUTES.LIST.SEGMENTS,
            disabled: !permissions.hasSegmentsViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.CONTACTS_MANAGER' }),
            to: ROUTES.LIST.CONTACTS_MANAGER,
            disabled: !permissions.hasContactsManagerViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.FORM_BUILDER' }),
            to: ROUTES.LIST.FORM_BUILDER,
            disabled: !permissions.hasFormBuilderViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.CONVERSION_POINTS' }),
            to: ROUTES.LIST.CONVERSION_POINTS,
            disabled: !permissions.hasConversionPointsViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.AUTOMATION_RULES' }),
            to: ROUTES.LIST.AUTOMATION_RULES,
            disabled: !permissions.hasAutomationRulesViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.SETTINGS' }),
            to: ROUTES.LIST.SETTINGS,
            disabled: !permissions.hasListSettingsViewPermission,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: 'MENU.CAMPAIGNS' }),
        icon: <Icon name="send" />,
        sub: [
          {
            title: intl.formatMessage({ id: 'MENU.CAMPAIGNS.OVERVIEW' }),
            to: ROUTES.CAMPAIGNS.OVERVIEW,
            disabled: !permissions.hasCampaignOverviewViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CAMPAIGNS.A_B' }),
            to: ROUTES.CAMPAIGNS.A_B,
            disabled: !permissions.hasCampaignsABViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CAMPAIGNS.EVENTS_AND_TRIGGERS' }),
            to: ROUTES.CAMPAIGNS.EVENTS_AND_TRIGGERS,
            disabled: !permissions.hasCampaignsJourneysViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CAMPAIGNS.TRANSACTIONAL' }),
            to: ROUTES.CAMPAIGNS.TRANSACTIONAL,
            disabled: !permissions.hasCampaignsTransactionalViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CAMPAIGNS.JOURNEYS' }),
            to: ROUTES.CAMPAIGNS.JOURNEYS,
            disabled: !permissions.hasCampaignsJourneysViewPermission,
            badge: <NewBadge />,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CAMPAIGNS.CALENDAR' }),
            to: ROUTES.CAMPAIGNS.CALENDAR,
            disabled: !permissions.hasCampaignsCalendarViewPermission,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: 'MENU.AUTOMATION' }),
        icon: <Icon name="dataflow" />,
        sub: [
          {
            title: intl.formatMessage({ id: 'MENU.AUTOMATION.WORKFLOWS' }),
            to: ROUTES.AUTOMATION.LIST,
            badge: <BetaBadge />,
            disabled: !permissions.hasAnalyticsByWorkflowViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.AUTOMATIONS.ANALYTICS' }),
            to: ROUTES.AUTOMATION.ANALYTICS,
            badge: <BetaBadge />,
            disabled: !permissions.hasAutomationsAnalyticsViewPermission,
          },
        ],
        blocked: !accountSettings?.settings.marketing_automation?.enable,
      },
      {
        title: intl.formatMessage({ id: 'MENU.CONTENT' }),
        icon: <Icon name="mail" />,
        sub: [
          {
            title: intl.formatMessage({ id: 'MENU.CONTENT.TEMPLATES' }),
            to: ROUTES.CONTENT.TEMPLATES,
            disabled: !permissions.hasContentTemplatesViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CONTENT.IMAGES' }),
            to: ROUTES.CONTENT.IMAGES,
            disabled: !permissions.hasContentImagesViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CONTENT.EMAILS' }),
            to: ROUTES.CONTENT.EMAILS,
            disabled: !permissions.hasContentEmailsViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.CONTENT.SMS' }),
            to: ROUTES.CONTENT.SMS,
            disabled: !permissions.hasContentSMSViewPermission,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: 'MENU.ANALYTICS' }),
        icon: <Icon name="pieChart" />,
        sub: [
          // TODO: exists in Legacy, gives 500 error, as soon as it resoved this one can be enabled
          /* {
            title: 'Insights',
            to: ROUTES.ANALYTICS.INSIGHTS,
            badge: <BetaBadge />,
            disabled: !hasAnalyticsInsightsPermissions,
          }, */
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.AI' }),
            to: ROUTES.AI,
            badge: <ExperimentalBadge />,
            blocked: !accountSettings?.settings.analytics_ai?.enable,
            disabled: !permissions.hasAIViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.OVERVIEW' }),
            to: ROUTES.ANALYTICS.OVERVIEW,
            disabled: !permissions.hasAnalyticsOverviewViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.AGGREGATE' }),
            to: ROUTES.ANALYTICS.AGGREGATE,
            disabled: !permissions.hasAnalyticsAggregateViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.MATRIX' }),
            to: ROUTES.ANALYTICS.MATRIX,
            disabled: !permissions.hasAnalyticsMatrixViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.SPLIT_CAMPAIGNS' }),
            to: ROUTES.ANALYTICS.SPLIT_CAMPAIGNS,
            disabled: !permissions.hasAnalyticsSplitCampaignsViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.EVENTS' }),
            to: ROUTES.ANALYTICS.EVENTS,
            disabled: !permissions.hasAnalyticsEventsViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.TRANSACTIONAL' }),
            to: ROUTES.ANALYTICS.TRANSACTIONAL,
            disabled: !permissions.hasAnalyticsTransactionalViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.CONTACTS_ACTIVITY' }),
            to: ROUTES.ANALYTICS.CONTACTS_ACTIVITY,
            disabled: !permissions.hasAnalyticsContactsActivityViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.ANALYTICS.AGGREGATE_REPORT' }),
            to: ROUTES.ANALYTICS.AGGREGATE_REPORT,
            disabled: !permissions.hasAnalyticsAggregateReportViewPermission,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: 'MENU.VENDORS' }),
        icon: <Icon name="plug" />,
        sub: [
          {
            title: intl.formatMessage({ id: 'MENU.VENDORS.CONNECTIONS' }),
            to: ROUTES.VENDORS.CONNECTIONS,
            disabled: !permissions.hasConnectionsViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.VENDORS.ESP_AND_SMTP' }),
            to: ROUTES.VENDORS.ESP_AND_SMTP,
            disabled: !permissions.hasESPAndSMTPVendorsViewPermission,
          },
          {
            title: intl.formatMessage({ id: 'MENU.VENDORS.SMS' }),
            to: ROUTES.VENDORS.SMS,
            disabled: !permissions.hasSMSVendorsViewPermission,
          },
        ],
      },
      {
        title: intl.formatMessage({ id: 'MENU.VALIDATION' }),
        icon: <Icon name="messageAccepted" />,
        to: ROUTES.VALIDATION.INDEX,
        disabled: !permissions.hasValidationIndexViewPermission,
      },
    ],
    [intl, accountSettings, permissions],
  );

  return <NavigationMenu items={menuItems} isLoading={isPending || isLoading} />;
};

// TODO: Think about passing color prop to badges
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BetaBadge = () => (
  <Badge
    variant="outline"
    style={{
      borderColor: 'rgba(21, 112, 239, 1)',
      color: 'rgba(23, 92, 211, 1)',
    }}
  >
    Beta
  </Badge>
);

const NewBadge = () => (
  <Badge
    style={{
      background: 'rgba(249, 245, 255, 1)',
      border: 'border: 1px solid',
      borderColor: 'rgba(233, 215, 254, 1)',
      color: 'rgba(105, 65, 198, 1)',
    }}
  >
    New
  </Badge>
);

const ExperimentalBadge = () => (
  <Badge
    style={{
      background: 'rgba(249, 245, 255, 1)',
      border: 'border: 1px solid',
      borderColor: 'rgba(233, 215, 254, 1)',
      color: 'rgba(105, 65, 198, 1)',
    }}
  >
    Experimental
  </Badge>
);
