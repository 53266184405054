import { FC } from 'react';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon/Icon';
import { ROUTES } from '@utils/routes';
import { useNavigate } from 'react-router-dom';

import styles from './ForgotPassword.module.scss';

export const PasswordResetComplete: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div className={styles.iconContainer}>
          <Icon name="checkCircle" width={25} height={25} />
        </div>
        <h2>Password reset</h2>
        <p className="fs-4 text-center">
          Your password has been successfully reset.
          <br />
          Click below to log in magically.
        </p>
      </div>
      <div className="d-flex flex-column justify-content-center pt-8 px-3">
        <Button
          variant="info"
          className="w-100"
          size="lg"
          onClick={() => navigate(ROUTES.AUTH.LOGIN)}
        >
          Back to log in
        </Button>
      </div>
    </>
  );
};
