import { FC, useMemo } from 'react';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { debounce } from 'lodash';

import { Icon } from '../Icon/Icon';
import { Input } from '../Input';

type SearchInputProps = {
  placeholder?: string;
};
export const SearchInput: FC<SearchInputProps> = ({ placeholder }) => {
  const { filters, updateFilter } = useTableFilters();

  const debounced = useMemo(
    () =>
      debounce(
        (search) =>
          updateFilter([
            {
              key: 'search',
              value: search,
            },
          ]),
        300,
      ),
    [updateFilter],
  );

  const handleSearch = (query: string) => {
    debounced(query);
  };

  return (
    <Input
      placeholder={placeholder || 'Search'}
      defaultValue={filters.search}
      leftSection={<Icon name="searchLg" />}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );
};
