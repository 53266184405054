import { create } from 'zustand';

interface SidebarState {
  isMinimized: boolean;
  isAnimating: boolean;
  mode: 'click' | 'hover';
  actions: {
    toggleSidebar: (payload?: {
      mode: 'click' | 'hover';
      isMinimized: boolean;
      isAnimating: boolean | undefined;
    }) => void;
    setIsAnimating: (isAnimating: boolean) => void;
  };
}

const useSidebarStore = create<SidebarState>((set) => ({
  isMinimized: false,
  isAnimating: false,
  mode: 'click',

  actions: {
    toggleSidebar: (payload) =>
      set((state) => ({
        isMinimized: payload && 'isMinimized' in payload ? payload.isMinimized : !state.isMinimized,
        isAnimating: payload && 'isAnimating' in payload ? payload.isAnimating : true,
        mode: payload?.mode || state.mode,
      })),
    setIsAnimating: (isAnimating: boolean) => set({ isAnimating }),
  },
}));

export const useSidebarMinimized = () => useSidebarStore((state) => state.isMinimized);
export const useSidebarMode = () => useSidebarStore((state) => state.mode);
export const useSidebarAnimating = () => useSidebarStore((state) => state.isAnimating);
export const useSidebarActions = () => useSidebarStore((state) => state.actions);
