import { PropsWithChildren, FC } from 'react';
import { ICONS } from '@styles/icons';
import { Icon, IconStrokeColors } from '@ui/Icon/Icon';
import clsx from 'clsx';
import { useAccordionButton } from 'react-bootstrap';

import styles from './Accordion.module.scss';

type Props = {
  eventKey: string;
  isActive: boolean;
  openIcon?: keyof typeof ICONS;
  closedIcon?: keyof typeof ICONS;
  stroke?: IconStrokeColors;
  leftAligned?: boolean;
};

export const CustomToggle: FC<PropsWithChildren<Props>> = ({
  eventKey,
  isActive,
  openIcon = 'minusCircle',
  closedIcon = 'plusCircle',
  children,
  stroke,
  leftAligned,
}) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  return (
    <div
      onClick={decoratedOnClick}
      className={clsx(styles.toggle, {
        [styles.leftAligned]: leftAligned,
      })}
    >
      {children}
      {isActive ? (
        <Icon name={openIcon} stroke={stroke} />
      ) : (
        <Icon name={closedIcon} stroke={stroke} />
      )}
    </div>
  );
};
