import { formatNumberToLocale } from '@utils/formatNumber';

import { Feature } from '../components/Feature';

export enum FREQUENCY {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export enum PRICING_TYPE {
  PER_CONTACT = 'per contact',
  PER_SENDING = 'per sending',
}

export enum EDGE_VALUES {
  MAX_SENDING = 21_000_000,
  MAX_CONTACTS = 1_050_000,
  MAX_PRICE = 999_999,
}

export const divideArrayIntoThree = (array: Feature[]): [Feature[], Feature[], Feature[]] => {
  const partSize = Math.ceil(array.length / 3);
  const part1 = array.slice(0, partSize);
  const part2 = array.slice(partSize, partSize * 2);
  const part3 = array.slice(partSize * 2);

  return [part1, part2, part3];
};

export const formatPriceValue = (value: number, type: PRICING_TYPE) => {
  if (type === PRICING_TYPE.PER_CONTACT) {
    return value === EDGE_VALUES.MAX_CONTACTS ? '1,000,000+' : formatNumberToLocale(value);
  } else {
    return value === EDGE_VALUES.MAX_SENDING ? '20,000,000+' : formatNumberToLocale(value);
  }
};

export const ANNUAL_DISCOUNT = 10;

export const applyDiscount = (price: number) => {
  const total = Math.trunc(price - (price * ANNUAL_DISCOUNT) / 100);
  return isNaN(total) ? 0 : total;
};
