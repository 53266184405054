import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

export const FormSection: FC<
  PropsWithChildren<{
    title: string;
    description?: string;
    className?: string;
    descrClassName?: string;
    link?: string;
  }>
> = ({ title, description, link, className, descrClassName, children }) => {
  return (
    <div className={className}>
      <h2 className="fs-3 text-gray-900 fw-bold">{title}</h2>
      {description && (
        <p className={clsx('text-gray-600', descrClassName)}>
          {description}{' '}
          {link && (
            <a href={link} target="blank" rel="noopener noreferrer">
              Read More
            </a>
          )}
        </p>
      )}
      {children}
    </div>
  );
};
