import { FC, forwardRef, PropsWithChildren, Ref, useMemo, useRef, useState } from 'react';
import { useSidebarLists } from '@module/list/hooks/list-queries';
import { useAllListsSelected, useSelectedList } from '@module/list/store/useListStore';
import { Icon } from '@ui/Icon/Icon';
import { Input } from '@ui/Input';
import { Tooltip } from '@ui/Tooltip';
import { useIsHovered } from '@utils/hooks';
import { useTransformCurrentPathUsingList } from '@utils/routes';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import styles from './ListOfLists.module.scss';

import type { List } from '@module/list/store/models';

export const ListOfLists = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const navigate = useNavigate();

  const selectedList = useSelectedList();
  const allListsSelected = useAllListsSelected();
  const { data: lists } = useSidebarLists();
  const [listsFilter, setListsFilter] = useState('');

  const filteredLists = useMemo<List[]>(() => {
    if (!lists) {
      return [];
    }

    if (!listsFilter) {
      return lists;
    }

    return lists.filter(
      (list) =>
        list.name.toLowerCase().includes(listsFilter) || list.id.toString().includes(listsFilter),
    );
  }, [lists, listsFilter]);

  const transformCurrentPathUsingList = useTransformCurrentPathUsingList();

  return (
    <div ref={ref} className={clsx(styles.listOfLists, 'py-5')}>
      <div className="flex-shrink-0 d-flex flex-column px-5">
        <Input
          placeholder="Search"
          value={listsFilter}
          leftSection={<Icon name="searchLg" />}
          onChange={(e) => setListsFilter(e.target.value.toLowerCase())}
        />
      </div>

      <ListItem
        className="mt-5 mx-3 px-3 flex-grow-1"
        onSelect={() => navigate(transformCurrentPathUsingList('all'))}
        active={allListsSelected}
      >
        All lists
      </ListItem>

      <ul className="d-flex flex-column flex-grow-1 gap-1 overflow-y-auto overflow-x-hidden mt-2 ps-3 pe-1">
        {filteredLists?.map((list) => (
          <li className="d-flex" key={list.id}>
            <ListItem
              listId={list.id}
              listName={list.name}
              className="flex-grow-1 text-truncate"
              onSelect={() => navigate(transformCurrentPathUsingList(list.id))}
              active={!allListsSelected && list.id === selectedList?.id}
            >
              {list.name}
            </ListItem>
          </li>
        ))}
      </ul>
    </div>
  );
});

const ListItem: FC<
  PropsWithChildren<{
    listId?: number;
    listName?: string;
    onSelect: () => void;
    active?: boolean;
    className?: string;
    disabled?: boolean;
  }>
> = ({ children, onSelect, className, active, disabled, listId, listName }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const isHovered = useIsHovered(ref);

  return (
    <button
      ref={ref}
      onClick={() => onSelect()}
      className={clsx(
        styles.listItem,
        className,
        { 'bg-white': active },
        'btn text-start px-4 py-3 fs-6 fw-bold',
      )}
      disabled={disabled}
    >
      <Tooltip
        colorTheme="dark"
        placement="top-start"
        title={listName}
        show={Boolean(isHovered && listName && listName?.length > 26)}
        arrowHidden
      >
        <span>{children}</span>
      </Tooltip>
      <br />
      <span className="fw-lighter">{listId}</span>
    </button>
  );
};
