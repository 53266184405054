import { useRef } from 'react';
import { Col, Dropdown } from 'react-bootstrap';

import { RadioButton } from '../inputs/RadioButton';

type RadioOption = {
  label: string;
  value: string;
};
type DropDownItemAsRadioProps<T extends RadioOption> = {
  option: T;
  selected: string | undefined;
  onChange: (option: T['value']) => void;
};
export const DropDownItemAsRadioGroup = <T extends RadioOption>({
  selected,
  onChange,
  option,
}: DropDownItemAsRadioProps<T>) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <Dropdown.Item onClick={() => ref?.current?.click()}>
      <Col className="d-flex flex-row align-items-center justify-content-start gap-2 px-2 py-2">
        <RadioButton
          ref={ref}
          title={option.label}
          value={option.value}
          groupSelectedValue={selected}
          onChange={onChange}
        />
      </Col>
    </Dropdown.Item>
  );
};
