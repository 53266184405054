import { ContactStatus, ListSystemCriterion } from '@module/shared/models/models';
import { Encoding } from '@utils/Encoding';

export interface List {
  id: number;
  account_id: number;
  name: string;
  description: string;
  type: 'sending' | 'suppression';
  last_count: number;
  last_active_count: number;
  unsubscribes: number;
  complaints: number;
  last_stats_date: number | null;
  bounces: number;
  unsubscribe_success_html: string | null;
  unsubscribe_confirmation_html: string | null;
  unsubscribe_confirmation_button: string;
  frequencies: string | number | null;
  frequency_active: boolean;
  frequency_html: string | null;
  frequency_button: string;
  external_url_wrapper: string;
  expiration_date: number | string;
  scope: string | null;
  welcome_email_id: number | null;
  welcome_email_active: boolean;
  welcome_sms_active: boolean;
  public_key: string | null;
  image_host_base: string;
  tracking_domain: string;
  welcome_email_esp_connection_id: number | null;
  sms_field_id: number | null;
  frequency_field_id: number | null;
  modified_counts: number;
  archive: boolean;
  segment_welcome_emails: number | null;
  included_segment_cache: number | null;
  excluded_segment_cache: number | null;
  default: boolean;
  created: number;
  modified: number;
  deleted: number | null;
  allowed_provider_connection_ids: number[] | null;
  hash_type: string;
  create_segment?: boolean;
  list_name?: string;
  preprocess_minutes?: string;
  suppression_import?: string;
  suppress_in_lists?: string;
}

export interface ListDomainVolume {
  active: string;
  bounces: string;
  complaints: string;
  isp_name: string;
  not_active: string;
  total: string;
  unsubscribes: string;
}

export interface TimeRangeFilter {
  start: string | Date | null;
  end: string | Date | null;
}
export enum ActiveContactsStatus {
  ACTIVE = 'active',
  BOUNCES = 'bounces',
  COMPLAINTS = 'complaints',
  UNSUBSCRIBES = 'unsubscribes',
  ALL = 'all',
  NOT_ACTIVE = 'not_active',
}
export const HumanActiveContactsStatus = {
  [ActiveContactsStatus.ACTIVE]: 'Active',
  [ActiveContactsStatus.BOUNCES]: 'Hard bounced',
  [ActiveContactsStatus.COMPLAINTS]: 'Complaints',
  [ActiveContactsStatus.UNSUBSCRIBES]: 'Unsubscribed',
  [ActiveContactsStatus.ALL]: 'All',
  [ActiveContactsStatus.NOT_ACTIVE]: 'Not active',
};

export enum ActiveContactsGrowthGroupBy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
export const HumanActiveContactsGrowthGroupBy = {
  [ActiveContactsGrowthGroupBy.DAY]: 'Day',
  [ActiveContactsGrowthGroupBy.WEEK]: 'Week',
  [ActiveContactsGrowthGroupBy.MONTH]: 'Month',
  [ActiveContactsGrowthGroupBy.YEAR]: 'Year',
};

export type ActiveContactsGrowth = {
  [k in ActiveContactsGrowthGroupBy]?: string;
} & {
  total: string;
};

export interface ActiveContactsGrowthParamsState {
  groupBy: ActiveContactsGrowthGroupBy;
  activeContactsStatus: ActiveContactsStatus;
  timeRange: TimeRangeFilter;
  setGroupBy: (groupBy: ActiveContactsGrowthGroupBy) => void;
  setActiveContactsStatus: (show: ActiveContactsStatus) => void;
  setTimeRange: (timeRange: TimeRangeFilter) => void;
}

export interface ImportFileState {
  file: File | null;
  encoding: Encoding;
  emailWhenDone: boolean;
  sendWelcome: boolean;
  updateExistingContacts: boolean;
  previewImportFile: boolean;
  defaultValuesSet: boolean;

  setFile: (file: File | undefined | null) => void;
  setEncoding: (encoding: Encoding) => void;
  setEmailWhenDone: (emailWhenDone: boolean) => void;
  setSendWelcome: (sendWelcome: boolean) => void;
  setUpdateExistingContacts: (updateExistingContacts: boolean) => void;
  setPreviewImportFile: (previewImportFile: boolean) => void;
  setDefaultValues: (defaultValues: boolean) => void;
  flushForm: () => void;
}

export interface ImportedFilesArchive {
  id: number;
  action: string;
  name: string;
  file: string;
  file_url: null;
  imported_by: number;
  is_override: boolean;
  send_welcome_message: boolean;
  send_email_notification: boolean;
  csv_delimiter: string;
  encoding: Encoding;
  ignore_empty: boolean;
  overwrite_only_nulls: boolean;
  progress: number;
  file_size_bytes: number;
  total: number;
  success: number;
  failed: number;
  duplicate: number;
  existing: number;
  not_existing: number;
  incomplete: number;
  invalid: number;
  status: IMPORTED_ITEM_STATUS;
  status_info: string;
  type: string;
  parent_id: number;
  remaining: null;
  import_process_start_time: number;
  import_process_validation_end_time: number;
  import_process_end_time: number;
  account_id: number;
  list_id: number;
  created: number;
  modified: number;
  deleted: null;
  importer_username: string;
  is_report_expired: boolean;
  can_be_aborted: boolean;
  status_desc: string;
}

export interface ExportedFilesArchive {
  id: number;
  segment_id: number;
  account_id: number;
  list_id: number;
  name: string;
  filename: string;
  status: EXPORTED_ITEM_STATUS;
  status_desc: string;
  status_info: string;
  exported_by: number;
  size: number;
  hash_types: null;
  expiry_date: number;
  s3_id: string;
  created: number;
  modified: number;
  deleted: number;
  exporter: string;
  exporter_full_name: string;
  account_name: string;
  segment_name: string;
}

export enum CONTACTS_SEARCH_STATUS_ID {
  PROCESSING = 1,
  COMPLETED = 2,
}

export enum ContactsSearchStatus {
  PROCESSING = 'Processing',
  COMPLETED = 'Completed',
}

export type ContactsSearch = {
  id: number;
  s3_id: number | string | null; // Not sure about number | string
  detailed_s3_id: number | string | null; // Not sure about number | string
  title: string;
  status: CONTACTS_SEARCH_STATUS_ID;
  status_desc: ContactsSearchStatus;
  count: number | null;
  limit: number;
  expire_date: number | null;
  include_behavior: boolean;
  combined_as_and: boolean;
  filters: {
    type: string; // TODO Should be enum
    criteria: ListSystemCriterion[];
    user_type: ContactStatus;
  };
  selected_fields: string[];
  group_fields: string[] | null; // todo not sure about string[]
  favorite: boolean;
  account_id: number;
  list_id: number;
  created: number;
  modified: number;
  deleted: number | null;
};

export interface DeleteUnsubscribeFilesArchive {
  id: number;
  action: string;
  name: string;
  file: string;
  file_url: string | null;
  imported_by: number;
  is_override: boolean;
  send_welcome_message: boolean;
  send_email_notification: boolean;
  csv_delimiter: string;
  encoding: Encoding;
  ignore_empty: boolean;
  overwrite_only_nulls: boolean;
  progress: number;
  file_size_bytes: number;
  total: number;
  success: number;
  failed: number;
  duplicate: number;
  existing: number;
  not_existing: number;
  incomplete: number;
  invalid: number;
  status: IMPORTED_ITEM_STATUS;
  status_info: string;
  type: string;
  parent_id: number;
  remaining: number | null;
  import_process_start_time: number;
  import_process_validation_end_time: number;
  import_process_end_time: number;
  account_id: number;
  list_id: number;
  created: number;
  modified: number;
  deleted: number | null;
  importer_username: string;
  can_be_aborted: boolean;
  status_desc: string;
}

export enum IMPORTED_ITEM_STATUS {
  QUEUED = 40001,
  PROCESSING = 40002,
  COMPLETED = 40003,
  UPDATING_LIST_STATS = 40004,
  ABORTING = 40005,
  ABORTED = 40006,
  REVERTING = 40007,
  REVERTED = 40008,
  VALIDATING = 40010,
  ERROR = 90001,
  INVALID = 90008,
}

export enum EXPORTED_ITEM_STATUS {
  START = 50001,
  PROCESSING = 50002,
  COMPLETED = 50003,
  EXPIRED = 50004,
}

export type StatusCode = IMPORTED_ITEM_STATUS | EXPORTED_ITEM_STATUS | CONTACTS_SEARCH_STATUS_ID;

// Mapping from the status code to a human-readable description
export const STATUS_DESCRIPTIONS: Record<StatusCode, string> = {
  [IMPORTED_ITEM_STATUS.QUEUED]: 'Queued',
  [IMPORTED_ITEM_STATUS.PROCESSING]: 'Processing',
  [IMPORTED_ITEM_STATUS.COMPLETED]: 'Completed',
  [IMPORTED_ITEM_STATUS.UPDATING_LIST_STATS]: 'Updating List Stats',
  [IMPORTED_ITEM_STATUS.ABORTING]: 'Aborting',
  [IMPORTED_ITEM_STATUS.ABORTED]: 'Aborted',
  [IMPORTED_ITEM_STATUS.REVERTING]: 'Reverting',
  [IMPORTED_ITEM_STATUS.REVERTED]: 'Reverted',
  [IMPORTED_ITEM_STATUS.VALIDATING]: 'Validating',
  [IMPORTED_ITEM_STATUS.ERROR]: 'Error',
  [IMPORTED_ITEM_STATUS.INVALID]: 'Invalid',
  [EXPORTED_ITEM_STATUS.START]: 'Start',
  [EXPORTED_ITEM_STATUS.PROCESSING]: 'Processing',
  [EXPORTED_ITEM_STATUS.COMPLETED]: 'Completed',
  [EXPORTED_ITEM_STATUS.EXPIRED]: 'Expired',
  [CONTACTS_SEARCH_STATUS_ID.PROCESSING]: 'Processing', // Contacts search does not have progress property
  [CONTACTS_SEARCH_STATUS_ID.COMPLETED]: 'Completed',
};

export const STATUSES_WITH_PROGRESS = new Set<StatusCode>([
  IMPORTED_ITEM_STATUS.QUEUED,
  IMPORTED_ITEM_STATUS.PROCESSING,
  IMPORTED_ITEM_STATUS.VALIDATING,
  IMPORTED_ITEM_STATUS.REVERTING,
  EXPORTED_ITEM_STATUS.PROCESSING,
]);

export interface AccountForms {
  id: number;
  list_id: number;
  account_id: number;
  created_by: number;
  name: string;
  token: string;
  title: string;
  submit_txt: string;
  auto_update: boolean;
  color_border: number;
  color_bg: string;
  color_font: string;
  color_btn: string;
  custom_style: string;
  onsubmit_action: number;
  onsubmit_msg: string;
  onsubmit_url: string;
  capture_ip_field: string;
  responsive_design: boolean;
  overwrite: boolean;
  custom_checkbox_label_html: string;
  custom_checkbox_is_mandatory: number;
  created: number;
  modified: number;
  deleted: number;
  creator: string;
  fields_count: number;
}

export interface ConversionPoint {
  id: number;
  list_field_id: number | string | null; // do not know actual type number|string
  conversion_number: number;
  title: string;
  description: string | null;
  action: string | null;
  save_sum: boolean;
  encrypt: boolean;
  uri: string;
  account_id: number;
  list_id: number;
  created: number;
  modified: number;
  deleted: number | null;
  list_field_title: string | null;
}

export interface PostBackLink {
  id: number;
  list_id: number;
  account_id: number;
  field_id: number;
  title: string;
  description: string;
  value: number | string;
  operation: number;
  redirect_url: string;
  confirm_text: string;
  status: string;
  path: string;
  created: number;
  modified: number;
  deleted: number;
  field_name: string;
}

export interface LinkGroup {
  id: number;
  name: string;
  list_id: number | null | 'all';
  account_id: number;
  created: number;
  modified: number;
  deleted: number;
}

export interface LinkedCampaignsAndEvents {
  id: string;
  name: string;
  type: string;
  schedule_date: string | number;
  status: string;
  status_desc: string;
  exclude: string;
}

export enum DomainSetupHttpStatusCode {
  HTTPS_ON = 1,
  HTTPS_OFF = 2,
  HTTPS_INVALID = 3,
  HTTPS_IN_PROCESS = 4,
}
export interface DomainSetup {
  id: number;
  type: 'image' | 'tracking';
  domain: string;
  verified: boolean;
  certificate_id: number;
  https_status_code: DomainSetupHttpStatusCode;
  bypass_verification: boolean;
  is_https: boolean;
  first_https_activation_date: number | null;
  last_https_activation_date: number | null;
  account_id: number;
  created: number;
  modified: number;
  deleted: number | null;
  is_default: boolean;
  dns_instructions: string;
  expiration_date: number;
  https_status_display_name: string;
}

export interface Certificate {
  id: number;
  account_id: number;
  aws_id: number;
  load_balancer_id: number;
  hash: string;
  expiration_date: number;
  dns_destination: string;
  created_by: number;
  modified_by: number;
  created: number;
  modified: number;
  deleted: number | null;
}

export interface User {
  id: number;
  account_id: number;
  default_esp_connection_id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  mobile: string;
  timezone: string;
  favorites: string | null;
  confirm: boolean;
  sf_lead_id: string;
  sf_contact_id: string;
  logins: number;
  last_login: number;
  last_login_ip_address: string | null;
  auth_2fa_is_pending: boolean;
  auth_2fa_secret: string;
  auth_2fa_timestamp: number;
  auth_2fa_hide_qr_code: boolean;
  auth_user_password: string;
  password: string;
  confirm_password: string;
  verified_by_email: boolean;
  is_admin: boolean;
  is_admin_manager: boolean;
  is_super_admin: boolean;
  is_creator: boolean;
  allow_frontend_login: boolean;
  allow_api_external: boolean;
  allow_future_lists: boolean;
  created: number;
  modified: number;
  blocked: number;
  deleted: boolean | null;
  is_auth_synced: number;
  role_title: string;
  re_password: string;
  reset_2fa: boolean;
  reset_2fa_with_code: number;
  user_allowed_sending_list_ids: number[];
}

export interface UserWithAllowedListIds extends User {
  allowed_list_ids?: {
    all_ids: number[];
    sending_ids: number[];
    all_lists_are_allowed: boolean;
  };
}

export interface Timezone {
  // to shared models
  id: number;
  name: string;
  title: string;
  gmt: number;
  gmt_display: string;
}

export interface TimezoneOption {
  label: string;
  value?: Timezone;
}

export interface TimezoneData {
  id: number;
  name: string;
  title: string;
  gmt: number;
  gmt_display: string;
}

export enum Roles {
  GENERAL_USER = '0', // but '' is used in fapi for setting general user
  ADMIN_MANAGER = 'admin_manager',
  ADMIN = 'admin',
  CONTENT_WRITER = '4',
  EMAIL_MARKETER_NO_IMPORT_EXPORT = '5',
  REPORT_VIEWER = '6',
  GENERAL_USER_NO_EXPORT = '7',
  EMAIL_MARKETER_NO_LIST_SETTINGS = '8',
}

export enum HashTypes {
  REGULAR = 'regular',
  MD5_HASH = 'md5',
  SHA_256 = 'sha256',
  SHA_512 = 'sha512',
}

export enum SupressionListScope {
  GLOBAL = 'global',
  LIST = 'list',
  CAMPAIGN = 'campaign',
}

export enum ListTypes {
  SENDING = 'sending',
  SUPPRESSION = 'suppression',
}

export interface SupListExportEntry {
  id: number;
  segment_id: number;
  account_id: number;
  list_id: number;
  name: string;
  filename: string;
  status: number;
  status_desc: string;
  status_info: string;
  exported_by: number;
  size: number;
  hash_types: string;
  expiry_date: number;
  s3_id: string;
  created: number;
  modified: number;
  deleted: number;
  exporter: string;
  exporter_full_name: string;
  account_name: string;
  segment_name: string;
}

export interface SupListImportEntry {
  id: string;
  action: string;
  name: string;
  file: string;
  file_url: string | null;
  imported_by: string;
  is_override: string;
  send_welcome_message: string;
  send_email_notification: string;
  csv_delimiter: string;
  encoding: string;
  ignore_empty: string;
  overwrite_only_nulls: string;
  fields: {
    email: {
      name: string;
      type: string;
    };
  };
  progress: number;
  file_size_bytes: string;
  total: string;
  success: string;
  failed: string;
  duplicate: string;
  existing: string;
  not_existing: string;
  incomplete: string;
  invalid: string;
  status: number;
  status_info: string;
  type: string;
  parent_id: string;
  remaining: string;
  import_process_start_time: string;
  import_process_validation_end_time: string;
  import_process_end_time: string;
  account_id: string;
  list_id: string;
  created: string;
  modified: string;
  deleted: string;
  can_be_aborted: string;
  status_desc: string;
  importer_username: string;
  report_expired: string;
}
