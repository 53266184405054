import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

import { Sidebar } from './Sidebar';
import { useSidebarMinimized } from './sidebar-store';
import styles from './SidebarLayout.module.scss';

export const SidebarLayout: FC<PropsWithChildren<JSX.IntrinsicElements['div']>> = ({
  className,
  children,
  ...restProps
}) => {
  const isSidebarMinimized = useSidebarMinimized();

  return (
    <div
      {...restProps}
      className={clsx(className, styles.sidebarLayout, {
        [styles.sidebarMinimized]: isSidebarMinimized,
      })}
    >
      <Sidebar />
      <div className={clsx(styles.mainContent, 'flex-column flex-row-fluid')}>{children}</div>
    </div>
  );
};
