import { useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { groupBy as group, throttle, toUpper, uniqBy } from 'lodash';

import { FapiResponse } from '../models/FapiResponse';
import { SearchParams } from '../models/models';

export interface Option<K> {
  value?: K;
  options?: Option<K>[];
  label: string;
}
export const useSelectData = <K extends { id: string | number; name?: string }>({
  fetchCallback,
  ids,
  labelFormatter,
  extraOptions = [],
  groupBy,
}: {
  fetchCallback: (sp: SearchParams) => UseQueryResult<FapiResponse<K[]>>;
  ids?: Array<number | string>;
  labelFormatter?: (params: K) => string;
  extraOptions?: Option<K>[];
  groupBy?: keyof K;
}) => {
  const [search, setSearch] = useState('');
  const throttled = useMemo(() => throttle(setSearch, 500), []);

  // we will fetch selected items only once on mount and then add selected and newly fetched campaigns to options
  const selectedIds = useRef(ids);

  const [options, setOptions] = useState<Option<K>[]>(extraOptions);

  const { data: searchData, isLoading: isSearchLoading } = fetchCallback({ search });
  const searchItems = useMemo(() => searchData?.payload || [], [searchData]);

  const { data: selectedItemsData, isLoading: isIdsLoading } = fetchCallback({
    ids: selectedIds.current,
  });
  const selectedItems = useMemo(() => selectedItemsData?.payload || [], [selectedItemsData]);

  useEffect(() => {
    if (isIdsLoading || isSearchLoading) {
      return;
    }

    setOptions((previous) => {
      const selectedPreviousItems: K[] = previous
        .filter((o) => ids?.map(String).includes(o.value?.id.toString() || ''))
        .filter((o) => o.value !== undefined)
        .map((o) => o.value) as K[];

      const aggregatedItems = uniqBy(
        [...selectedPreviousItems, ...selectedItems, ...searchItems],
        (e) => e.id,
      );

      const formattedOptions = aggregatedItems.map((e) => ({
        value: e,
        label: labelFormatter ? labelFormatter(e) : `[${e.id}] ${e.name}`,
      }));

      if (groupBy && aggregatedItems[0] && groupBy in aggregatedItems[0]) {
        const groupedItems = group(aggregatedItems, groupBy);

        return Object.keys(groupedItems).map((groupByField) => {
          return {
            label: toUpper(groupByField),
            options: groupedItems[groupByField].map((f) => {
              return {
                value: f,
                label: labelFormatter ? labelFormatter(f) : `[${f.id}] ${f.name}`,
              };
            }),
          };
        });
      }

      return formattedOptions;
    });
  }, [searchItems, selectedItems, isIdsLoading, isSearchLoading, groupBy, labelFormatter, ids]);

  return {
    options,
    value: options
      .flatMap((o) => (o.options?.length ? o.options : o))
      .filter((o) => ids?.map(String).includes(o.value?.id.toString() || '')),
    isLoading: isIdsLoading || isSearchLoading,
    setSearch: throttled,
  };
};
