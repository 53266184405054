import { FC, ReactElement, ReactNode } from 'react';

interface Props {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactNode;
  elseWrapper?: (children: ReactElement) => ReactNode;
  children: ReactElement;
}

export const ConditionalWrapper: FC<Props> = ({ condition, wrapper, elseWrapper, children }) => {
  return <>{condition ? wrapper(children) : elseWrapper ? elseWrapper(children) : children}</>;
};
