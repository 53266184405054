import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const ForgotPasswordLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  const getOpacity = (index: number, totalLength: number) => {
    const maxOpacity = 0.35;
    return maxOpacity - Math.abs(maxOpacity / 2 - index * (maxOpacity / (totalLength - 1)));
  };

  const lineVertical = () => (
    <div className="position-absolute top-0 d-flex justify-content-center h-100 w-100 gap-12 mx-5">
      {Array(15)
        .fill(null)
        .map((_, index) => (
          <div
            key={index + 'v'}
            className="line-vertical"
            style={{
              opacity: getOpacity(index, 15),
            }}
          ></div>
        ))}
    </div>
  );

  const lineHorizontal = () => (
    <div className="position-absolute top-0 d-flex flex-column justify-content-center h-100 w-100 gap-12 my-5">
      {Array(10)
        .fill(null)
        .map((_, index) => (
          <div
            key={index + 'h'}
            className="line-horizontal"
            style={{
              opacity: getOpacity(index, 10),
            }}
          ></div>
        ))}
    </div>
  );

  return (
    <>
      <ToastContainer
        transition={Slide}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        position="top-right"
        containerId="top"
        closeOnClick
        hideProgressBar
      />
      <div className="position-relative">
        <div className="d-flex flex-column p-10">
          <div className="justify-content-start">
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className="h-30px app-sidebar-logo-default theme-light-show"
            />
          </div>
        </div>
        <div className="d-flex flex-column-fluid h-100 justify-content-center">
          {/* begin::Body */}
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10">
            {/* begin::Form */}
            <div className="d-flex align-items-center flex-column flex-lg-row-fluid">
              {/* begin::Wrapper */}
              <div className="position-absolute top-0 h-75 w-50 mx-auto">
                {lineVertical()}
                {lineHorizontal()}
              </div>
              <div className="w-lg-500px p-10" style={{ zIndex: 1 }}>
                <Outlet />
              </div>
              {/* end::Wrapper */}
            </div>
            {/* end::Form */}
          </div>
          {/* end::Body */}
        </div>
      </div>
    </>
  );
};

export { ForgotPasswordLayout };
