import { FC, useState } from 'react';
import Yup from '@module/shared/validation/yupConfig';
import { Button } from '@ui/Button';
import { FormikTextField } from '@ui/formik/TextField';
import { FormSection } from '@ui/FormSection';
import { Icon } from '@ui/Icon/Icon';
import { toastError, toastSuccess, toastWarn } from '@ui/Toasts';
import { ROUTES } from '@utils/routes';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';

import { BackToLogin } from './BackToLogin';
import styles from './ForgotPassword.module.scss';
import { useRequestPassword } from '../queries';

export type ForgotPwdFormFields = {
  email: string;
  account_code?: string;
};

export const ForgotPassword: FC = () => {
  const { mutate: requestPassword, isPending } = useRequestPassword();
  const navigate = useNavigate();
  const [accountCode, setAccountCode] = useState<boolean>(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    account_code: Yup.string().when([], {
      is: () => accountCode,
      then: () =>
        Yup.string()
          .min(3, 'Minimum 3 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Account is required'),
      otherwise: () => Yup.string().optional(),
    }),
  });

  return (
    <Formik<ForgotPwdFormFields>
      initialValues={{
        email: '',
        account_code: '',
      }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values, { resetForm }) => {
        requestPassword(
          { ...values },
          {
            onSuccess: () => {
              toastSuccess({
                title: 'Request password',
                text: `We've sent you an email with a link to finish resetting your password. Can't find the email? Try checking your spam folder. If you still can't log in, have us resend the email or contact Ongage Support.`,
              });
              resetForm();
              navigate(
                generatePath(ROUTES.AUTH.FORGOT_PASSWORD_SENT_LINK, { email: values.email }),
              );
            },
            onError: (err) => {
              if (err instanceof AxiosError) {
                // 412 status code means account code is required from user
                if (err.response && err.response.status === 412) {
                  toastWarn({
                    title: 'Request password',
                    text: 'Account code required for this email address.',
                  });
                  setAccountCode(true);
                } else {
                  toastError({
                    title: 'Request password',
                    text: err.response?.data?.payload?.message,
                  });
                }
              } else {
                toastError({
                  title: 'Request password',
                  text: 'Something went wrong. Please refresh the page and try again.',
                });
              }
            },
          },
        );
      }}
    >
      {(formik) => {
        return (
          <>
            <div className="d-flex justify-content-center">
              <div className={styles.iconContainer}>
                <Icon name="key" width={25} height={25} />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} id="reset_pwd_form">
              <FormSection
                className={styles.resetPwd}
                title="Forgot password?"
                description={
                  accountCode
                    ? 'Fill in your email to identify your account. An email will be sent to you, containing a link to start a password reset process.'
                    : 'No worries, we’ll send you reset instructions.'
                }
                descrClassName="fs-4 text-center"
              >
                <div>
                  <FormikTextField name="email" label="Email" placeholder="Enter your email" />
                </div>
                <div className="mt-4">
                  {accountCode && (
                    <FormikTextField
                      name="account_code"
                      label="Account Code"
                      placeholder="Enter your Account Code"
                    />
                  )}
                </div>
              </FormSection>
              <div className="d-flex flex-column justify-content-center pt-8">
                <Button
                  type="submit"
                  variant="info"
                  form="reset_pwd_form"
                  className="w-100"
                  size="lg"
                  isLoading={isPending}
                >
                  Send Email
                </Button>
                <BackToLogin />
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};
