import { FC } from 'react';
import { Col, Dropdown } from 'react-bootstrap';

import { Checkbox } from '../Checkbox';

type DropDownItemAsOptionProps = {
  label: string;
  checked: boolean;
  onToggle: () => void;
};
export const DropDownItemAsOption: FC<DropDownItemAsOptionProps> = ({
  label,
  checked,
  onToggle,
}) => {
  return (
    <Dropdown.Item onClick={onToggle}>
      <Col className="d-flex flex-row align-items-center justify-content-start gap-2 px-2 py-2">
        <Checkbox className="align-self-center" checked={checked} />
        <span className="text-gray-700 fs-6 fw-bold text-truncate">{label}</span>
      </Col>
    </Dropdown.Item>
  );
};
