import { useMemo } from 'react';
import { Option, useSelectData } from '@module/shared/hooks/useSelectData';
import { debounce } from 'lodash';
import { Col, Dropdown, Spinner } from 'react-bootstrap';

import { DropDownItemAsOption } from './DropdownItemAsOption';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input';

type DropDownItemAsSelectProps<T extends { id: string | number; name?: string }> = {
  onSearchChange: (value: string) => void;
  value: ReturnType<typeof useSelectData<T>>['value'];
  onOptionToggle: (option: Option<T>) => void;
  isLoading?: boolean;
  options: ReturnType<typeof useSelectData<T>>['options'];
};
export const DropDownItemAsSelect = <T extends { id: string | number; name?: string }>({
  onSearchChange,
  value,
  onOptionToggle,
  isLoading,
  options,
}: DropDownItemAsSelectProps<T>) => {
  const debounced = useMemo(() => debounce(onSearchChange, 300), [onSearchChange]);

  const handleSearch = (query: string) => {
    debounced(query);
  };

  return (
    <>
      <Dropdown.Item className="p-2">
        <Input
          wrapperClassName="w-100"
          placeholder="Search"
          leftSection={<Icon name="searchLg" />}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Dropdown.Item>
      {isLoading && (
        <Dropdown.Item>
          <Col className="text-center">
            <Spinner />
          </Col>
        </Dropdown.Item>
      )}
      <div style={{ maxHeight: 500, overflow: 'auto' }}>
        {!isLoading &&
          options.map((option) => (
            <DropDownItemAsOption
              key={option.value?.id || option.value?.name}
              label={option.label}
              checked={!!value.find((s) => s.value?.id === option.value?.id)}
              onToggle={() => onOptionToggle(option)}
            />
          ))}
      </div>
    </>
  );
};
