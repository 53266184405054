import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';

import { AlertCircleIcon, AlertTriangleIcon, CheckCircleIcon, XIcon } from '../../styles/icons';

const AUTO_CLOSE = 10_000; // 10 seconds

type ProgressProps = {
  size?: number;
  progress?: number;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: 'butt' | 'round' | 'square' | 'inherit' | undefined;
  label?: string | React.ReactNode;
  labelColor?: string;
  spinnerMode?: boolean;
  spinnerSpeed?: number;
};
const Progress: React.FC<ProgressProps> = (props) => {
  const {
    size = 150,
    progress = 0,
    trackWidth = 10,
    trackColor = '#ddd',
    indicatorWidth = 10,
    indicatorColor = '#07c',
    indicatorCap = 'round',
    label = 'Loading...',
    labelColor = '#333',
    spinnerMode = false,
    spinnerSpeed = 1,
  } = props;

  const center = size / 2,
    radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <>
      <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
        <svg className="svg-pi" style={{ width: size, height: size }}>
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className={`svg-pi-indicator ${spinnerMode ? 'svg-pi-indicator--spinner' : ''}`}
            style={{ animationDuration: (spinnerSpeed * 1000).toString() }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        {!spinnerMode && (
          <div className="svg-pi-label text-center" style={{ color: labelColor }}>
            <span className="svg-pi-label__loading">{label}</span>
          </div>
        )}
      </div>
    </>
  );
};

const CloseButton: React.FC<{
  trackColor: string;
  indicatorColor: string;
  loadingDuration: number;
}> = ({ trackColor, indicatorColor, loadingDuration }) => {
  const [loading, setLoading] = React.useState(true);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      if (progress >= 100) return;
      setProgress(progress + 1);
    }, loadingDuration / 100);

    if (progress === 100) {
      setLoading(false);
    }

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [progress, loading, loadingDuration]);

  return (
    <div className="mt-1">
      <Progress
        progress={progress}
        trackWidth={1}
        indicatorWidth={1.5}
        size={24}
        label={<XIcon width={20} height={20} stroke={indicatorColor} />}
        trackColor={trackColor}
        indicatorColor={indicatorColor}
        labelColor={indicatorColor}
      />
    </div>
  );
};

type MessageProps = {
  title?: ReactNode;
  text?: ReactNode;
};
const Msg = ({ title, text }: MessageProps) => {
  return (
    <div className="msg-container d-flex flex-column gap-2">
      {!!title && <span className="msg-title fw-bold">{title}</span>}
      {!!text && <span className="msg-description">{text}</span>}
    </div>
  );
};

type ToastOptions = {
  title: ReactNode;
  text: ReactNode;
  autoClose?: number | false;
  withCloseButton?: boolean;
  containerId?: string;
};
export const toastSuccess = (options: ToastOptions) =>
  toast.success(<Msg text={options.text} title={options.title} />, {
    icon: (
      <CheckCircleIcon width={20} height={20} style={{ stroke: '#039855', alignSelf: 'start' }} />
    ),
    style: {
      borderRadius: '0.7rem',
      border: '1px solid var(--ongage-success-300)',
      background: 'var(--ongage-success-25)',
    },
    containerId: options?.containerId || 'top',
    autoClose:
      typeof options?.autoClose === 'number'
        ? options?.autoClose || AUTO_CLOSE
        : options?.autoClose === false
          ? false
          : AUTO_CLOSE,
    closeButton:
      options?.withCloseButton !== undefined ? (
        options?.withCloseButton && (
          <CloseButton
            loadingDuration={(options?.autoClose || AUTO_CLOSE) as number}
            indicatorColor="var(--ongage-success-600)"
            trackColor="var(--ongage-success-300)"
          />
        )
      ) : (
        <CloseButton
          loadingDuration={(options?.autoClose || AUTO_CLOSE) as number}
          indicatorColor="var(--ongage-success-600)"
          trackColor="var(--ongage-success-300)"
        />
      ),
    bodyStyle: {
      color: 'var(--ongage-success-600)',
      alignItems: 'center',
      margin: '0',
      fontSize: '1rem',
      fontWeight: '500',
    },
    ...options,
  });

export const toastWarn = (options: ToastOptions) =>
  toast.warn(<Msg text={options.text} title={options.title} />, {
    icon: (
      <AlertTriangleIcon
        width={20}
        height={20}
        style={{ color: 'var(--ongage-error-600)', alignSelf: 'start' }}
      />
    ),
    style: {
      borderRadius: '0.7rem',
      border: '1px solid var(--ongage-warning-300)',
      background: 'var(--ongage-warning-25)',
    },
    containerId: options?.containerId || 'top',
    autoClose:
      typeof options?.autoClose === 'number'
        ? options?.autoClose || AUTO_CLOSE
        : options?.autoClose === false
          ? false
          : AUTO_CLOSE,
    closeButton:
      options?.withCloseButton !== undefined ? (
        options?.withCloseButton && (
          <CloseButton
            loadingDuration={(options?.autoClose || AUTO_CLOSE) as number}
            indicatorColor="var(--ongage-warning-600)"
            trackColor="var(--ongage-warning-300)"
          />
        )
      ) : (
        <CloseButton
          loadingDuration={(options?.autoClose || AUTO_CLOSE) as number}
          indicatorColor="var(--ongage-warning-600)"
          trackColor="var(--ongage-warning-300)"
        />
      ),
    bodyStyle: {
      color: 'var(--ongage-warning-600)',
      alignItems: 'center',
      margin: '0',
      fontSize: '1rem',
      fontWeight: '500',
    },
    ...options,
  });

export const toastError = (options: ToastOptions) =>
  toast.error(<Msg text={options.text} title={options.title} />, {
    icon: (
      <AlertCircleIcon
        width={20}
        height={20}
        style={{ stroke: 'var(--ongage-error-600)', alignSelf: 'start' }}
      />
    ),
    style: {
      borderRadius: '0.7rem',
      border: '1px solid var(--ongage-error-300)',
      background: 'var(--ongage-error-25)',
      fontWeight: '500',
    },
    containerId: options?.containerId || 'top',
    autoClose:
      typeof options?.autoClose === 'number'
        ? options?.autoClose || AUTO_CLOSE
        : options?.autoClose === false
          ? false
          : AUTO_CLOSE,
    closeButton:
      options?.withCloseButton !== undefined ? (
        options?.withCloseButton && (
          <CloseButton
            loadingDuration={(options?.autoClose || AUTO_CLOSE) as number}
            indicatorColor="var(--ongage-error-600)"
            trackColor="var(--ongage-error-300)"
          />
        )
      ) : (
        <CloseButton
          loadingDuration={(options?.autoClose || AUTO_CLOSE) as number}
          indicatorColor="var(--ongage-error-600)"
          trackColor="var(--ongage-error-300)"
        />
      ),
    bodyStyle: {
      color: 'var(--ongage-error-600)',
      alignItems: 'center',
      margin: '0',
      fontSize: '1rem',
      fontWeight: '500',
    },
    ...options,
  });
