import { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';

const useDebouncedTooltip = (initialState = false, delay = 300) => {
  const [showTooltip, setShowTooltip] = useState(initialState);

  const debouncedShow = useMemo(
    () => debounce((showTooltip) => setShowTooltip(showTooltip), delay),
    [setShowTooltip, delay],
  );

  return [showTooltip, debouncedShow] as const;
};

export default useDebouncedTooltip;
