import { useCallback } from 'react';
import { useWrappedTo } from '@routing/to';
import { useQueryClient } from '@tanstack/react-query';
import { ROUTES } from '@utils/routes';
import { useNavigate } from 'react-router-dom';

import { useAuthTokensActions, usePreviousRefreshToken } from '../store/authTokensStore';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { logout, restorePreviousTokens } = useAuthTokensActions();
  const previousRefreshToken = usePreviousRefreshToken();
  const navigate = useNavigate();
  const to = useWrappedTo();

  return useCallback(() => {
    if (previousRefreshToken) {
      restorePreviousTokens();
      navigate(to(ROUTES.LIST.DASHBOARD));
    } else {
      logout();
      navigate(ROUTES.AUTH.LOGIN);
    }
    queryClient.clear();
  }, [logout, navigate, previousRefreshToken, queryClient, restorePreviousTokens, to]);
};
