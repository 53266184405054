import { FC, PropsWithChildren, ReactNode, useContext } from 'react';
import clsx from 'clsx';
import BSModal from 'react-bootstrap/Modal';
import BSModalContext from 'react-bootstrap/ModalContext';

import styles from './Modal.module.scss';
import { Button } from '../Button';
import { Icon } from '../Icon/Icon';

export interface ModalProps {
  show: boolean;
  onClose: () => void;
  verticalCentered?: boolean;
  dialogClassName?: string;
  backdrop?: true | false | 'static';
  size?: 'sm' | 'lg' | 'xl';
}

export function Modal({
  show,
  onClose,
  verticalCentered = true,
  children,
  dialogClassName,
  backdrop = 'static',
  size,
}: PropsWithChildren<ModalProps>): ReactNode {
  return (
    <BSModal
      className={styles.modal}
      contentClassName={styles.content}
      show={show}
      onHide={onClose}
      backdropClassName={styles.backdrop}
      centered={verticalCentered}
      dialogClassName={clsx(dialogClassName)}
      backdrop={backdrop}
      size={size}
    >
      {children}
    </BSModal>
  );
}

export interface ModalHeaderProps {
  withCloseButton?: boolean;
}

const Header: FC<PropsWithChildren<ModalHeaderProps>> = ({
  children,
  withCloseButton = true,
}): ReactNode => {
  const modalContext = useContext(BSModalContext);

  return (
    <div className={clsx(styles.header)}>
      {children}
      {withCloseButton ? (
        <Button
          variant="tertiary"
          size="sm"
          onClick={modalContext.onHide}
          className={styles.closeButton}
        >
          <Icon name="xNoCircle" width={24} height={24} />
        </Button>
      ) : null}
    </div>
  );
};

const Body: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return <div className={styles.body}>{children}</div>;
};

Modal.Header = Header;
Modal.Body = Body;
