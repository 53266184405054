import React, { forwardRef } from 'react';
import { ICONS } from '@styles/icons';
import clsx from 'clsx';

import styles from './Icon.module.scss';

export type IconStrokeColors =
  | 'white'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'rose'
  | 'blue'
  | 'yellow'
  | 'dark-blue'
  | 'green'
  | 'gray300'
  | 'gray600';
export type IconFillColors =
  | 'white'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'pink'
  | 'gray300'
  | 'gray600';
export type IconHoverStrokeColors =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'gray300'
  | 'gray400'
  | 'gray600';
export type IconHoverFillColors =
  | 'white'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'gray300'
  | 'gray600';

type CustomIconProps = {
  name: keyof typeof ICONS;
  className?: string;
  width?: number;
  height?: number;
  stroke?: IconStrokeColors;
  fill?: IconFillColors;
  hoverStroke?: IconHoverStrokeColors;
  hoverFill?: IconFillColors;
} & Omit<React.SVGProps<SVGSVGElement>, 'name' | 'width' | 'height'>;

// TODO: Create story for CustomIconWithBg and refactor it
export const Icon = forwardRef<SVGSVGElement, CustomIconProps>(
  (
    {
      name,
      className = '',
      width = 20,
      height = 20,
      stroke,
      fill,
      hoverStroke,
      hoverFill,
      ...svgProps
    },
    ref,
  ) => {
    const CustomIcon = name ? ICONS[name] : undefined;

    const iconClasses = clsx(
      styles.icon,
      {
        [styles.hoverStrokePrimary]: hoverStroke === 'primary',
        [styles.hoverStrokeSecondary]: hoverStroke === 'secondary' || hoverStroke === 'gray600',
        [styles.hoverStrokeSuccess]: hoverStroke === 'success',
        [styles.hoverStrokeInfo]: hoverStroke === 'info',
        [styles.hoverStrokeWarning]: hoverStroke === 'warning',
        [styles.hoverStrokeDanger]: hoverStroke === 'danger',
        [styles.hoverStrokeGray400]: hoverStroke === 'gray400',
        [styles.hoverStrokeGray300]: hoverStroke === 'gray300',
        [styles.hoverStrokeGray600]: hoverStroke === 'gray600',

        [styles.hoverFillPrimary]: hoverFill === 'primary',
        [styles.hoverFillSecondary]: hoverFill === 'secondary' || hoverFill === 'gray600',
        [styles.hoverFillSuccess]: hoverFill === 'success',
        [styles.hoverFillInfo]: hoverFill === 'info',
        [styles.hoverFillWarning]: hoverFill === 'warning',
        [styles.hoverFillDanger]: hoverFill === 'danger',
        [styles.hoverFillWhite]: hoverFill === 'white',
        [styles.hoverFillGray300]: hoverFill === 'gray300',

        [styles.primary]: stroke === 'primary',
        [styles.secondary]: stroke === 'secondary' || stroke === 'gray600',
        [styles.success]: stroke === 'success',
        [styles.info]: stroke === 'info',
        [styles.warning]: stroke === 'warning',
        [styles.danger]: stroke === 'danger',
        [styles.white]: stroke === 'white',
        [styles.dark]: stroke === 'dark',
        [styles.rose]: stroke === 'rose',
        [styles.blue]: stroke === 'blue',
        [styles.yellow]: stroke === 'yellow',
        [styles.darkBlue]: stroke === 'dark-blue',
        [styles.green]: stroke === 'green',
        [styles.gray300]: stroke === 'gray300',

        [styles.primaryFill]: fill === 'primary',
        [styles.secondaryFill]: fill === 'secondary' || fill === 'gray600',
        [styles.successFill]: fill === 'success',
        [styles.infoFill]: fill === 'info',
        [styles.warningFill]: fill === 'warning',
        [styles.dangerFill]: fill === 'danger',
        [styles.whiteFill]: fill === 'white',
        [styles.pinkFill]: fill === 'pink',
        [styles.gray300Fill]: fill === 'gray600',
      },
      className,
    );

    return name && CustomIcon ? (
      <CustomIcon {...svgProps} className={iconClasses} width={width} height={height} ref={ref} />
    ) : null;
  },
);

export const IconWithBg = forwardRef<SVGSVGElement, CustomIconProps>((props, ref) => {
  return (
    <div
      style={{
        borderRadius: '50%',
        border: `8px solid var(${props.stroke === 'dark' ? '--bs-gray-50' : '--ongage-error-50'})`,
        background: `var(${props.stroke === 'dark' ? '--bs-gray-100' : '--ongage-error-100'})`,
        padding: '12px',
      }}
    >
      <Icon {...props} ref={ref} />
    </div>
  );
});
