import { forwardRef, ReactNode } from 'react';

type RadioButtonProps = {
  groupSelectedValue: string | undefined;
  value: string | undefined;
  onChange: (value: string) => void;
  title: ReactNode;
  description?: string;
  disabled?: boolean;
};
export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ value, onChange, groupSelectedValue, title, description, disabled }, ref) => {
    return (
      <label className="form-check form-check-custom form-check-inline d-flex align-items-center cursor-pointer">
        <input
          ref={ref}
          className="form-check-input h-15px w-15px"
          type="radio"
          value={value}
          checked={value === groupSelectedValue}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
        <span className="form-check-label fs-6 fw-semibold text-gray-700">{title}</span>
        {description && <span className="form-check-label fs-7 text-gray-500">{description}</span>}
      </label>
    );
  },
);
