import { FC } from 'react';
import { useUserInfo } from '@module/list/hooks/user-queries';
import { NotificationsPopup } from '@module/sidebar/menu/entities/NotificationsPopup';
import { SupportModalLink } from '@module/sidebar/menu/entities/SupportModalLink';
import { Icon } from '@ui/Icon/Icon';
import { ROUTES } from '@utils/routes';

import { NavigationMenu } from './NavigationMenu';

export const FooterMenu = () => {
  const { data, isPending } = useUserInfo();
  return (
    <NavigationMenu
      items={[
        {
          title: 'Notifications',
          icon: <NotificationBellIcon />,
          render: (title) => <NotificationsPopup title={title} />,
        },
        {
          title: 'Support',
          icon: <Icon name="customerSupport" />,
          render: (title) => <SupportModalLink title={title} />,
        },
        {
          title: 'Settings',
          icon: <Icon name="settingsGear" />,
          to: ROUTES.SETTINGS.PROFILE,
        },
        {
          title: 'NOP',
          icon: <Icon name="shield" />,
          to: ROUTES.NOP.INDEX,
          blocked: !isPending && data ? !data.payload.is_super_admin : true,
        },
      ]}
      isLoading={false}
    />
  );
};

const NotificationBellIcon: FC = () => {
  const hasNewNotifications = true; // todo change to api call

  return (
    <div className="position-relative">
      {hasNewNotifications && (
        <Icon
          name="bellDot"
          width={8}
          height={8}
          style={{
            position: 'absolute',
            top: 0,
            right: 1,
          }}
        />
      )}
      <Icon name="bell" />
    </div>
  );
};
