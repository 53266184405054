import { FC, PropsWithChildren, ReactElement } from 'react';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import { FapiResponse } from '@module/shared/models/FapiResponse';
import { useQuery } from '@tanstack/react-query';
import { DateUIFormats } from '@utils/DateFieldFormats';
import axios from 'axios';
import { format, fromUnixTime } from 'date-fns';
import { Dropdown } from 'react-bootstrap';

import styles from './Notifications.module.scss';
import config from '../../../../../app-config';

type Notification = {
  account_id: number;
  created: number;
  deleted: number;
  esp: string;
  esp_connection_id: number;
  esp_id: number;
  expiry_date: number;
  id: number;
  message: string;
};
export const NotificationsPopup: FC<{ title: ReactElement }> = ({ title }) => {
  const { data: account } = useAccountInfo();
  const notifications = useQuery({
    queryKey: [account?.id],
    queryFn: () =>
      axios
        .get<FapiResponse<Notification[]>>(`${config.fapiUrl}/api/notifications`)
        .then(({ data }) => data),
    enabled: !!account?.id,
  });

  const groupedNotifications = notifications.data?.payload.reduce(
    (acc, cur) => {
      const date = format(fromUnixTime(cur.created), DateUIFormats.MMM_DD_COMMA_YYYY);
      if (!acc[date]) {
        acc[date] = [cur];
      } else {
        acc[date].push(cur);
      }
      return acc;
    },
    {} as Record<string, Notification[]>,
  );

  return (
    <Dropdown drop="end">
      <Dropdown.Toggle as={'div'}>{title}</Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{ strategy: 'fixed' }}
        flip
        align="end"
        renderOnMount
        className={styles.menu}
      >
        <NotificationContainer>
          {groupedNotifications &&
            Object.keys(groupedNotifications).map((date, idx) => (
              <div key={`${date}_${idx}`}>
                <span className="text-gray-500 fw-bold">{date}</span>
                {groupedNotifications?.[date].map((notification, index) => (
                  <NotificationItem
                    key={`${notification.id}_${index}`}
                    notification={notification}
                  />
                ))}
              </div>
            ))}
        </NotificationContainer>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const NotificationContainer: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.container}>
    <span className="text-gray-700 fw-bold fs-5">Notifications</span>
    {children}
  </div>
);

const NotificationItem: FC<{ notification: Notification }> = ({ notification }) => {
  const hoursMinutes = format(fromUnixTime(notification.created), 'h:mm a');
  return (
    <div className={styles.notification}>
      <span className="text-gray-700 fw-semibold">
        {/* Removing any html tags https://stackoverflow.com/questions/74669280/removing-html-tags-from-string-in-react */}
        {notification.message.replace(/(<([^>]+)>)/gi, '')}
      </span>
      <span className="text-gray-500 flex-shrink-0">{hoursMinutes}</span>
    </div>
  );
};
