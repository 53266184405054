import { ButtonHTMLAttributes, forwardRef, useMemo } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

type HTMLButtonElementPropsSubset = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'type' | 'value' | 'size' | 'as'
>;

type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'info' | 'danger';

type ButtonColor = 'primary' | 'gray';

type ButtonType = 'button' | 'submit' | 'reset';

export interface ButtonProps extends HTMLButtonElementPropsSubset {
  size?: ButtonSize;
  variant?: ButtonVariant;
  color?: ButtonColor;
  type?: ButtonType;
  disabled?: boolean;
  isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'md',
      variant = 'primary',
      color = 'gray',
      className,
      disabled = false,
      type = 'button',
      isLoading = false,
      children,
      ...buttonProps
    },
    ref,
  ) => {
    const buttonClassName = useMemo(
      () =>
        clsx(styles.button, styles[size], styles[variant], className, {
          [styles.colorPrimary]: color === 'primary',
          [styles.colorGray]: color === 'gray',
          'fs-3': size === 'xxl',
          'fs-4': ['lg', 'xl'].includes(size),
          'fs-6': ['sm', 'md'].includes(size),
        }),
      [size, variant, color, className],
    );

    return (
      <button
        {...buttonProps}
        ref={ref}
        type={type}
        className={buttonClassName}
        disabled={disabled || isLoading}
      >
        {children}
        {isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2" />}
      </button>
    );
  },
);
