import { getTimezones } from '@module/shared/api/timezones';
import { SearchParams } from '@module/shared/models/models';
import { useQuery } from '@tanstack/react-query';

const timezoneKeys = {
  users: (searchParams?: SearchParams) =>
    [
      {
        entity: 'timezones',
        ...searchParams,
      },
    ] as const,
};

export const useTimezones = (searchParams?: SearchParams) => {
  return useQuery({
    queryKey: timezoneKeys.users(searchParams),
    queryFn: getTimezones,
  });
};
