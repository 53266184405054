import { RefObject, useEffect, useState } from 'react';

export const useIsHovered = (ref: RefObject<Element>) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const onMouseOver = () => setIsHovered(true);
    const onMouseOut = () => setIsHovered(false);

    if (ref && ref.current) {
      ref.current.addEventListener('mouseover', onMouseOver);
      ref.current.addEventListener('mouseout', onMouseOut);
    }

    const { current } = ref;

    return () => {
      if (current) {
        current.removeEventListener('mouseover', onMouseOver);
        current.removeEventListener('mouseout', onMouseOut);
      }
    };
  }, [ref]);

  return isHovered;
};
