import { CSSProperties, FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './Badge.module.scss';
import { Icon, IconFillColors } from '../Icon/Icon';

type BadgeVariant = 'filled' | 'outline';

interface BadgeProps {
  className?: string;
  variant?: BadgeVariant;
  style?: CSSProperties;
  color?: IconFillColors;
  dot?: boolean;
}

const classToVariant: Record<BadgeVariant, string> = {
  filled: styles.filled,
  outline: styles.outlined,
};

export const Badge: FC<PropsWithChildren<BadgeProps>> = ({
  variant = 'filled',
  color = 'secondary',
  className,
  children,
  style,
  dot,
}) => {
  return (
    <span
      className={clsx(styles.badge, classToVariant[variant], styles[color], className, 'fs-7')}
      style={{ ...style }}
    >
      {dot && <Icon name="dot" fill={color} width={8} height={8} />}
      {children}
    </span>
  );
};
