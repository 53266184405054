import { FC, useEffect, useState } from 'react';
import { Icon } from '@ui/Icon/Icon';
import clsx from 'clsx';

import styles from '../../../../ui/table/controls/ControlWrapper.module.scss';
import { useTableDataContext } from '../../store/useTableColumns';

export const RefreshTableQueryButton: FC = () => {
  const { refetch, isRefetching } = useTableDataContext((s) => s.query);
  const [forceAnimate, setForceAnimate] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);

  useEffect(() => {
    let timeoutId;

    if (isRefetching) {
      setStartTime(Date.now()); // Set startTime when refetching starts
      setForceAnimate(true); // Start animation
    } else if (startTime) {
      const endTime = Date.now();
      const elapsedTime = (endTime - startTime) / 1000; // Time in seconds
      const adjustedDuration = Math.ceil(elapsedTime); // Round up to nearest second
      const timeToAdd = adjustedDuration - elapsedTime;

      timeoutId = setTimeout(() => setForceAnimate(false), timeToAdd * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRefetching, startTime]);

  return (
    <div onClick={() => refetch()} className={styles.controlWrapper}>
      <Icon
        name="refresh"
        stroke="secondary"
        width={22}
        height={22}
        className={clsx({ [styles.spin]: forceAnimate })}
      />
    </div>
  );
};
