import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import OTPInput, { InputProps } from 'react-otp-input';

import './otp-input.scss';

interface OtpInputProps {
  shouldAutoFocus?: boolean;
  otpLength?: number;
  value: string | null;
  onChange: (otp: string | null) => void;
}

export const OtpInput: FC<OtpInputProps> = ({
  otpLength = 6,
  onChange,
  value,
  ...props
}: OtpInputProps) => {
  const placeholder = useMemo(
    () => Array.from({ length: otpLength }, () => '0').join(''),
    [otpLength],
  );

  const handleChange = (otp: string) => {
    onChange(otp.length === 0 ? null : otp);
  };

  const renderSeparator = useCallback(
    (index: number) => {
      if (otpLength % 2 !== 0 || index + 1 !== otpLength / 2) {
        return null;
      }

      return <span className="otp-input__separator">&#45;</span>;
    },
    [otpLength],
  );

  return (
    <OTPInput
      {...props}
      inputType="text"
      onChange={handleChange}
      value={value ?? ''}
      numInputs={otpLength}
      renderInput={OtpSingleInput}
      placeholder={placeholder}
      containerStyle="otp-input d-flex justify-content-center gap-2"
      renderSeparator={renderSeparator}
    />
  );
};

const OtpSingleInput: FC<InputProps> = (props) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const otpDigit = e.target.value.trim();

    if (otpDigit.length === 0 || otpDigit.match(/^[0-9]+$/)) {
      props.onChange(e);
    }
  };

  return (
    <input
      {...props}
      onChange={handleChange}
      inputMode="numeric"
      pattern="[0-9]*"
      className={clsx('otp-input__single-input flex-grow-1', {
        'otp-input__single-input_empty': props.value === '',
      })}
    />
  );
};
