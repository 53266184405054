import { forwardRef } from 'react';
import { TextAreaField, TextAreaFieldProps } from '@ui/TextArea';
import { useField } from 'formik';

export const FormikTextArea = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>((props, ref) => {
  const [field, meta] = useField<string>(props.name);

  return (
    <TextAreaField
      {...field}
      error={meta.touched && meta.error}
      id={props.id || props.name}
      {...props}
      ref={ref}
    />
  );
});
